import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "./api";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CFormLabel,
  CTableBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CFormInput,
  CFormFloating,
  CInputGroup,
  CInputGroupText,
  CFormCheck,
  CButtonGroup,
  CButton,
  CContainer,
  CCard,
  CCardHeader,
  CCollapse,
  CRow,
  CCol,
  CFormSwitch,
} from "@coreui/react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import enLocale from "date-fns/locale/en-IE";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import moment from "moment";
import QRCode from "qrcode";
import ReusableButton from "./Reusable/Buttons";

import GlobalSnackbar from "./Snackbar";
import { Backdrop, CircularProgress } from "@mui/material";

import logo from "../img/icon144.png";
import imgNew from "../img/fondNew.jpg";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

import { saveAs } from "file-saver";

const columnsAccise = [
  {
    field: "utente",
    headerName: "Utente",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matricola",
    headerName: "Matricola",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "m_giri",
    headerName: "M Giri",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "k_giri",
    headerName: "K Giri",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ore",
    headerName: "Ore",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "minuti",
    headerName: "Minuti",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
];
const columnsManutenzioni = [
  {
    field: "utente",
    headerName: "Utente",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matricola",
    headerName: "Matricola",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descrizione",
    headerName: "Descrizione",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matr_costr",
    headerName: "Matricola Costruttore",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "manutenz_tipo",
    headerName: "Tipo Manutenzione",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ore_km",
    headerName: "Ore/Km",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "manutenzione",
    headerName: "Manutenzione",
    width: 300,
    headerAlign: "center",
  },
  {
    field: "note",
    headerName: "Note",
    width: 500,
    height: 100,
    headerAlign: "center",
  },
  {
    field: "meccanico",
    headerName: "Meccanico",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
];

const MacchinarioDati = () => {
  const [macchinario, setMacchinario] = useState({
    matricola: "",
    matricola_costr: "",
    tipologia: "",
    marca: "",
    modello: "",
    descrizione: "",
    chassis: "",
    targa: "",
    foto: "",
    stato_attuale: "",
    anno_acquisto: "",
    ore_km: "",
    costo: "",
    documenti_ce: "",
    documenti_cdc: "",
    packingList: "",
    documenti_ass: "",
    documenti_usoman: "",
    documenti_catric: "",
    documenti_schel: "",
    documenti_schidr: "",
    documenti_schpneu: "",
    cespite: "",
    costo_storico: "",
    valore_ammortamento: "",
    documenti_fattura: "",
    varie1: "",
    varie2: "",
    varie3: "",
    scadenza_circolazione: null,
    scadenza_assicurazione: null,
    scadenza_varie2: null,
    scadenza_varie3: null,
  });
  const [datiMacchina, setDatiMacchina] = useState({
    matricola: "",
    motore_marca: "",
    motore_modello: "",
    motore_SN: "",
    motore_potenza: "",
    motore_note: "",
    trasmissione_marca: "",
    trasmissione_modello: "",
    trasmissione_SN: "",
    trasmissione_note: "",
    macchina_base_lunghezza: "",
    macchina_base_larghezza: "",
    macchina_base_altezza: "",
    macchina_base_peso: "",
    equipaggiamento: "",
    accessori: "",
    note: "",
  });
  const [macchinarioBackup, setMacchinarioBackup] = useState({
    id: "",
    matricola: "",
    marca: "",
    modello: "",
    descrizione: "",
    targa: "",
    noleggiatore: "",
    data_inizio: null,
    data_fine: null,
    cantiere: null,
    contratto: "",
    tipologia: 0,
    chassis: "",
    tariffa: 0,
    n_matricola: 0,
    note: "",
    scheda_tecnica: "",
    certificato_ce: "",
    carta_circolazione: "",
    assicurazione: "",
    usoman: "",
    catric: "",
    funicat: "",
    periodica: "",
    scadenza_circolazione: null,
    scadenza_assicurazione: null,
    scadenza_funicatene: null,
    scadenza_periodica: null,
  });
  const [tipologiaMacchina, setTipologiaMacchina] = useState([]);
  const [manutenzioneMacchina, setManutenzioneMacchina] = useState([
    {
      matricola: "",
      componente: "filtro_aria_esterno",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_aria_interno",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_gasolio",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_olio",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_idraulico",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "essiccatore",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_motore",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_cambio",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_idraulico",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_pistoni",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_bielle",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "antigelo",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
  ]);
  const [valueBottom, setValueBottom] = useState(1);
  const [valueTop, setValueTop] = useState(1);
  const [accise, setAccise] = useState([]);
  const [searchReady, setSearchReady] = useState(false);
  const [manutenzione, setManutenzione] = useState([]);
  const [visibile, setVisibile] = useState({
    ce: false,
    cdc: false,
    packingList: false,
    ass: false,
    usoman: false,
    catric: false,
    schel: false,
    schidr: false,
    schpneu: false,
    tecnica: false,
    varie2: false,
    varie3: false,
    fattura: false,
  });
  const [qrcode, setQrcode] = useState("");
  const [circolazione, setCircolazione] = useState(false);
  const [assicurazione, setAssicurazione] = useState(false);
  const [funiCatene, setFuniCatene] = useState(false);
  const [verificaPeriodica, setVerificaPeriodica] = useState(false);
  const [scadenzaManut, setScadenzaManut] = useState(0);
  const [nManutenzioni, setNManutenzioni] = useState(0);
  const [nAccise, setNAccise] = useState(0);
  const [ready, setReady] = useState(false);

  const [valueUpload, setValueUpload] = useState("");
  const [colonnaDb, setColonnaDb] = useState("");

  const [nomiDocumenti, setNomiDocumenti] = useState({ from: "", to: "" });
  const [pathDocumenti, setPathDocumenti] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const permission = JSON.parse(localStorage.getItem("permission"));
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const displayNone = () => {
    if (
      JSON.parse(localStorage.getItem("permission")).manutenzioni
        .allowDelete === 0
    ) {
      return "none";
    } else {
      return "block";
    }
  };
  const rendOutputManutenzioni = (elemento) => {
    let b = "";
    switch (elemento.componente) {
      case "filtro_aria_esterno":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Filtro aria esterno
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_esterno")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="number"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_esterno")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_esterno")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_aria_interno":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Filtro aria interno
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_interno")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_interno")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_interno")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_gasolio":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Filtro gasolio
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_gasolio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_gasolio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_gasolio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_olio":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Filtro olio
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_olio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_olio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_olio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_idraulico":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Filtro idraulico
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_idraulico")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_idraulico")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_idraulico")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "essiccatore":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Essiccatore aria
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "essiccatore")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "essiccatore")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "essiccatore")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_motore":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Olio motore [kg]
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_motore")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_motore")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_motore")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_cambio":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Olio cambio [kg]
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_cambio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_cambio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_cambio")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_idraulico":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Olio idraulico [kg]
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_idraulico")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_idraulico")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_idraulico")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_pistoni":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Olio pistoni [kg]
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_pistoni")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_pistoni")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_pistoni")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_bielle":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Olio bielle [kg]
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_bielle")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_bielle")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_bielle")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "antigelo":
        return (
          <CTableRow>
            <CTableHeaderCell className="fw-normal" scope="row">
              Antigelo [kg]
            </CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "antigelo")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "antigelo")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "antigelo")
                }
                disabled={
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </CTableDataCell>
          </CTableRow>
        );
    }
  };
  const changeDocumentPath = (path) => {
    let newPath = { ...path };

    let arrOfPath = [...pathDocumenti];
    if (arrOfPath.length > 0) {
      let elIndex = arrOfPath.findIndex((e) => e.from === newPath.from);

      if (elIndex !== -1) {
        arrOfPath[elIndex].to = newPath.to;
      } else {
        arrOfPath.push(newPath);
      }
    } else {
      arrOfPath.push(newPath);
    }
    setPathDocumenti(arrOfPath);
  };
  const handleChange = (e) => {
    if (
      e.target.name === "varie1" ||
      //e.target.name === "tecnica" ||
      e.target.name === "documenti_ce" ||
      e.target.name === "documenti_cdc" ||
      e.target.name === "documenti_ass" ||
      e.target.name === "documenti_usoman" ||
      e.target.name === "documenti_catric" ||
      e.target.name === "documenti_schidr" ||
      e.target.name === "documenti_schpneu" ||
      e.target.name === "documenti_schel" ||
      e.target.name === "varie2" ||
      e.target.name === "varie3" ||
      e.target.name === "packingList"
    ) {
      let valDocumenti = { ...nomiDocumenti };
      valDocumenti.from = "/" + macchinarioBackup[e.target.name];
      valDocumenti.to = "/" + e.target.value;
      changeDocumentPath(valDocumenti);
    }
    let copiaMacchinario = { ...macchinario };
    copiaMacchinario[e.target.name] = e.target.value;
    setMacchinario(copiaMacchinario);
  };
  const handleChangeDatiMacchinario = (e) => {
    let copiaDati = { ...datiMacchina };
    copiaDati[e.target.name] = e.target.value;
    setDatiMacchina(copiaDati);
  };
  const handleChangeManutenzioneMacchinari = (e, tipologia) => {
    let copiaManutenzione = [...manutenzioneMacchina];
    for (let i = 0; i < copiaManutenzione.length; i++) {
      if (copiaManutenzione[i].componente === tipologia) {
        copiaManutenzione[i][e.target.name] = e.target.value;
        i += copiaManutenzione.length;
      }
    }
    setManutenzioneMacchina(copiaManutenzione);
  };
  const handleChangeRadio = (value) => {
    let copiaMacchinario = { ...macchinario };
    copiaMacchinario["stato_attuale"] = value;
    setMacchinario(copiaMacchinario);
  };
  const handlleDelete = async () => {
    let confirm = window.confirm(
      "Sei sicuro di voler cancellare il macchinario?"
    );
    if (confirm) {
      await api.delete("/api/macchinari", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          matricola: macchinario.matricola,
        },
      });
      alert("Manutenzione eliminata con successo");
    }
  };
  const saveManutenzioneMacchinario = async () => {
    let copiaManutenzioneMacchinario = manutenzioneMacchina;
    try {
      for (let i = 0; i < copiaManutenzioneMacchinario.length; i++) {
        copiaManutenzioneMacchinario[i].matricola = macchinario.matricola;
        if (copiaManutenzioneMacchinario[i].id === undefined) {
          try {
            await api.post(
              "api/manutMacchinari",
              {
                auth: "a5d42c3ed7977afa2d8e922569e7940b",
                manut: copiaManutenzioneMacchinario[i],
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
          } catch {}
        } else {
          await api.put(
            "api/manutMacchinari",
            {
              auth: "a5d42c3ed7977afa2d8e922569e7940b",
              manut: copiaManutenzioneMacchinario[i],
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
        }
      }

      alert("Salvataggio tabella manutenzione effettuato con successo");
    } catch {
      alert("ERRORE NEL SALVATAGGIO DEI DATI");
    }
  };
  const manageFiles = async (cFile) => {
    let f = cFile.split("/");

    let file = f[1];

    await api
      .post(
        "api/ftp",
        { file: file, percorso: cFile, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        //window.open("https://portale.fondamentasrl.it/file?file=" + file);

        var oReq = new XMLHttpRequest();
        // The Endpoint of your server
        var URLToPDF = "https://portale.fondamentasrl.it/file?file=" + file;
        // Configure XMLHttpRequest
        oReq.open("GET", URLToPDF, true);

        // Important to use the blob response type
        oReq.responseType = "blob";

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
          // Once the file is downloaded, open a new window with the PDF
          // Remember to allow the POP-UPS in your browser
          var filee = new Blob([oReq.response], {
            type: "application/pdf",
          });

          // Generate file download directly in the browser !
          saveAs(filee, file);
        };

        oReq.send();
      });
  };
  const viewFiles = async (cFile) => {
    let f = cFile.split("/");

    let file = f[1];

    await api
      .post(
        "api/ftp",
        { file: file, percorso: cFile, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://portale.fondamentasrl.it/file?file=" + file);
      });
  };
  const deleteFiles = async (name, colonnaDb) => {
    let perc =
      macchinario.matricola + "/" + macchinario.matricola + "_" + name + ".pdf";
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare il documento " +
        macchinario.matricola +
        "_" +
        name +
        ".pdf" +
        "?"
    );
    if (isExecuted) {
      try {
        await api.post(
          "api/ftp",
          { percorso: perc, operazione: "elimina" },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        //#region
        let copiaMacchinario = { ...macchinario };
        if (
          copiaMacchinario.scadenza_circolazione !== "" &&
          copiaMacchinario.scadenza_circolazione !== null
        ) {
          copiaMacchinario.scadenza_circolazione = moment(
            copiaMacchinario.scadenza_circolazione
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaMacchinario.scadenza_assicurazione !== "" &&
          copiaMacchinario.scadenza_assicurazione !== null
        ) {
          copiaMacchinario.scadenza_assicurazione = moment(
            copiaMacchinario.scadenza_assicurazione
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaMacchinario.scadenza_varie2 !== "" &&
          copiaMacchinario.scadenza_varie2 !== null
        ) {
          copiaMacchinario.scadenza_varie2 = moment(
            copiaMacchinario.scadenza_varie2
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaMacchinario.scadenza_varie3 !== "" &&
          copiaMacchinario.scadenza_varie3 !== null
        ) {
          copiaMacchinario.scadenza_varie3 = moment(
            copiaMacchinario.scadenza_varie3
          )
            .local()
            .format("YYYY-MM-DD");
        }
        //#endregion
        copiaMacchinario[colonnaDb] = "";
        switch (colonnaDb) {
          case "documenti_cdc":
            copiaMacchinario.scadenza_circolazione = null;
            setCircolazione(false);
            break;
          case "documenti_ass":
            copiaMacchinario.scadenza_assicurazione = null;
            setAssicurazione(false);
            break;
          case "varie2":
            copiaMacchinario.scadenza_varie2 = null;
            setFuniCatene(false);
            break;
          case "varie3":
            copiaMacchinario.scadenza_varie3 = null;
            setVerificaPeriodica(false);
            break;

          default:
            break;
        }
        try {
          await api.put(
            "api/macchinari",
            {
              auth: "a5d42c3ed7977afa2d8e922569e7940b",
              macchinario: copiaMacchinario,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          setSnack({
            severity: "success",
            messaggio: "File Eliminato con successo",
          });
          setOpenSnack(true);
          reloadData();
        } catch (error) {
          setSnack({
            severity: "error",
            messaggio: "Eliminazione persorso file fallito",
          });
          setOpenSnack(true);
        }
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Eliminazione fallita, controllare presenza del file",
        });
        setOpenSnack(true);
      }
    }
  };
  const modifyMacchinario = async () => {
    if (macchinario.matricola === "" || macchinario.matricola === undefined) {
      setSnack({
        severity: "error",
        messaggio: "Attenzione la matricola non può essere vuota",
      });
      setOpenSnack(true);
    } else {
      try {
        let copiaMacchinario = { ...macchinario };
        if (
          copiaMacchinario.scadenza_circolazione !== "" &&
          copiaMacchinario.scadenza_circolazione !== null
        ) {
          copiaMacchinario.scadenza_circolazione = moment(
            copiaMacchinario.scadenza_circolazione
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaMacchinario.scadenza_assicurazione !== "" &&
          copiaMacchinario.scadenza_assicurazione !== null
        ) {
          copiaMacchinario.scadenza_assicurazione = moment(
            copiaMacchinario.scadenza_assicurazione
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaMacchinario.scadenza_varie2 !== "" &&
          copiaMacchinario.scadenza_varie2 !== null
        ) {
          copiaMacchinario.scadenza_varie2 = moment(
            copiaMacchinario.scadenza_varie2
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaMacchinario.scadenza_varie3 !== "" &&
          copiaMacchinario.scadenza_varie3 !== null
        ) {
          copiaMacchinario.scadenza_varie3 = moment(
            copiaMacchinario.scadenza_varie3
          )
            .local()
            .format("YYYY-MM-DD");
        }

        if (location.state.operazione === "Nuovo") {
          const response = await api.post(
            "api/macchinari",
            {
              auth: "a5d42c3ed7977afa2d8e922569e7940b",
              macchinario: copiaMacchinario,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          let datMacc = { ...datiMacchina };
          datMacc.matricola = copiaMacchinario.matricola;
          await api.post(
            "api/datiMacchina",
            {
              auth: "a5d42c3ed7977afa2d8e922569e7940b",
              dati: datMacc,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          location.state.operazione = "Modifica";
          setSnack({
            severity: "success",
            messaggio: "Inserimento effettuato",
          });
          setOpenSnack(true);
          setTimeout(() => {
            navigate("/home/macchinari", {
              state: {
                search: "",
              },
            });
          }, 500);
        } else {
          if (pathDocumenti.length > 0) {
            try {
              await api.post(
                "api/allegati",
                { rename: true, path: pathDocumenti },
                {
                  responseType: "blob",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                  },
                }
              );
              await api.put(
                "api/macchinari",
                {
                  auth: "a5d42c3ed7977afa2d8e922569e7940b",
                  macchinario: copiaMacchinario,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                  },
                }
              );

              let datMacc = { ...datiMacchina };
              datMacc.matricola = copiaMacchinario.matricola;
              await api.put(
                "api/datiMacchina",
                {
                  auth: "a5d42c3ed7977afa2d8e922569e7940b",
                  dati: datMacc,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                  },
                }
              );

              setSnack({
                severity: "success",
                messaggio: "Modifica effettuata",
              });
              setOpenSnack(true);
              setTimeout(() => {
                navigate("/home/macchinari", {
                  state: {
                    search: "",
                  },
                });
              }, 500);
            } catch (error) {
              setSnack({
                severity: "error",
                messaggio: "Errore durante la modifica",
              });
              setOpenSnack(true);
            }
          } else {
            await api.put(
              "api/macchinari",
              {
                auth: "a5d42c3ed7977afa2d8e922569e7940b",
                macchinario: copiaMacchinario,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
            let datMacc = { ...datiMacchina };
            datMacc.matricola = copiaMacchinario.matricola;
            await api.put(
              "api/datiMacchina",
              {
                auth: "a5d42c3ed7977afa2d8e922569e7940b",
                dati: datMacc,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
            setSnack({
              severity: "success",
              messaggio: "Modifica effettuata",
            });
            setOpenSnack(true);
            setTimeout(() => {
              navigate("/home/macchinari", {
                state: {
                  search: "",
                },
              });
            }, 500);
          }
        }
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore nel salvataggio",
        });
      }
    }
  };
  const loadFile = async (filex, matricola, nome, nomeColonnaDb) => {
    setOpenBackdrop(true);

    const formData = new FormData();
    //filex.name=matricola+"_"+moment(data).format("YYMMDD")+".pdf";

    formData.append("file", filex);
    formData.append("matricola", matricola);
    //formData.append("data", data);
    formData.append("nomeManutenzione", matricola + "_" + nome + ".pdf");
    formData.append("docMacch", true);

    await api
      .post("api/ftp/caricaDoc", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async () => {
        setOpenBackdrop(false);
        setSnack({
          severity: "success",
          messaggio: "Documento caricato con successo!",
        });
        setOpenSnack(true);
        let macch = { ...macchinario };
        macch.scadenza_varie2
          ? (macch.scadenza_varie2 = moment(macch.scadenza_varie2).format(
              "YYYY-MM-DD"
            ))
          : (macch.scadenza_varie2 = null);
        macch.scadenza_varie3
          ? (macch.scadenza_varie3 = moment(macch.scadenza_varie3).format(
              "YYYY-MM-DD"
            ))
          : (macch.scadenza_varie3 = null);
        macch.scadenza_assicurazione
          ? (macch.scadenza_assicurazione = moment(
              macch.scadenza_assicurazione
            ).format("YYYY-MM-DD"))
          : (macch.scadenza_assicurazione = null);
        macch.scadenza_circolazione
          ? (macch.scadenza_circolazione = moment(
              macch.scadenza_circolazione
            ).format("YYYY-MM-DD"))
          : (macch.scadenza_circolazione = null);
        macch[`${nomeColonnaDb}`] =
          matricola + "/" + matricola + "_" + nome + ".pdf";
        await api.put(
          "api/macchinari",
          {
            macchinario: macch,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        await reloadData();
      });
  };
  const handleChangeValueBottom = (event, newValue) => {
    setValueBottom(newValue);
  };
  const handleChangeValueTop = (event, newValue) => {
    setValueTop(newValue);
  };
  const reloadData = async () => {
    const res = await api.get("api/macchinari", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const macchinarioFiltered = res.data.data.filter(
      (el) => el.matricola === macchinario.matricola
    );
    setMacchinarioBackup(macchinarioFiltered[0]);
    setMacchinario(macchinarioFiltered[0]);
  };
  const zipAllFiles = async () => {
    api
      .post(
        "api/allegati",
        { macchina: macchinario.matricola },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", macchinario.matricola + ".zip");
        document.body.appendChild(link);
        link.click();
      });
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/tipologia", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const tipologia = res.data.data.map((tipo) => tipo.tipologia);
          setTipologiaMacchina(tipologia);
          setSearchReady(true);
        });
      api
        .get("api/accise", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let accisaMacchinario = res.data.data.filter(
            (accisa) =>
              accisa.matricola === location.state.macchinario.matricola
          );
          const numeroAccise = accisaMacchinario.length;
          setNAccise(numeroAccise);
          setAccise(accisaMacchinario);
        });
      api
        .get("api/manutenzioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const manutenzione = res.data.data;
          const manutenzioneMacchinario = res.data.data.filter(
            (manutenzione) =>
              manutenzione.matricola === location.state.macchinario.matricola
          );
          const numeroManutenzioni = manutenzioneMacchinario.length;
          const scadenzaManut = manutenzioneMacchinario.ore_scadenza;
          setScadenzaManut(scadenzaManut);
          setNManutenzioni(numeroManutenzioni);
          setManutenzione(manutenzioneMacchinario);
          setReady(true);
        });

      if (location.state.operazione === "Nuovo") {
        const macch = { ...macchinario };
        setMacchinarioBackup(macch);
        setMacchinario(macch);
      } else {
        const macchinario = location.state.macchinario;
        if (
          macchinario.scadenza_circolazione === null ||
          macchinario.scadenza_circolazione === "1899-11-29T23:00:00.000Z" ||
          macchinario.scadenza_circolazione === "0000-00-00"
        ) {
          macchinario.scadenza_circolazione = null;
        }
        if (
          macchinario.scadenza_assicurazione === null ||
          macchinario.scadenza_assicurazione === "1899-11-29T23:00:00.000Z" ||
          macchinario.scadenza_assicurazione === "0000-00-00"
        ) {
          macchinario.scadenza_assicurazione = null;
        }
        if (
          macchinario.scadenza_varie2 === null ||
          macchinario.scadenza_varie2 === "1899-11-29T23:00:00.000Z" ||
          macchinario.scadenza_varie2 === "0000-00-00"
        ) {
          macchinario.scadenza_varie2 = null;
        }
        if (
          macchinario.scadenza_varie3 === null ||
          macchinario.scadenza_varie3 === "1899-11-29T23:00:00.000Z" ||
          macchinario.scadenza_varie3 === "0000-00-00"
        ) {
          macchinario.scadenza_varie3 = null;
        }
        setMacchinarioBackup(macchinario);
        setMacchinario(macchinario);

        let url = `https://portale.fondamentasrl.it/home/macchinari/qrcode/${location.state.macchinario.matricola}`;
        QRCode.toDataURL(url).then((data) => setQrcode(data));

        api
          .get("api/manutMacchinari", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            params: { matricola: macchinario.matricola },
          })
          .then((res) => {
            if (res.data.data.length > 0) {
              setManutenzioneMacchina(res.data.data);
            }
            setReady(true);
          });

        let responseDatiMacchina = [];
        responseDatiMacchina = await api.get("api/datiMacchina", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { matricola: macchinario.matricola },
        });
        if (!responseDatiMacchina.data.data[0]) {
          try {
            let datiMacc = { ...datiMacchina };
            datiMacc.matricola = macchinario.matricola;
            await api.post(
              "api/datiMacchina",
              {
                auth: "a5d42c3ed7977afa2d8e922569e7940b",
                dati: datiMacc,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );
          } catch (error) {
            responseDatiMacchina = await api.get("api/datiMacchina", {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
              params: { matricola: macchinario.matricola },
            });
          }
        }
        setDatiMacchina(responseDatiMacchina.data.data[0]);

        location.state.macchinario.scadenza_circolazione !== null
          ? setCircolazione(true)
          : setCircolazione(false);
        location.state.macchinario.scadenza_assicurazione !== null
          ? setAssicurazione(true)
          : setAssicurazione(false);
        location.state.macchinario.scadenza_varie2 !== null
          ? setFuniCatene(true)
          : setFuniCatene(false);
        location.state.macchinario.scadenza_varie3 !== null
          ? setVerificaPeriodica(true)
          : setVerificaPeriodica(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="card text-center  border-classic mx-4 mx-md-0">
        <div className="card-header position-relative">
          <span className="fs-4 col">
            {macchinario.matricola} - {macchinario.descrizione}
          </span>
          <span
            className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (location.state.search !== undefined) {
                navigate("/home/macchinari", {
                  state: {
                    search: location.state.search,
                  },
                });
              } else {
                navigate(-1);
              }
            }}
          >
            <i className="bi bi-x fs-1 my-auto"></i>
          </span>
        </div>
        {/* NAVIGAZIONE */}
        <TabContext value={valueTop}>
          <div className="card-header bg-light d-flex">
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChangeValueTop}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Tab
                icon={
                  <>
                    <i className="bi bi-clipboard-data fs-4"></i>
                    <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                      DETTAGLI
                    </span>
                  </>
                }
                className="row justify-content-center"
                value={1}
              ></Tab>
              <Tab
                icon={
                  <>
                    <i className="bi bi-file-earmark-pdf fs-4"></i>
                    <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                      DOCUMENTI
                    </span>
                  </>
                }
                className="row justify-content-center"
                value={2}
              ></Tab>
              <Tab
                icon={
                  <>
                    <i className="bi bi-clipboard-data fs-4"></i>
                    <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                      AMMINISTRAZIONE
                    </span>
                  </>
                }
                className="row justify-content-center"
                value={3}
              ></Tab>
              <Tab
                icon={
                  <>
                    <i className="bi bi-clipboard-data fs-4"></i>
                    <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                      DATI
                    </span>
                  </>
                }
                className="row justify-content-center"
                value={4}
              ></Tab>
              <Tab
                icon={
                  <>
                    <i className="bi bi-hammer fs-4"></i>
                    <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                      MANUTENZIONE
                    </span>
                  </>
                }
                className="row justify-content-center"
                value={5}
              ></Tab>
            </TabList>
          </div>
          <div className="card-body">
            <TabPanel value={1} className="row flex-wrap flex-md-nowrap">
              <div className="col-md-8 col-12 p-0">
                <div className=" row">
                  <CFormFloating className="mb-3 col-md-6">
                    <CFormInput
                      className=""
                      type="text"
                      id="MatricolaInput"
                      name="matricola"
                      defaultValue={macchinario.matricola}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>
                      Matricola
                    </CFormLabel>
                  </CFormFloating>
                  {/* <CFormFloating className="mb-3 col-6">
                    <CFormInput
                      type="text"
                      id="MatricolaInput"
                      name="tipologia"
                      defaultValue={macchinario.tipologia}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>
                      Tipologia
                    </CFormLabel>
                  </CFormFloating> */}
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    {searchReady ? (
                      <Autocomplete
                        value={macchinario.tipologia}
                        onChange={(event, newValue) => {
                          let tipo = { ...macchinario };
                          tipo["tipologia"] = newValue;
                          setMacchinario(tipo);
                        }}
                        id="controllable-states-demo"
                        options={tipologiaMacchina}
                        getOptionLabel={(option) => `${option}`}
                        renderInput={(params) => (
                          <TextField {...params} label="Tipologia" />
                        )}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    ) : (
                      ""
                    )}
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      id="MarcaInput"
                      name="marca"
                      defaultValue={macchinario.marca}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>Marca</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      id="ModelloInput"
                      name="modello"
                      defaultValue={macchinario.modello}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>Modello</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      name="descrizione"
                      defaultValue={macchinario.descrizione}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>
                      Descrizione
                    </CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      id="ChassisInput"
                      name="chassis"
                      defaultValue={macchinario.chassis}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>Chassis</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      id="TargaInput"
                      name="targa"
                      defaultValue={macchinario.targa}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>Targa</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      id="AnnoAcquistoInput"
                      name="anno_acquisto"
                      defaultValue={macchinario.anno_acquisto}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>
                      Anno acquisto
                    </CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="d-flex mb-3 col-md-3 col-12">
                    <CFormCheck
                      type="radio"
                      name="stato_attuale"
                      id="Operativo"
                      label="Operativo"
                      checked={
                        macchinario.stato_attuale === "Operativo" ? true : ""
                      }
                      value="Operativo"
                      onChange={(e) => handleChangeRadio("Operativo")}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </CFormFloating>
                  <CFormFloating className="d-flex mb-3 col-md-3 col-12 ">
                    <CFormCheck
                      type="radio"
                      name="stato_attuale"
                      id="Demolito"
                      label="Venduto/Demolito"
                      checked={
                        macchinario.stato_attuale === "Demolito" ||
                        macchinario.stato_attuale === "Venduto"
                          ? true
                          : ""
                      }
                      value="Demolito"
                      onChange={(e) => handleChangeRadio("Demolito")}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </CFormFloating>
                </div>
              </div>
              <div className="d-flex mb-3 col-md-4 col-12 px-0 bg-cover bg-center rounded-lg">
                <div
                  id="qrcode"
                  className="px-0 macchinari-image"
                  style={{
                    background: `url(${
                      macchinario.foto
                        ? "https://portale.fondamentasrl.it/file?file=" +
                          macchinario.foto?.split("/")[1]
                        : ""
                    }) no-repeat center / cover transparent`,
                  }}
                ></div>
              </div>
            </TabPanel>
            <TabPanel value={2} className="p-0">
              {/* Prima riga */}
              <div className="d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.tecnica ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Scheda Tecnica
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.varie1)}
                    style={{
                      display: macchinario.varie1 ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.varie1)}
                    style={{
                      display: macchinario.varie1 ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => deleteFiles("scheda_tecnica", "varie1")}
                      style={{
                        display: macchinario.varie1 ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.varie1 ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        tecnica: !visibile.tecnica,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>

                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("scheda_tecnica");
                      setColonnaDb("varie1");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.varie1
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.tecnica}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        id="ManualeUsoeManutenzione"
                        name="varie1"
                        defaultValue={macchinario.varie1}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>

                <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.ce ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Certificato CE
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_ce)}
                    style={{
                      display: macchinario.documenti_ce ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_ce)}
                    style={{
                      display: macchinario.documenti_ce ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => deleteFiles("ce", "documenti_ce")}
                      style={{
                        display: macchinario.documenti_ce ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    type="button"
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_ce ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({ ...visibile, ce: !visibile.ce })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("ce");
                      setColonnaDb("documenti_ce");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_ce
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse visible={visibile.ce} className="col-md col-12">
                    <CCol>
                      <CFormInput
                        type="text"
                        id="Certificato"
                        name="documenti_ce"
                        defaultValue={macchinario.documenti_ce}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Seconda riga */}
              <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                {/* Metà sinistra */}
                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.cdc ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Carta di Circolazione
                  </CFormLabel>
                  {/* Download */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_cdc)}
                    style={{
                      display: macchinario.documenti_cdc ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_cdc)}
                    style={{
                      display: macchinario.documenti_cdc ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => deleteFiles("cdc", "documenti_cdc")}
                      style={{
                        display: macchinario.documenti_cdc ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_cdc ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        cdc: !visibile.cdc,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("cdc");
                      setColonnaDb("documenti_cdc");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_cdc
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  {/* collapse documento */}
                  <CCollapse visible={visibile.cdc} className="col-md col-12">
                    <CCol>
                      <CFormInput
                        type="text"
                        id="CartaDiCircolazione"
                        className="col"
                        name="documenti_cdc"
                        defaultValue={macchinario.documenti_cdc}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>

                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2">
                  {/* On/Off */}
                  <CFormFloating className="col-4 col-form-label">
                    <CFormSwitch
                      label="Scadenza"
                      checked={circolazione}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                      }
                      id="formSwitch"
                      style={{
                        backgroundColor:
                          moment(new Date()).local().format("YYYY-MM-DD") >=
                          moment(macchinario.scadenza_circolazione)
                            .local()
                            .format("YYYY-MM-DD")
                            ? "red"
                            : "",
                        borderColor: "grey",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCircolazione(true);
                        } else {
                          let macch = { ...macchinario };
                          macch.scadenza_circolazione = null;
                          setMacchinario(macch);
                          setCircolazione(false);
                        }
                      }}
                    />
                  </CFormFloating>
                  {/* collapse Scadenza */}
                  <CCollapse className="col-6" visible={circolazione}>
                    <CCol>
                      <CFormFloating>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enLocale}
                        >
                          <DatePicker
                            label="Scadenza"
                            value={macchinario.scadenza_circolazione}
                            renderInput={(params) => (
                              <TextField {...params} className="p-0" />
                            )}
                            onChange={(newValue) => {
                              let can = { ...macchinario };
                              can["scadenza_circolazione"] = moment(newValue);
                              setMacchinario(can);
                              setCircolazione(true);
                            }}
                            disabled={
                              JSON.parse(localStorage.getItem("permission"))
                                .macchinari.allowEdit === 0
                                ? true
                                : false
                            }
                          />
                        </LocalizationProvider>
                      </CFormFloating>
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Terza riga */}
              <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.ass ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Assicurazione
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_ass)}
                    style={{
                      display: macchinario.documenti_ass ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_ass)}
                    style={{
                      display: macchinario.documenti_ass ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => deleteFiles("ass", "documenti_ass")}
                      style={{
                        display: macchinario.documenti_ass ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_ass ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        ass: !visibile.ass,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("ass");
                      setColonnaDb("documenti_ass");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_ass
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse visible={visibile.ass} className="col-md col-12">
                    <CCol>
                      <CFormInput
                        type="text"
                        id="CertificatoDiProprieta"
                        name="documenti_ass"
                        defaultValue={macchinario.documenti_ass}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>

                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2">
                  <CFormFloating className="col-4 col-form-label">
                    <CFormSwitch
                      label="Scadenza"
                      checked={assicurazione}
                      id="formSwitchCheckDefault"
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                      }
                      style={{
                        backgroundColor:
                          moment(new Date()).local().format("YYYY-MM-DD") >=
                          moment(macchinario.scadenza_assicurazione)
                            .local()
                            .format("YYYY-MM-DD")
                            ? "red"
                            : "",
                        borderColor: "grey",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAssicurazione(true);
                        } else {
                          let macch = { ...macchinario };
                          macch.scadenza_assicurazione = "";
                          setMacchinario(macch);
                          setAssicurazione(false);
                        }
                      }}
                    />
                  </CFormFloating>
                  {/* collapse Scadenza */}
                  <CCollapse className="col-6" visible={assicurazione}>
                    <CCol>
                      <CFormFloating>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enLocale}
                        >
                          <DatePicker
                            label="Scadenza"
                            value={macchinario.scadenza_assicurazione}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(newValue) => {
                              let can = { ...macchinario };
                              can["scadenza_assicurazione"] = moment(newValue);
                              setMacchinario(can);
                              setAssicurazione(true);
                            }}
                            disabled={
                              JSON.parse(localStorage.getItem("permission"))
                                .macchinari.allowEdit === 0
                                ? true
                                : false
                            }
                          />
                        </LocalizationProvider>
                      </CFormFloating>
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Quarta riga */}
              <div className="d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.usoman ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Manuale Uso e Manutenzione
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_usoman)}
                    style={{
                      display: macchinario.documenti_usoman ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_usoman)}
                    style={{
                      display: macchinario.documenti_usoman ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) =>
                        deleteFiles("uso_manutenzione", "documenti_usoman")
                      }
                      style={{
                        display: macchinario.documenti_usoman
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_usoman ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        usoman: !visibile.usoman,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("uso_manutenzione");
                      setColonnaDb("documenti_usoman");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_usoman
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.usoman}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        id="ManualeUsoeManutenzione"
                        name="documenti_usoman"
                        defaultValue={macchinario.documenti_usoman}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>

                <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.catric ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Catalogo Ricambi
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_catric)}
                    style={{
                      display: macchinario.documenti_catric ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_catric)}
                    style={{
                      display: macchinario.documenti_catric ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) =>
                        deleteFiles("catalogo_ricambi", "documenti_catric")
                      }
                      style={{
                        display: macchinario.documenti_catric
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_catric ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        catric: !visibile.catric,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("catalogo_ricambi");
                      setColonnaDb("documenti_catric");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_catric
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.catric}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        id="ManualeUsoeManutenzione"
                        name="documenti_catric"
                        defaultValue={macchinario.documenti_catric}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Quinta riga */}
              <div className="d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.schel ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Schema Impianto Elettrico
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_schel)}
                    style={{
                      display: macchinario.documenti_schel ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_schel)}
                    style={{
                      display: macchinario.documenti_schel ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) =>
                        deleteFiles("schema_el", "documenti_schel")
                      }
                      style={{
                        display: macchinario.documenti_schel ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_schel ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        schel: !visibile.schel,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("schema_el");
                      setColonnaDb("documenti_schel");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_schel
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse visible={visibile.schel} className="col-md col-12">
                    <CCol>
                      <CFormInput
                        type="text"
                        id="ManualeUsoeManutenzione"
                        name="documenti_schel"
                        defaultValue={macchinario.documenti_schel}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>

                <div className="p-1 border border-dark d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.schidr ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Schema Impianto Idraulico
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_schidr)}
                    style={{
                      display: macchinario.documenti_schidr ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_schidr)}
                    style={{
                      display: macchinario.documenti_schidr ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) =>
                        deleteFiles("schema_idr", "documenti_schidr")
                      }
                      style={{
                        display: macchinario.documenti_schidr
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_schidr ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        schidr: !visibile.schidr,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("schema_idr");
                      setColonnaDb("documenti_schidr");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_schidr
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.schidr}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        id="ManualeUsoeManutenzione"
                        name="documenti_schidr"
                        defaultValue={macchinario.documenti_schidr}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Sesta riga */}
              <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.schpneu ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Schema Impianto Pneumatico
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_schpneu)}
                    style={{
                      display: macchinario.documenti_schpneu ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_schpneu)}
                    style={{
                      display: macchinario.documenti_schpneu ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) =>
                        deleteFiles("schema_pn", "documenti_schpneu")
                      }
                      style={{
                        display: macchinario.documenti_schpneu
                          ? "block"
                          : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_schpneu ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        schpneu: !visibile.schpneu,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("schema_pn");
                      setColonnaDb("documenti_schpneu");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.documenti_schpneu
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.schpneu}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        id="ManualeUsoeManutenzione"
                        name="documenti_schpneu"
                        value={macchinario.documenti_schpneu}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Settima Riga */}
              <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.varie2 ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Verifica funi e catene
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.varie2)}
                    style={{
                      display: macchinario.varie2 ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.varie2)}
                    style={{
                      display: macchinario.varie2 ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => deleteFiles("verificafuni", "varie2")}
                      style={{
                        display: macchinario.varie2 ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.varie2 ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        varie2: !visibile.varie2,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("verificafuni");
                      setColonnaDb("varie2");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.varie2
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.varie2}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        id="ManualeUsoeManutenzione"
                        name="varie2"
                        defaultValue={macchinario.varie2}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>

                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2 flex-md-nowrap flex-wrap">
                  <CFormFloating className="col-4 col-form-label">
                    <CFormSwitch
                      label="Scadenza"
                      checked={funiCatene}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                      }
                      id="formSwitchCheckDefault2"
                      style={{
                        backgroundColor:
                          moment(new Date()).local().format("YYYY-MM-DD") >=
                          moment(macchinario.scadenza_varie2)
                            .local()
                            .format("YYYY-MM-DD")
                            ? "red"
                            : "",
                        borderColor: "grey",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFuniCatene(true);
                        } else {
                          let macch = { ...macchinario };
                          macch.scadenza_varie2 = null;
                          setMacchinario(macch);
                          setFuniCatene(false);
                        }
                      }}
                    />
                  </CFormFloating>
                  {/* collapse Scadenza */}
                  <CCollapse className="col-6" visible={funiCatene}>
                    <CCol sm={12}>
                      <CFormFloating>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enLocale}
                        >
                          <DatePicker
                            label="Scadenza"
                            value={macchinario.scadenza_varie2}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(newValue) => {
                              let can = { ...macchinario };
                              can["scadenza_varie2"] = moment(newValue);
                              setMacchinario(can);
                              setFuniCatene(true);
                            }}
                            disabled={
                              JSON.parse(localStorage.getItem("permission"))
                                .macchinari.allowEdit === 0
                                ? true
                                : false
                            }
                          />
                        </LocalizationProvider>
                      </CFormFloating>
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Ottava riga */}
              <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.varie3 ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Verifica periodica
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.varie3)}
                    style={{
                      display: macchinario.varie3 ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.varie3)}
                    style={{
                      display: macchinario.varie3 ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) =>
                        deleteFiles("verificaperiodica", "varie3")
                      }
                      style={{
                        display: macchinario.varie3 ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.varie3 ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        varie3: !visibile.varie3,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("verificaperiodica");
                      setColonnaDb("varie3");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.varie3
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.varie3}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        name="varie3"
                        defaultValue={macchinario.varie3}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>

                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 mt-md-0 mt-2">
                  <CFormFloating className="col-4 col-form-label">
                    <CFormSwitch
                      label="Scadenza"
                      checked={verificaPeriodica}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                      }
                      id="formSwitchCheckDefault3"
                      style={{
                        backgroundColor:
                          moment(new Date()).local().format("YYYY-MM-DD") >=
                          moment(macchinario.scadenza_varie3)
                            .local()
                            .format("YYYY-MM-DD")
                            ? "red"
                            : "",
                        borderColor: "grey",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setVerificaPeriodica(true);
                        } else {
                          let macch = { ...macchinario };
                          macch.scadenza_varie3 = null;
                          setMacchinario(macch);
                          setVerificaPeriodica(false);
                        }

                        e.target.checked
                          ? setVerificaPeriodica(true)
                          : setVerificaPeriodica(false);
                      }}
                    />
                  </CFormFloating>
                  {/* collapse Scadenza */}
                  <CCollapse className="col-6" visible={verificaPeriodica}>
                    <CCol>
                      <CFormFloating>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          locale={enLocale}
                        >
                          <DatePicker
                            label="Scadenza"
                            value={macchinario.scadenza_varie3}
                            renderInput={(params) => <TextField {...params} />}
                            onChange={(newValue) => {
                              let can = { ...macchinario };
                              can["scadenza_varie3"] = moment(newValue);
                              setMacchinario(can);
                              setVerificaPeriodica(true);
                            }}
                            disabled={
                              JSON.parse(localStorage.getItem("permission"))
                                .macchinari.allowEdit === 0
                                ? true
                                : false
                            }
                          />
                        </LocalizationProvider>
                      </CFormFloating>
                    </CCol>
                  </CCollapse>
                </div>
              </div>

              {/* Nona riga */}
              <div className="p-1 border border-dark d-flex justify-content-sm-start justify-content-around align-items-center flex-md-nowrap flex-wrap">
                <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center gap-2 flex-md-nowrap flex-wrap">
                  <CFormLabel
                    className={`col-form-label text-start ${
                      visibile.packingList ? "col-md-2 col-8" : "col-md-5 col-8"
                    }`}
                  >
                    Packing List
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.packingList)}
                    style={{
                      display: macchinario.packingList ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.packingList)}
                    style={{
                      display: macchinario.packingList ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  {JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowDelete !== 0 ? (
                    <CButton
                      className="btn btn-secondary rounded"
                      onClick={(e) => deleteFiles("packinglist", "packingList")}
                      style={{
                        display: macchinario.packingList ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </CButton>
                  ) : (
                    <></>
                  )}

                  <CButton
                    className={`btn btn-secondary rounded ${
                      macchinario.packingList ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        packingList: !visibile.packingList,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>
                  {/* Import */}
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={(e) => {
                      setValueUpload("packinglist");
                      setColonnaDb("packingList");
                      document.getElementById("formFile").click();
                    }}
                    style={{
                      display:
                        location.state.operazione === "Nuovo"
                          ? "none"
                          : JSON.parse(localStorage.getItem("permission"))
                              .macchinari.allowEdit === 0
                          ? "none"
                          : !macchinario.packingList
                          ? "block"
                          : "none",
                    }}
                  >
                    <i className="bi bi-upload"></i>
                  </CButton>
                  <CCollapse
                    visible={visibile.packingList}
                    className="col-md col-12"
                  >
                    <CCol>
                      <CFormInput
                        type="text"
                        id="PackingList"
                        name="packingList"
                        defaultValue={macchinario.packingList}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission"))
                            .macchinari.allowEdit === 0
                        }
                      />
                    </CCol>
                  </CCollapse>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={3}>
              <div className="d-flex col-md-12  flex-md-nowrap flex-wrap">
                <CFormFloating className="col-md-6 col-12 flex-md-nowrap flex-wrap">
                  <CInputGroup className="mb-3 col-12">
                    <CFormLabel className="mx-1 col-4">Cespite</CFormLabel>
                    <CFormInput
                      className="rounded col-8"
                      name="cespite"
                      defaultValue={macchinario.cespite}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </CInputGroup>
                </CFormFloating>
                <CFormFloating className="col-md-6 col-12 flex-md-nowrap flex-wrap">
                  <CInputGroup className="mb-3 col-12">
                    <CFormLabel className="mx-1 col-4">
                      Costo Storico
                    </CFormLabel>
                    <CFormInput
                      className="rounded col-8"
                      name="costo_storico"
                      defaultValue={macchinario.costo_storico}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CInputGroupText>€</CInputGroupText>
                  </CInputGroup>
                </CFormFloating>
              </div>
              <div className="d-flex col-md-12  flex-md-nowrap flex-wrap">
                <CFormFloating className="col-md-6 col-12 flex-md-nowrap flex-wrap">
                  <CInputGroup className="mb-3 col-12">
                    <CFormLabel className="mx-1 col-4">
                      Costo acquisto
                    </CFormLabel>
                    <CFormInput
                      className="rounded col-8"
                      name="costo"
                      defaultValue={macchinario.costo}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </CInputGroup>
                </CFormFloating>
                <CFormFloating className="col-md-6 col-12 flex-md-nowrap flex-wrap">
                  <CInputGroup className="mb-3 col-12">
                    <CFormLabel className="mx-1 col-4">
                      Valore Ammortamento
                    </CFormLabel>
                    <CFormInput
                      className="rounded col-8"
                      name="valore_ammortamento"
                      defaultValue={macchinario.valore_ammortamento}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CInputGroupText>€</CInputGroupText>
                  </CInputGroup>
                </CFormFloating>
              </div>

              <div className="mb-3 gx-0 d-flex col-12 flex-md-nowrap flex-wrap">
                <div className="d-flex col-12 gx-0 flex-md-nowrap flex-wrap">
                  <CFormLabel className="col-md-2 col-6 col-form-label">
                    Fattura di Acquisto
                  </CFormLabel>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => manageFiles(macchinario.documenti_fattura)}
                    style={{
                      display: macchinario.documenti_fattura ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-download"></i>
                  </CButton>
                  <CButton
                    className="btn btn-secondary rounded"
                    onClick={() => viewFiles(macchinario.documenti_fattura)}
                    style={{
                      display: macchinario.documenti_fattura ? "block" : "none",
                    }}
                  >
                    <i className="bi bi-eye"></i>
                  </CButton>
                  <CButton
                    type="button"
                    className={`btn btn-secondary rounded ${
                      macchinario.documenti_fattura ? "d-block" : "d-none"
                    }`}
                    style={{ display: displayNone() }}
                    onClick={() =>
                      setVisibile({
                        ...visibile,
                        fattura: !visibile.fattura,
                      })
                    }
                  >
                    <i className="bi bi-pen"></i>
                  </CButton>

                  <CCollapse
                    visible={visibile.fattura}
                    className="col-md-8 col-12"
                  >
                    <CFormInput
                      type="text"
                      id="Certificato"
                      name="documenti_fattura"
                      defaultValue={macchinario.documenti_fattura}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </CCollapse>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={4} className="p-0">
              <h3>Dati motore</h3>

              <div className="row d-flex ">
                <div className="col-lg-4">
                  <CFormFloating className="col-4" style={{ width: "100%" }}>
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="motore_marca"
                      value={datiMacchina?.motore_marca}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Marca</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-4">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="motore_modello"
                      value={datiMacchina?.motore_modello}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Modello</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-4">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="motore_SN"
                      value={datiMacchina?.motore_SN}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>S/N</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-4">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="motore_potenza"
                      value={datiMacchina?.motore_potenza}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Potenza</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-4">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="motore_note"
                      value={datiMacchina?.motore_note}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Note</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <h3>Dati trasmissione</h3>

              <div className="row d-flex ">
                <div className="col-lg-6">
                  <CFormFloating className="col-4" style={{ width: "100%" }}>
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="trasmissione_marca"
                      value={datiMacchina?.trasmissione_marca}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Marca</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="trasmissione_modello"
                      value={datiMacchina?.trasmissione_modello}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Modello</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="trasmissione_SN"
                      value={datiMacchina?.trasmissione_SN}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>S/N</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="trasmissione_note"
                      value={datiMacchina?.trasmissione_note}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Note</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <h3>Dimensione macchina base</h3>

              <div className="row d-flex ">
                <div className="col-lg-6">
                  <CFormFloating className="col-4" style={{ width: "100%" }}>
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="macchina_base_lunghezza"
                      value={datiMacchina?.macchina_base_lunghezza}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Lunghezza</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="macchina_base_larghezza"
                      value={datiMacchina?.macchina_base_larghezza}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Larghezza</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="macchina_base_altezza"
                      value={datiMacchina?.macchina_base_altezza}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Altezza</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-6">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="macchina_base_peso"
                      value={datiMacchina?.macchina_base_peso}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Peso</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <div className="row d-flex mt-3">
                <div className="col-lg-4">
                  <CFormFloating className="col-4" style={{ width: "100%" }}>
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="equipaggiamento"
                      value={datiMacchina?.equipaggiamento}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Equipaggiamenti</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-4">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="accessori"
                      value={datiMacchina?.accessori}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Accessori</CFormLabel>
                  </CFormFloating>
                </div>
                <div className="col-lg-4">
                  <CFormFloating className="mb-3">
                    <CFormInput
                      type="text"
                      id="DescrizioneInput"
                      onChange={handleChangeDatiMacchinario}
                      name="note"
                      value={datiMacchina?.note}
                      disabled={
                        JSON.parse(localStorage.getItem("permission"))
                          .macchinari.allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel>Note</CFormLabel>
                  </CFormFloating>
                </div>
              </div>
              <div className="row d-flex">
                <div className="col-lg-12"></div>
              </div>
            </TabPanel>
            <TabPanel value={5} className="p-0">
              <CTable className="row gx-0">
                <CTableHead className="" style={{ maxWidth: "810px" }}>
                  <CTableRow>
                    <CTableHeaderCell scope="col" className="w-25">
                      Comp.
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="w-25">
                      Marca e modello
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="w-25">
                      Quantità
                    </CTableHeaderCell>
                    <CTableHeaderCell scope="col" className="w-25 p-0">
                      Frequenza manut./sostituz.
                    </CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {manutenzioneMacchina.map((manut) => {
                    return <>{rendOutputManutenzioni(manut)}</>;
                  })}
                </CTableBody>
              </CTable>
              <CButton
                className="btn btn-green mb-4"
                onClick={() => saveManutenzioneMacchinario()}
                style={{
                  width: "inherit",
                  display:
                    macchinario.matricola !== "" &&
                    JSON.parse(localStorage.getItem("permission")).macchinari
                      .allowEdit !== 0
                      ? ""
                      : "None",
                }}
              >
                Salva Manutenzioni
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
              </CButton>
            </TabPanel>
          </div>
        </TabContext>
        <div className="d-none mb-3">
          <input
            className="form-control"
            type="file"
            id="formFile"
            onChange={(e) => {
              loadFile(
                e.target.files[0],
                macchinario.matricola,
                valueUpload,
                colonnaDb
              );
            }}
          />
        </div>
        {/* FOOTER */}
        <div className="card-footer d-flex justify-content-between ">
          <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap">
            <ReusableButton
              text="STAMPA"
              icon="printer"
              size="xxs"
              className={`${
                location.state.operazione === "Nuovo" ? "d-none" : "d-block"
              }`}
              onClick={() => {
                navigate(`/home/macchinari/stampa/${macchinario.matricola}`, {
                  state: {
                    macchinario: macchinario,
                    stampa: "MacchinarioDettaglio",
                  },
                });
              }}
            />
            <ReusableButton
              text="QR CODE"
              icon="qr-code"
              className={`${
                location.state.operazione === "Nuovo" ? "d-none" : "d-block"
              }`}
              size="xxs"
              onClick={() => {
                let arr = [];
                arr.push(macchinario);
                navigate(`/home/macchinari/print-qrcode`, {
                  state: { macchinari: arr },
                });
              }}
            />
            <ReusableButton
              text="ARCHIVIO"
              icon="archive"
              size="xxs"
              onClick={() =>
                //https://portale.dscloud.me:7036/index.cgi?launchApp=SYNO.SDS.App.FileStation3.Instance&launchParam=openfile%3D%252FPORTALE%252F
                window.open(
                  "https://portale.dscloud.me:7036/index.cgi?launchApp=SYNO.SDS.App.FileStation3.Instance&launchParam=openfile%3D%252FPORTALE%252F" +
                    macchinario.matricola +
                    "%252F#/signin"
                )
              }
            />
            <ReusableButton
              className={`${
                location.state.operazione === "Nuovo" ? "d-none" : "d-block"
              }`}
              text="ESPORTA"
              icon="cloud-download"
              size="xxs"
              onClick={() => zipAllFiles()}
            />
          </div>

          <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
            <ReusableButton
              text="ELIMINA"
              icon="trash"
              size="xs"
              className={`bg-red ${
                permission.macchinari.allowDelete === 0
                  ? "d-none"
                  : location.state.operazione === "Nuovo"
                  ? "d-none"
                  : "d-flex"
              }`}
              onClick={() => handlleDelete()}
            />
            <ReusableButton
              text="SALVA"
              icon="check"
              size="xs"
              onClick={() => modifyMacchinario()}
              style={{
                display:
                  JSON.parse(localStorage.getItem("permission")).macchinari
                    .allowEdit === 0
                    ? "none"
                    : "flex",
              }}
            />
          </div>
        </div>
      </div>

      {/* ASSOCIATI */}
      <div className="mt-4 mx-0 bg-white rounded  border-classic">
        <div className="row d-flex justify-content-start"></div>
        <TabContext value={valueBottom}>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}
          >
            <TabList
              className="mx-auto"
              onChange={handleChangeValueBottom}
              aria-label="lab API tabs example"
            >
              <Tab
                label="Manutenzioni"
                value={1}
                icon={
                  <span
                    style={{
                      border: "2px solid #1976d2",
                      borderRadius: "50%",
                      padding: "5px 8px 5px 8px",
                    }}
                  >
                    {" " + nManutenzioni}
                  </span>
                }
                iconPosition="end"
              />
              <Tab
                label="Accise"
                value={2}
                icon={
                  <span
                    style={{
                      border: "2px solid #1976d2",
                      borderRadius: "50%",
                      padding: "5px 8px 5px 8px",
                    }}
                  >
                    {" " + nAccise}
                  </span>
                }
                iconPosition="end"
              />
            </TabList>
          </Box>
          <TabPanel value={1} className="p-0">
            <CRow className="row p-2">
              <ReusableButton
                text="AGGIUNGI"
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                icon="plus-circle"
                size="xxs"
                onClick={() =>
                  navigate("/home/manutenzioni/:id", {
                    state: {
                      manutenzione: macchinario.matricola,
                      operazione: "MacchinarioNuovo",
                    },
                  })
                }
              />
              <ReusableButton
                text="RICARICA"
                icon="arrow-clockwise"
                size="xxs"
                onClick={() => window.location.reload()}
              />
              <ReusableButton
                text="EXCEL"
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                icon="printer"
                size="xxs"
                onClick={() =>
                  navigate("/tabellaxls", {
                    state: {
                      macchinario: { ...macchinario },
                      manutenzioneMacchina: [...manutenzioneMacchina],
                      datiMacchina: { ...datiMacchina },
                      manutenzioni: [...manutenzione],
                    },
                  })
                }
              />
            </CRow>
            <div style={{ height: 400, width: "100%" }}>
              <DataGridPro
                rows={manutenzione}
                columns={columnsManutenzioni}
                pageSize={5}
                checkboxSelection
                disableSelectionOnClick
                onRowClick={(paramsManutenzioni) => {
                  const dataManutenzione = manutenzione.filter(
                    (manutenzione) =>
                      manutenzione.id === paramsManutenzioni.row.id
                  );
                  navigate(
                    `/home/manutenzioni/${dataManutenzione[0].matricola}`,
                    {
                      state: {
                        manutenzione: dataManutenzione[0],
                        operazione: "Modifica",
                      },
                    }
                  );
                }}
              />
            </div>
          </TabPanel>
          <TabPanel value={2} className="p-0">
            <CRow className="row p-2">
              <ReusableButton
                text="AGGIUNGI"
                icon="plus-circle"
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                size="xxs"
                onClick={() =>
                  navigate("/home/accise/:id", {
                    state: {
                      accise: macchinario.matricola,
                      operazione: "MacchinarioNuovo",
                    },
                  })
                }
              />
              <ReusableButton
                text="RICARICA"
                icon="arrow-clockwise"
                size="xxs"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </CRow>
            <div style={{ height: 400, width: "100%" }} id="accisaCorrelata">
              <DataGridPro
                rows={accise}
                columns={columnsAccise}
                pageSize={5}
                checkboxSelection
                disableSelectionOnClick
                onRowClick={(paramsAccise) => {
                  const dataAccise = accise.filter(
                    (accisa) => accisa.id === paramsAccise.row.id
                  );
                  navigate(`/home/accise/${dataAccise[0].matricola}`, {
                    state: { accise: dataAccise[0], operazione: "Modifica" },
                  });
                }}
              />
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default MacchinarioDati;
