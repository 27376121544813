import React from "react";
import Header from "./Header";
import { Outlet } from 'react-router-dom';
const AppLayout = () => {

return(
        <>
            <Header/>
            <div className="pt-5">
                <Outlet />     
            </div>
        </>
    )
}



export default AppLayout;