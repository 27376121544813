import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./component/login";
import Home from "./component/Home";
import Header from "./component/Header";
import Macchinari from "./component/Macchinari";
import Cantieri from "./component/Cantieri";
import NewManutenzioni from "./component/NewManutenzioni";
import AdminPage from "./component/AdminPage";
import MemberShip from "./component/MemberShip";
import SafeRoute from "./component/SafeRoute";
import SafeRouteAdmin from "./component/SafeRouteAdmin";
import MacchinarioDati from "./component/MacchinarioDati";
import Accise from "./component/Accise";
import Manutenzioni from "./component/Manutenzioni";
import AccisaDati from "./component/AccisaDati";
import PrintMacchinario from "./component/PrintMacchinario";
import DettaglioCantiere from "./component/DettaglioCantiere";
import Lavorazioni from "./component/Lavorazioni";
import DettaglioLavorazioni from "./component/DettaglioLavorazioni";
import DettaglioUtente from "./component/DettaglioUtente";
import Produzione from "./component/Produzione";
import DettaglioProduzione from "./component/DettaglioProduzione";
import Costi from "./component/Costi";
import DettaglioCosti from "./component/DettaglioCosti";
import Noleggi from "./component/Noleggi";
import DettaglioNoleggi from "./component/DettaglioNoleggi";
import Report from "./component/Report";
import ElencoManutenzioni from "./component/ElencoManutenzioni";
import DettaglioElencoManutenzioni from "./component/DettaglioElencoManutenzioni";
import PrintQrcode from "./component/PrintQrcode";
import AtterraggioQrcode from "./component/AtterraggioQrcode";
import AppLayout from "./component/AppLayout";
import GraficaNew from "./component/GraficaNew";
import Tipologia from "./component/Tipologia";
import DettaglioTipologia from "./component/DettaglioTipologia";
import DettaglioMeccanici from "./component/DettaglioMeccanici";
import Meccanici from "./component/Meccanici";
import DettaglioWbs from "./component/DettaglioWbs";
import Wbs from "./component/Wbs";
import DettaglioOperatore from "./component/DettaglioOperatore";
import Operatore from "./component/Operatore";
import TipologiaCosti from "./component/TipologiaCosti";
import DettaglioTipologiaCosti from "./component/DettaglioTipologiaCosti";

import "./component/SafeRoute";
import DettaglioGruppi from "./component/DettaglioGruppi";
import TabellaXls from "./component/TabellaXls";
import Referenti from "./component/Referenti";
import DettaglioReferenti from "./component/DettaglioReferenti";

import { LicenseInfo } from "@mui/x-data-grid-pro";
import StoricoNoleggi from "./component/StoricoNoleggio";
import Personale from "./component/Personale";
import Mansioni from "./component/Mansioni";
import DettaglioMansioni from "./component/DettaglioMansioni";
import DettaglioPersonale from "./component/DettaglioPersonale";
import PersonaleQrcode from "./component/PersonaleQrcode";
import AtterraggioPersonaleQrcode from "./component/AtterraggioPersonaleQrcode";
import LoginQr from "./component/LoginQr";
LicenseInfo.setLicenseKey(
  "5b30fac79aff89e43e91021018cca43dTz04MTU1MyxFPTE3MzU5OTY0NDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  return (
    <div>
      <main>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login-qr" element={<LoginQr />} />
          <Route
            path="/"
            element={
              <SafeRoute>
                <AppLayout />
              </SafeRoute>
            }
          >
            <Route
              path="/home"
              element={
                <SafeRoute>
                  <Home />
                </SafeRoute>
              }
            />
            <Route
              path="/admin-page"
              element={
                <SafeRouteAdmin>
                  <AdminPage />
                </SafeRouteAdmin>
              }
            />
            <Route
              path="/membership"
              element={
                <SafeRoute>
                  <MemberShip />
                </SafeRoute>
              }
            />
            <Route
              path="/home/macchinari"
              element={
                <SafeRoute>
                  <Macchinari />
                </SafeRoute>
              }
            />
            <Route
              path="/home/macchinari/:id"
              element={
                <SafeRoute>
                  <MacchinarioDati />
                </SafeRoute>
              }
            />
            <Route
              path="/home/macchinari/stampa/:id"
              element={
                <SafeRoute>
                  <PrintMacchinario />
                </SafeRoute>
              }
            />
            <Route
              path="/home/accise"
              element={
                <SafeRoute>
                  <Accise />
                </SafeRoute>
              }
            />
            <Route
              path="/home/accise/:id"
              element={
                <SafeRoute>
                  <AccisaDati />
                </SafeRoute>
              }
            />
            <Route
              path="/home/manutenzioni"
              element={
                <SafeRoute>
                  <Manutenzioni />
                </SafeRoute>
              }
            />
            <Route
              path="/home/manutenzioni/:id"
              element={
                <SafeRoute>
                  <NewManutenzioni />
                </SafeRoute>
              }
            />
            <Route
              path="/home/cantieri"
              element={
                <SafeRoute>
                  <Cantieri />
                </SafeRoute>
              }
            />
            <Route
              path="/home/cantieri/dettaglio-cantiere"
              element={
                <SafeRoute>
                  <DettaglioCantiere />
                </SafeRoute>
              }
            />
            <Route
              path="/home/lavorazioni"
              element={
                <SafeRoute>
                  <Lavorazioni />
                </SafeRoute>
              }
            />
            <Route
              path="/home/lavorazioni/dettaglio-lavorazioni"
              element={
                <SafeRoute>
                  <DettaglioLavorazioni />
                </SafeRoute>
              }
            />
            <Route
              path="/membership/dettaglio-utente"
              element={
                <SafeRoute>
                  <DettaglioUtente />
                </SafeRoute>
              }
            />
            <Route
              path="/home/produzione"
              element={
                <SafeRoute>
                  <Produzione />
                </SafeRoute>
              }
            />
            <Route
              path="/home/produzione/dettaglio-produzione"
              element={
                <SafeRoute>
                  <DettaglioProduzione />
                </SafeRoute>
              }
            />
            <Route
              path="/home/costi"
              element={
                <SafeRoute>
                  <Costi />
                </SafeRoute>
              }
            />
            <Route
              path="/home/costi/dettaglio-costi"
              element={
                <SafeRoute>
                  <DettaglioCosti />
                </SafeRoute>
              }
            />
            <Route
              path="/home/noleggi"
              element={
                <SafeRoute>
                  <Noleggi />
                </SafeRoute>
              }
            />
            <Route
              path="/home/noleggi/dettaglio-noleggi"
              element={
                <SafeRoute>
                  <DettaglioNoleggi />
                </SafeRoute>
              }
            />
            <Route
              path="/home/noleggi/dettaglio-noleggi/:id"
              element={
                <SafeRoute>
                  <StoricoNoleggi />
                </SafeRoute>
              }
            />
            <Route
              path="/home/report"
              element={
                <SafeRoute>
                  <Report />
                </SafeRoute>
              }
            />

            <Route
              path="/home/elenco-manutenzioni"
              element={
                <SafeRoute>
                  <ElencoManutenzioni />
                </SafeRoute>
              }
            />
            <Route
              path="/home/elenco-manutenzioni/:id"
              element={
                <SafeRoute>
                  <DettaglioElencoManutenzioni />
                </SafeRoute>
              }
            />
            <Route
              path="/membership/dettaglio-gruppo"
              element={
                <SafeRoute>
                  <DettaglioGruppi />
                </SafeRoute>
              }
            />
            <Route
              path="/home/macchinari/print-qrcode"
              element={
                <SafeRoute>
                  <PrintQrcode />
                </SafeRoute>
              }
            />
            <Route
              path="/home/personale/print-qrcode"
              element={
                <SafeRoute>
                  <PersonaleQrcode />
                </SafeRoute>
              }
            />
            <Route
              path="/tabellaxls"
              element={
                <SafeRoute>
                  <TabellaXls />
                </SafeRoute>
              }
            />
            <Route
              path="/home/referenti"
              element={
                <SafeRoute>
                  <Referenti />
                </SafeRoute>
              }
            />
            <Route
              path="/home/tipologia"
              element={
                <SafeRoute>
                  <Tipologia />
                </SafeRoute>
              }
            />
            <Route
              path="/home/tipologia/dettaglio-tipologia"
              element={
                <SafeRoute>
                  <DettaglioTipologia />
                </SafeRoute>
              }
            />
            <Route
              path="/home/meccanici"
              element={
                <SafeRoute>
                  <Meccanici />
                </SafeRoute>
              }
            />
            <Route
              path="/home/meccanici/dettaglio-meccanici"
              element={
                <SafeRoute>
                  <DettaglioMeccanici />
                </SafeRoute>
              }
            />
            <Route
              path="/home/wbs"
              element={
                <SafeRoute>
                  <Wbs />
                </SafeRoute>
              }
            />
            <Route
              path="/home/wbs/dettaglio-wbs"
              element={
                <SafeRoute>
                  <DettaglioWbs />
                </SafeRoute>
              }
            />
            <Route
              path="/home/operatore"
              element={
                <SafeRoute>
                  <Operatore />
                </SafeRoute>
              }
            />
            <Route
              path="/home/operatore/dettaglio-operatore"
              element={
                <SafeRoute>
                  <DettaglioOperatore />
                </SafeRoute>
              }
            />
            <Route
              path="/home/tipologia-costi"
              element={
                <SafeRoute>
                  <TipologiaCosti />
                </SafeRoute>
              }
            />
            <Route
              path="/home/tipologia-costi/dettaglio-tipologia-costi"
              element={
                <SafeRoute>
                  <DettaglioTipologiaCosti />
                </SafeRoute>
              }
            />
            <Route
              path="/home/mansioni"
              element={
                <SafeRoute>
                  <Mansioni />
                </SafeRoute>
              }
            />
            <Route
              path="/home/mansioni/dettaglio-mansioni"
              element={
                <SafeRoute>
                  <DettaglioMansioni />
                </SafeRoute>
              }
            />
            <Route
              path="/home/referenti/:id"
              element={
                <SafeRoute>
                  <DettaglioReferenti />
                </SafeRoute>
              }
            />
            <Route
              path="/home/personale"
              element={
                <SafeRoute>
                  <Personale />
                </SafeRoute>
              }
            />
            <Route
              path="/home/personale/:id"
              element={
                <SafeRoute>
                  <DettaglioPersonale />
                </SafeRoute>
              }
            />
          </Route>
          <Route
            path="/home/macchinari/qrcode/:id"
            element={<AtterraggioQrcode />}
          />
          <Route
            path="/home/personale/qrcode/:id"
            element={<AtterraggioPersonaleQrcode qrCode={"personale"} />}
          />
          <Route path="/grafica" element={<GraficaNew />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
