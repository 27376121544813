import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./api";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CCol,
  CButton,
  CFormInput,
  CFormLabel,
  CFormFloating,
} from "@coreui/react";
import "../index.scss";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";

const MemberShip = () => {
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const navigate = useNavigate();
  const handleClick = async (page) => {
    navigate(page);
  };
  const handleChange = (e) => {
    let copiaUser = { ...user };
    copiaUser[e.target.name] = e.target.value;

    setUser(copiaUser);
  };

  const handleUpdateButton = async () => {
    await api.put(
      "api/users",
      { auth: "a5d42c3ed7977afa2d8e922569e7940b", user: user },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    alert("Modifica effettuata");
  };
  useEffect(() => {
    const fetchData = async () => {
      await api
        .get("api/users", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { user: localStorage.getItem("nomeUtente") },
        })
        .then((res) => {
          setUser(res.data.data[0]);
        });
    };

    fetchData();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">
              <p>Ciao {localStorage.getItem("nomeUtente")}!</p>
            </span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
          </div>

          {/* BODY */}
          <div className="card-body">
            <CFormFloating className="mb-3">
              <CFormInput
                type="text"
                id="DescrizioneInput"
                onChange={(e) => handleChange(e)}
                name="email"
                value={user.email}
              />
              <CFormLabel>Indirizzo Email</CFormLabel>
            </CFormFloating>
            <CFormFloating className="mb-3">
              <CFormInput
                type="text"
                id="DescrizioneInput"
                onChange={(e) => handleChange(e)}
                name="custom1"
                value={user.custom1}
              />
              <CFormLabel>Nome completo</CFormLabel>
            </CFormFloating>
            <CFormFloating className="mb-3">
              <CFormInput
                type="text"
                id="DescrizioneInput"
                onChange={(e) => handleChange(e)}
                name="custom2"
                value={user.custom2}
              />
              <CFormLabel>Indirizzo</CFormLabel>
            </CFormFloating>
            <CFormFloating className="mb-3">
              <CFormInput
                type="text"
                id="DescrizioneInput"
                onChange={(e) => handleChange(e)}
                name="custom3"
                value={user.custom3}
              />
              <CFormLabel>Città</CFormLabel>
            </CFormFloating>
            <CFormFloating className="mb-3">
              <CFormInput
                type="text"
                id="DescrizioneInput"
                onChange={(e) => handleChange(e)}
                name="custom4"
                value={user.custom4}
              />
              <CFormLabel>Stato</CFormLabel>
            </CFormFloating>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap gap-2">
            <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap"></div>

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                onClick={() => handleUpdateButton()}
              />
              {/* <button
                className="btn bg-green text-white fs-6"
                onClick={() => handleUpdateButton()}
              >
                <i className="bi bi-check fs-5"></i> SALVA
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberShip;
