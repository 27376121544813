import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useLocation } from "react-router-dom";
import logo from "../img/logofond.jpg";
import moment from "moment";

const TabellaXls = () => {
  const location = useLocation();
  const [macchinario, setMacchinario] = useState([]);
  const [manutenzioneMacchina, setManutenzioneMacchina] = useState([]);
  const [datiMacchina, setDatiMacchina] = useState([]);
  const [manutenzioni, setManutenzioni] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      setManutenzioneMacchina(location.state.manutenzioneMacchina);
      setManutenzioni(location.state.manutenzioni);
      setDatiMacchina(location.state.datiMacchina);
      setMacchinario(location.state.macchinario);
    };
    fetchData();
  }, []);
  return (
    <div style={{ marginTop: "70px" }}>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="table-to-xls"
        filename={"stampa attrezzature" + macchinario.matricola}
        sheet={"stampa attrezzature" + macchinario.matricola}
        buttonText="Scarica come XLS"
      />

      <table
        id="table-to-xls"
        style={{ marginTop: 10 }}
        cellSpacing={0}
        border={0}
      >
        <colgroup width={106} />
        <colgroup width={93} />
        <colgroup span={4} width={32} />
        <colgroup width={36} />
        <colgroup span={2} width={32} />
        <colgroup width={38} />
        <colgroup width={612} />
        <colgroup width={140} />
        <colgroup width={174} />
        <tbody>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={13}
              height={59}
              align="center"
              valign="middle"
            >
              <b>
                <i>
                  <font face="Arial" size={5} color="#000000">
                    SCHEDA MANUTENZIONI ATTREZZATURE
                    <img
                      src={logo}
                      width={222}
                      height={50}
                      hspace={5}
                      vspace={4}
                    />
                  </font>
                </i>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={13}
              height={30}
              align="center"
              valign="middle"
              bgcolor="#E5E5E5"
            >
              <b>
                <i>
                  <font face="Arial" size={4} color="#000000">
                    Dati generali
                  </font>
                </i>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={4}
              height={27}
              align="left"
              valign="middle"
            >
              <b>
                <font face="Arial" color="#000000">
                  Marca
                </font>
              </b>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={6}
              align="left"
              valign="middle"
            >
              <b>
                <font face="Arial" color="#000000">
                  Modello
                </font>
              </b>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="left"
              valign="middle"
            >
              <b>
                <font face="Arial" color="#000000">
                  Telaio - matricola
                </font>
              </b>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="middle"
            >
              <b>
                <font face="Arial" color="#000000">
                  Matricola interna
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={4}
              height={27}
              align="center"
              valign="middle"
            >
              <font face="Arial" color="#000000">
                {macchinario.marca}
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={6}
              align="center"
              valign="middle"
            >
              <font face="Arial" color="#000000">
                {macchinario.modello}
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
            >
              <font face="Arial" color="#000000">
                {macchinario.chassis}
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" color="#000000">
                {macchinario.matricola}
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={11}
              height={27}
              align="left"
              valign="middle"
            >
              <b>
                <font face="Arial" color="#000000">
                  Motore:
                </font>
              </b>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="left"
              valign="middle"
            >
              <b>
                <font face="Arial" color="#000000">
                  Tipo attrezzatura
                </font>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={11}
              height={27}
              align="center"
              valign="middle"
            >
              <font face="Arial" color="#000000">
                {datiMacchina.motore_modello}
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" color="#000000">
                {macchinario.tipologia}
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={13}
              height={30}
              align="center"
              valign="middle"
              bgcolor="#E5E5E5"
            >
              <b>
                <i>
                  <font face="Arial" size={4} color="#000000">
                    Filtri e olii
                  </font>
                </i>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "1px solid #3f3f3f",
                borderLeft: "1px solid #3f3f3f",
              }}
              colSpan={4}
              height={26}
              align="center"
              valign="middle"
              bgcolor="#F2F2F2"
            >
              <b>
                <font face="Arial" color="#3F3F3F">
                  Componente
                </font>
              </b>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={7}
              align="center"
              valign="middle"
              bgcolor="#F2F2F2"
            >
              <b>
                <font face="Arial" color="#3F3F3F">
                  Marca e modello
                </font>
              </b>
            </td>
            <td
              style={{
                borderBottom: "1px solid #3f3f3f",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
              bgcolor="#F2F2F2"
            >
              <b>
                <font face="Arial" color="#3F3F3F">
                  Quantità
                </font>
              </b>
            </td>
            <td
              style={{
                borderBottom: "1px solid #3f3f3f",
                borderRight: "1px solid #3f3f3f",
              }}
              align="center"
              valign="middle"
              bgcolor="#F2F2F2"
            >
              <b>
                <font face="Arial" size={1} color="#3F3F3F">
                  Frequenza
                  <br />
                  manut./sostituz.
                </font>
              </b>
            </td>
          </tr>

          {manutenzioneMacchina !== []
            ? manutenzioneMacchina?.map((manut) => {
                let comp = manut.componente.replaceAll("_", " ");
                return (
                  <>
                    <tr>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                        }}
                        colSpan={4}
                        height={25}
                        align="left"
                        valign="top"
                      >
                        <font face="Arial">
                          {comp.charAt(0).toUpperCase() + comp.slice(1)}
                        </font>
                      </td>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          borderRight: "1px solid #000000",
                        }}
                        colSpan={7}
                        align="center"
                        valign="middle"
                      >
                        <font face="Arial" color="#000000">
                          {manut.marca_e_modello}
                        </font>
                      </td>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          borderLeft: "1px solid #000000",
                          borderRight: "1px solid #000000",
                        }}
                        align="center"
                        valign="middle"
                        sdval={1}
                        sdnum="1033;"
                      >
                        <font face="Arial" color="#000000">
                          {manut.quantita}
                        </font>
                      </td>
                      <td
                        style={{
                          borderTop: "1px solid #000000",
                          borderBottom: "1px solid #000000",
                          borderRight: "1px solid #000000",
                        }}
                        align="left"
                        valign="bottom"
                      >
                        <font face="Arial" color="#000000">
                          {manut.frequenza}
                        </font>
                      </td>
                    </tr>
                  </>
                );
              })
            : ""}

          <tr>
            <td
              style={{ borderLeft: "2px solid #000000" }}
              colSpan={13}
              height={30}
              align="center"
              valign="middle"
              bgcolor="#E5E5E5"
            >
              <b>
                <i>
                  <font face="Arial" size={4} color="#000000">
                    Registrazione manutenzioni effettuate
                  </font>
                </i>
              </b>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              rowSpan={2}
              height={40}
              align="center"
              valign="middle"
            >
              <b>
                <font face="Arial" size={1}>
                  Data
                </font>
              </b>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              rowSpan={2}
              align="center"
              valign="middle"
            >
              <b>
                <font face="Arial" size={1}>
                  Ore
                </font>
              </b>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Motore
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Idraulico
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              rowSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Filtro
                <br />
                Gasolio
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Filtro aria
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              colSpan={2}
              rowSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Descrizione interventi
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              rowSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Firma
              </font>
            </td>
            <td
              style={{
                borderTop: "1px solid #000000",
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              rowSpan={2}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                cantiere
              </font>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Olio
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Filtro
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Olio
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Filtro
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Interno
              </font>
            </td>
            <td
              style={{
                borderBottom: "1px solid #000000",
                borderLeft: "1px solid #000000",
                borderRight: "1px solid #000000",
              }}
              align="center"
              valign="middle"
            >
              <font face="Arial" size={1}>
                Esterno
              </font>
            </td>
          </tr>
          {manutenzioni?.map((manut) => {
            return (
              <>
                <tr>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    height={49}
                    align="center"
                    valign="middle"
                    sdval={44075}
                    sdnum="1033;1033;M/D/YYYY"
                  >
                    <font face="Arial" color="#000000">
                      {moment(new Date(manut.data))
                        .local()
                        .format("YYYY-MM-DD")
                        .toString()}
                    </font>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                    sdval={0}
                    sdnum="1033;"
                  >
                    <font face="Arial" color="#000000">
                      {manut.ore_km}
                    </font>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <b>
                      <font face="Arial" size={5} color="#000000">
                        {manut.manutenzione?.includes("Cambio Olio Motore")
                          ? "X"
                          : ""}
                      </font>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <b>
                      <font face="Arial" size={5} color="#000000">
                        {manut.manutenzione?.includes("Filtro Motore")
                          ? "X"
                          : ""}
                      </font>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <font face="Arial" color="#000000">
                      {manut.manutenzione?.includes("Cambio Olio Idraulico")
                        ? "X"
                        : ""}
                    </font>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <b>
                      <font face="Arial" size={5} color="#000000">
                        {manut.manutenzione?.includes("Filtro Olio idraulico")
                          ? "X"
                          : ""}
                      </font>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <b>
                      <font face="Arial" size={5} color="#000000">
                        {manut.manutenzione?.includes("Filtro Gasolio")
                          ? "X"
                          : ""}
                      </font>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <b>
                      <font face="Arial" size={5} color="#000000">
                        {manut.manutenzione?.includes("Filtro Aria Interno")
                          ? "X"
                          : ""}
                      </font>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <b>
                      <font face="Arial" size={5} color="#000000">
                        {manut.manutenzione?.includes("Filtro Aria Esterno")
                          ? "X"
                          : ""}
                      </font>
                    </b>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    colSpan={2}
                    align="left"
                    valign="middle"
                  >
                    <font face="Arial">{manut.note}</font>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                  >
                    <font face="Arial">{manut.meccanico}</font>
                  </td>
                  <td
                    style={{
                      borderTop: "1px solid #000000",
                      borderBottom: "1px solid #000000",
                      borderLeft: "1px solid #000000",
                      borderRight: "1px solid #000000",
                    }}
                    align="center"
                    valign="middle"
                    sdval={1130}
                    sdnum="1033;"
                  >
                    <font face="Arial">{manut.cantiere}</font>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TabellaXls;
