import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "./api";
import { DataGridPro, GridToolbar, itIT } from "@mui/x-data-grid-pro";
import { CContainer, CButton } from "@coreui/react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../index.scss";
import GlobalSnackbar from "./Snackbar";

const columns = [
  {
    field: "memberID",
    headerName: "Username",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "Group",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "custom1",
    headerName: "Full Name",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "custom2",
    headerName: "Address",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "custom3",
    headerName: "City",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "custom4",
    headerName: "State",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
];

const groupsColumns = [
  {
    field: "name",
    headerName: "Group",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "description",
    headerName: "Description",
    width: 400,
    headerAlign: "center",
    align: "center",
  },
];

const AdminPage = () => {
  const [users, setUsers] = useState({});
  const [groups, setGroups] = useState({});
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const fetchData = async () => {
      await api
        .get("api/users", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { user: 0 },
        })
        .then((res) => {
          setUsers(res.data.data);
        });

      await api
        .get("api/groups", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          setGroups(res.data.data);
        });
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic">
          {/* HEADER */}
          <div className="card-header position-relative d-flex justify-content-center">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="d-flex justify-content-center"
            >
              <Tab label="Users" {...a11yProps(0)} />
              <Tab label="Groups" {...a11yProps(1)} />
            </Tabs>
            <span className="position-absolute top-50 end-0 translate-middle-y mr-3">
              {" "}
            </span>{" "}
            <a
              style={{ cursor: "pointer", color: "black" }}
              className="position-absolute top-50 end-0 translate-middle-y indigo-500"
              onClick={() => setOptions(!options)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="30"
                fill="currentColor"
                className="bi bi-three-dots-vertical pr-3"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </a>
            <ul
              className={`dropdown-menu position-absolute top-100 end-0 ${
                options ? "show" : ""
              }`}
            >
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    navigate(`/membership/dettaglio-utente`, {
                      state: {
                        utente: {
                          memberID: "",
                          passMD5: "",
                          email: "",
                          signupDate: "",
                          groupID: "",
                          isBanned: "",
                          isApproved: "",
                          custom1: "",
                          custom2: "",
                          custom3: "",
                          custom4: "",
                          comments: "",
                          pass_reset_key: "",
                          pass_reset_expiry: "",
                          flags: "",
                        },
                        operazione: "Nuovo",
                      },
                    })
                  }
                >
                  <i className="bi bi-plus-circle"></i> AGGIUNGI UTENTE
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() =>
                    navigate(`/membership/dettaglio-gruppo`, {
                      state: { operazione: "Nuovo" },
                    })
                  }
                >
                  <i className="bi bi-plus-circle"></i> AGGIUNGI GRUPPO
                </a>
              </li>
            </ul>
          </div>

          {/* BODY */}
          <div className="card-body" style={{ padding: 0 }}>
            <TabPanel value={value} index={0} style={{ padding: 0 }}>
              <div style={{ height: 650, width: "100%" }}>
                <DataGridPro
                  components={{ Toolbar: GridToolbar }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={users}
                  columns={columns}
                  getRowId={(row) => row.memberID}
                  pageSize={10}
                  disableSelectionOnClick
                  onRowClick={(params) => {
                    const dataUtente = users.filter(
                      (user) => user.memberID === params.row.memberID
                    );
                    navigate(`/membership/dettaglio-utente`, {
                      state: { utente: dataUtente[0], operazione: "Modifica" },
                    });
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div style={{ height: 650, width: "100%" }}>
                <DataGridPro
                  components={{ Toolbar: GridToolbar }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={groups}
                  columns={groupsColumns}
                  getRowId={(row) => row.groupID}
                  pageSize={10}
                  disableSelectionOnClick
                  onRowClick={(params) => {
                    const dataGruppo = groups.filter(
                      (group) => group.name === params.row.name
                    );
                    navigate(`/membership/dettaglio-gruppo`, {
                      state: { gruppo: dataGruppo[0], operazione: "Modifica" },
                    });
                  }}
                />
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default AdminPage;
