import React from "react";

const ReusableButton = (props) => {
  return (
    <>
      {props.size === "xxs" ? (
        <div
          className="d-flex justify-content-start"
          style={{ width: "fit-content" }}
        >
          <button
            id={props.id}
            className={`btn bg-green text-white fs-6 my-auto d-flex flex-nowrap gap-1 ${props.className}`}
            onClick={() => props.onClick()}
            style={props.style}
          >
            <i className={`bi bi-${props.icon} fs-6`}></i> {props.text}
          </button>
        </div>
      ) : (
        ""
      )}
      {props.size === "xs" ? (
        <div
          className="d-flex justify-content-start"
          style={{ width: "fit-content" }}
        >
          <button
            id={props.id}
            className={`btn bg-green text-white fs-5 my-auto d-flex flex-nowrap gap-1 ${props.className}`}
            onClick={() => props.onClick()}
            style={props.style}
          >
            <i className={`bi bi-${props.icon} fs-5`}></i> {props.text}
          </button>
        </div>
      ) : (
        ""
      )}
      {props.size === "md" ? (
        <div className="w-25 d-flex justify-content-start">
          <button
            id={props.id}
            className={`btn bg-green text-white fs-3  ${props.className}`}
            onClick={() => props.onClick()}
          >
            <i className={`bi bi-${props.icon} fs-3`}></i> {props.text}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ReusableButton;
