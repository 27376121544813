import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CContainer,
  CButton,
  CButtonGroup,
  CCard,
  CCardHeader,
} from "@coreui/react";
import Divider from "@mui/material/Divider";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";

const DettalioElencoManutenzioni = (props) => {
  const [elencoManutenzioni, setElencoManutenzioni] = useState({
    ID: "",
    Descrizione: "",
  });
  const [voce, setVoce] = useState([]);
  const [searchReady, setSearchReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleChange = (event) => {
    let manut = { ...elencoManutenzioni };
    manut[event.target.name] = event.target.value;
    setElencoManutenzioni(manut);
  };
  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la manutenzione?"
    );
    if (isExecuted) {
      await api.delete("/api/elencoManutenzioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: elencoManutenzioni.ID,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Manutenzione eliminata con successo",
      });
      setOpenSnack(true);
      //navigate(-1);
    }
  };
  const handleConfirmButton = async () => {
    let manut = { ...elencoManutenzioni };
    manut["utente"] = localStorage.getItem("nomeUtente");
    if (location.state.operazione === "Nuovo") {
      const response = await api.post(
        "api/elencoManutenzioni",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", elencoManutenzioni: manut },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      location.state.operazione = "Modifica";
      manut["ID"] = response.data.message.insertId; //ottengo l'id dell'elemento appena inserito
      setSnack({ severity: "success", messaggio: "Inserimento effettuato" });
      setOpenSnack(true);
    } else {
      await api.put(
        "api/elencoManutenzioni",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", elencoManutenzioni: manut },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
    }
    //navigate(-1);
  };
  const handleClick = (percorso) => {
    navigate(percorso);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (location.state.operazione === "Nuovo") {
        const elencoManut = { ...elencoManutenzioni };

        setElencoManutenzioni(elencoManut);
      } else {
        const elencoManut = location.state.elencoManutenzioni;
        setElencoManutenzioni(elencoManut);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento Manutenzioni</span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.state.search !== undefined) {
                  navigate("/home/elenco-manutenzioni", {
                    state: {
                      search: location.state.search,
                    },
                  });
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
            {/*  <span className="position-absolute top-50 end-0 translate-middle-y mr-3">
              {" "}
            </span>{" "}
            <a
              style={{ cursor: "pointer", color: "black" }}
              className="position-absolute top-50 end-0 translate-middle-y indigo-500"
              onClick={() => setOptions(!options)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="30"
                fill="currentColor"
                className="bi bi-three-dots-vertical pr-3"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </a>
            <ul
              className={`dropdown-menu position-absolute top-200 end-0 ${
                options ? "show" : ""
              }`}
            >
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    navigate(
                      `/home/macchinari/stampa/${elencoManutenzioni.ID}`,
                      {
                        state: {
                          noleggio: elencoManutenzioni,
                          stampa: "NoleggioDettaglio",
                        },
                      }
                    );
                  }}
                >
                  <i className="bi bi-printer"></i> STAMPA
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    handleDeleteButton();
                  }}
                >
                  <i className="bi bi-trash"></i> ELIMINA
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  //onClick={() => {}}
                >
                  <i className="bi bi-plus-circle"></i> AGGIUNGI
                </a>
              </li>
            </ul> */}
          </div>

          {/* BODY */}
          <div className="card-body">
            <div className="col-lg-10">
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  name="Descrizione"
                  value={elencoManutenzioni.Descrizione}
                  onChange={handleChange}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
                <CFormLabel>Descrizione</CFormLabel>
              </CFormFloating>
            </div>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap gap-2">
            <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap"></div>

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xs"
                className={`bg-red ${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                onClick={() => {
                  handleDeleteButton();
                }}
              />
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                onClick={() => handleConfirmButton()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DettalioElencoManutenzioni;
