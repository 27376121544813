import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "./api";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  CTableHeaderCell,
  CTableBody,
  CTable,
  CTableDataCell,
  CTableHead,
  CTableRow,
  CFormLabel,
  CFormInput,
  CFormSwitch,
  CFormFloating,
  CInputGroup,
  CInputGroupText,
  CFormCheck,
  CButtonGroup,
  CButton,
  CContainer,
  CCard,
  CCardHeader,
  CCollapse,
  CRow,
  CCol,
} from "@coreui/react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import QRCode from "qrcode";

import enLocale from "date-fns/locale/en-IE";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import moment from "moment";

const columnsAccise = [
  {
    field: "utente",
    headerName: "Utente",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matricola",
    headerName: "Matricola",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "m_giri",
    headerName: "M Giri",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "k_giri",
    headerName: "K Giri",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ore",
    headerName: "Ore",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "minuti",
    headerName: "Minuti",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
];
const columnsManutenzioni = [
  {
    field: "utente",
    headerName: "Utente",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matricola",
    headerName: "Matricola",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descrizione",
    headerName: "Descrizione",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matr_costr",
    headerName: "Matricola Costruttore",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "manutenz_tipo",
    headerName: "Tipo Manutenzione",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ore_km",
    headerName: "Ore/Km",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "manutenzione",
    headerName: "Manutenzione",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "note",
    headerName: "Note",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "meccanico",
    headerName: "Meccanico",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
];

const AtterraggioQrcode = () => {
  const [macchinario, setMacchinario] = useState({
    matricola: "",
    matricola_costr: "",
    tipologia: "",
    marca: "",
    modello: "",
    descrizione: "",
    chassis: "",
    targa: "",
    foto: "",
    stato_attuale: "",
    anno_acquisto: "",
    ore_km: "",
    costo: "",
    documenti_ce: "",
    documenti_cdc: "",
    documenti_cdp: "",
    documenti_ass: "",
    documenti_usoman: "",
    documenti_catric: "",
    documenti_schel: "",
    documenti_schidr: "",
    documenti_schpneu: "",
    cespite: "",
    costo_storico: "",
    valore_ammortamento: "",
    documenti_fattura: "",
    varie1: "",
    varie2: "",
    varie3: "",
    scadenza_circolazione: 0,
    scadenza_assicurazione: 0,
    scadenza_varie2: 0,
    scadenza_varie3: 0,
  });
  const [datiMacchina, setDatiMacchina] = useState({
    matricola: "",
    motore_marca: "",
    motore_modello: "",
    motore_SN: "",
    motore_potenza: "",
    motore_note: "",
    trasmissione_marca: "",
    trasmissione_modello: "",
    trasmissione_SN: "",
    trasmissione_note: "",
    macchina_base_lunghezza: "",
    macchina_base_larghezza: "",
    macchina_base_altezza: "",
    macchina_base_peso: "",
    equipaggiamento: "",
    accessori: "",
    note: "",
  });
  const [manutenzioneMacchina, setManutenzioneMacchina] = useState([
    {
      matricola: "",
      componente: "filtro_aria_esterno",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_aria_interno",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_gasolio",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_olio",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "filtro_idraulico",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "essiccatore",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_motore",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_cambio",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_idraulico",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_pistoni",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "olio_bielle",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
    {
      matricola: "",
      componente: "antigelo",
      marca_e_modello: "",
      quantita: "",
      frequenza: "",
    },
  ]);
  const [foto, setFoto] = useState([]);
  const [value, setValue] = useState(1);
  const [valueBottom, setValueBottom] = useState(1);
  const [accise, setAccise] = useState([]);
  const [manutenzione, setManutenzione] = useState([]);
  const [visibile, setVisibile] = useState({
    ce: false,
    cdc: false,
    cdp: false,
    ass: false,
    usoman: false,
    catric: false,
    schel: false,
    schidr: false,
    schpneu: false,
    tecnica: false,
    varie2: false,
    varie3: false,
    fattura: false,
  });

  const [qrcode, setQrcode] = useState("");
  const [circolazione, setCircolazione] = useState(false);
  const [assicurazione, setAssicurazione] = useState(false);
  const [funiCatene, setFuniCatene] = useState(false);
  const [verificaPeriodica, setVerificaPeriodica] = useState(false);
  const [ready, setReady] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const displayNone = () => {
    if (
      sessionStorage.getItem("SESSION") === "user" ||
      localStorage.getItem("LOCAL") === "user"
    ) {
      return "none";
    } else {
      return "block";
    }
  };
  const handleChange = (e) => {
    let copiaMacchinario = { ...macchinario };
    copiaMacchinario[e.target.name] = e.target.value;
    setMacchinario(copiaMacchinario);
  };
  const handleChangeDatiMacchinario = (e) => {
    let copiaDati = { ...datiMacchina };
    copiaDati[e.target.name] = e.target.value;
    setDatiMacchina(copiaDati);
  };
  const handleChangeManutenzioneMacchinari = (e, tipologia) => {
    let copiaManutenzione = [...manutenzioneMacchina];
    for (let i = 0; i < copiaManutenzione.length; i++) {
      if (copiaManutenzione[i].componente === tipologia) {
        copiaManutenzione[i][e.target.name] = e.target.value;
        i += copiaManutenzione.length;
      }
    }
    setManutenzioneMacchina(copiaManutenzione);
  };
  const handleChangeRadio = (value) => {
    let copiaMacchinario = { ...macchinario };
    copiaMacchinario["stato_attuale"] = value;
    setMacchinario(copiaMacchinario);
  };
  const handlleDelete = async () => {
    let confirm = window.confirm(
      "Sei sicuro di voler cancellare il macchinario?"
    );
    if (confirm) {
      await api.delete("/api/macchinari", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          matricola: macchinario.matricola,
        },
      });
      alert("Manutenzione eliminata con successo");
      navigate(-1);
    }
  };

  const rendOutputManutenzioni = (elemento) => {
    let b = "";

    switch (elemento.componente) {
      case "filtro_aria_esterno":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Filtro aria esterno</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_esterno")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_esterno")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_esterno")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_aria_interno":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Filtro aria interno</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_interno")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_interno")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_aria_interno")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_gasolio":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Filtro gasolio</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_gasolio")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_gasolio")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_gasolio")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_olio":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Filtro olio</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_olio")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_olio")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_olio")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "filtro_idraulico":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Filtro idraulico</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_idraulico")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_idraulico")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "filtro_idraulico")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "essiccatore":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Essiccatore aria</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "essiccatore")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "essiccatore")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "essiccatore")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_motore":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Olio motore [kg]</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_motore")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_motore")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_motore")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_cambio":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Olio cambio [kg]</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_cambio")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_cambio")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_cambio")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_idraulico":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Olio idraulico [kg]</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_idraulico")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_idraulico")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_idraulico")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_pistoni":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Olio pistoni [kg]</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_pistoni")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_pistoni")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_pistoni")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "olio_bielle":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Olio bielle [kg]</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_bielle")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_bielle")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "olio_bielle")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );

      case "antigelo":
        return (
          <CTableRow>
            <CTableHeaderCell scope="row">Antigelo [kg]</CTableHeaderCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="marca_e_modello"
                value={elemento.marca_e_modello}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "antigelo")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              {" "}
              <CFormInput
                type="text"
                name="quantita"
                value={elemento.quantita}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "antigelo")
                }
                disabled
              />{" "}
            </CTableDataCell>
            <CTableDataCell>
              <CFormInput
                type="text"
                name="frequenza"
                value={elemento.frequenza}
                onChange={(e) =>
                  handleChangeManutenzioneMacchinari(e, "antigelo")
                }
                disabled
              />
            </CTableDataCell>
          </CTableRow>
        );
    }
  };
  const modifyMacchinario = async () => {
    let copiaMacchinario = { ...macchinario };

    await api.put(
      "api/macchinari",
      {
        auth: "a5d42c3ed7977afa2d8e922569e7940b",
        macchinario: copiaMacchinario,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    alert("Modifica effettuata");

    navigate(-1);
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeValueBottom = (event, newValue) => {
    setValueBottom(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      let MatricolaUrl = location.pathname.split("/");
      let macchinaTest = "";
      await api
        .get("api/qrcode", {
          body: { auth: "a5d42c3ed7977afa2d8e922569e7940b" },
        })
        .then(async (res) => {
          let macchinari = res.data.data;
          const macchinario = macchinari.filter(
            (macchinario) =>
              macchinario.matricola === MatricolaUrl[MatricolaUrl.length - 1]
          );
          //passaggio dati Documenti Scadenze
          scadenze(macchinario[0]);
          //chiamata await funzione caricamento IMG
          const foto = await caricamentoFoto(macchinario[0]);
          setFoto(foto);
          setMacchinario(macchinario[0]);
          macchinaTest = macchinario[0];
          setReady(true);
          let url = `https://react.millergroup.it/home/macchinari/qrcode/${macchinario.matricola}`;
          QRCode.toDataURL(url).then((data) => {
            setQrcode(data);
          });
        })
        .catch((err) => {
          console.log(err);
        });

      api
        .get("api/accise", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let accisaMacchinario = res.data.data.filter(
            (accisa) => accisa.matricola === macchinario.matricola
          );
          setAccise(accisaMacchinario);
        });

      api
        .get("api/manutenzioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const manutenzione = res.data.data;
          const manutenzioneMacchinario = res.data.data.filter(
            (manutenzione) => manutenzione.matricola === macchinario.matricola
          );
          setManutenzione(manutenzioneMacchinario);
        });
      api
        .get("api/datiMacchina", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { matricola: MatricolaUrl[MatricolaUrl.length - 1] },
        })
        .then((res) => {
          if (res.data.data.length > 0) {
            setDatiMacchina(res.data.data[0]);
          }
          setReady(true);
        });

      api
        .get("api/manutMacchinari", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          params: { matricola: MatricolaUrl[MatricolaUrl.length - 1] },
        })
        .then((res) => {
          if (res.data.data.length > 0) {
            setManutenzioneMacchina(res.data.data);
          }
          setReady(true);
        });
    };
    caricamentoFoto(macchinario);
    fetchData();
  }, []);
  const caricamentoFoto = async (macchinario) => {
    if (
      macchinario.foto !== "" ||
      macchinario.foto !== null ||
      macchinario.foto !== undefined
    ) {
      let f = macchinario.foto.split("/");
      let file = f[1];

      await api.post("api/ftp", {
        file: file,
        percorso: macchinario.foto,
        operazione: "prendi",
      });
      return macchinario.foto
        ? "https://portale.fondamentasrl.it/file?file=" +
            macchinario.foto.split("/")[1]
        : "";
    }
  };
  const scadenze = (macchinario) => {
    macchinario.scadenza_circolazione !== null
      ? setCircolazione(true)
      : setCircolazione(false);
    macchinario.scadenza_assicurazione !== null
      ? setAssicurazione(true)
      : setAssicurazione(false);
    macchinario.scadenza_varie2 !== null
      ? setFuniCatene(true)
      : setFuniCatene(false);
    macchinario.scadenza_varie3 !== null
      ? setVerificaPeriodica(true)
      : setVerificaPeriodica(false);
  };
  const handleClick = (percorso) => {
    navigate(percorso);
  };
  const manageFiles = async (cFile) => {
    let f = cFile.split("/");
    let file = f[1];

    await api
      .post(
        "api/ftp",
        { file: file, percorso: cFile, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://react.millergroup.it/file?file=" + file);
      });
  };
  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <h2 className="d-flex align-items-center col mt-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-gear mx-2"
              viewBox="0 0 16 16"
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            >
              <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
              <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
            </svg>
            <span style={{ cursor: "pointer" }}>MACCHINARI</span>
            <CButton
              className="btn btn-green mx-2"
              onClick={() =>
                navigate("/home/macchinari/:id", {
                  state: { macchinario: [], operazione: "Nuovo" },
                })
              }
              style={{ display: displayNone() }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
              <span className="mx-2">Aggiungi</span>
            </CButton>
          </h2>
        </div>
        <CCard /* ref={(el) => (componentRef = el)} */>
          <CCardHeader>
            <h6>
              {macchinario.matricola} - {macchinario.descrizione}
            </h6>
          </CCardHeader>
          <Divider variant="middle" className="mt-5 mx-4" />
          <div className="row d-flex p-4">
            <div className="col-lg-10">
              <div className="row">
                <h3>MATRICOLA</h3>
              </div>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="MatricolaInput"
                  name="matricola"
                  defaultValue={macchinario.matricola}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Matricola</CFormLabel>
              </CFormFloating>
              <div className="row">
                <h3>DATI</h3>
              </div>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="MatricolaInput"
                  name="tipologia"
                  defaultValue={macchinario.tipologia}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Tipologia</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="MarcaInput"
                  name="marca"
                  defaultValue={macchinario.marca}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Marca</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="ModelloInput"
                  name="modello"
                  defaultValue={macchinario.modello}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Modello</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  name="descrizione"
                  defaultValue={macchinario.descrizione}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Descrizione</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="ChassisInput"
                  name="chassis"
                  defaultValue={macchinario.chassis}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Chassis</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="TargaInput"
                  name="targa"
                  defaultValue={macchinario.targa}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Targa</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="AnnoAcquistoInput"
                  name="anno_acquisto"
                  defaultValue={macchinario.anno_acquisto}
                  onChange={handleChange}
                  disabled
                />
                <CFormLabel>Anno acquisto</CFormLabel>
              </CFormFloating>
              <div className="col-lg-5">
                <div className="mb-3 row">
                  <h3 className="col-3  col-lg-4">Foto</h3>
                  <div id="foto" className="col-9 col-lg-8">
                    <img src={foto} className="img-thumbnail" alt="..." />
                  </div>
                </div>
                <div className="mb-3 row">
                  <p className="col-3 col-lg-4 col-form-label">Qr code</p>
                  <div className="col-9 col-lg-8" id="qrcode">
                    <img src={qrcode} className="img-thumbnail" alt="..." />
                  </div>
                </div>
                <div className="my-4 row">
                  <label className="col-3 col-lg-4 col-form-label">
                    Stato attuale
                  </label>
                  <div className="col-9 col-lg-8 mt-2">
                    <CFormCheck
                      type="radio"
                      name="stato_attuale"
                      id="Operativo"
                      label="Operativo"
                      checked={
                        macchinario.stato_attuale === "Operativo" ? true : ""
                      }
                      value="Operativo"
                      onChange={(e) => handleChangeRadio("Operativo")}
                      disabled
                    />
                    <CFormCheck
                      type="radio"
                      name="stato_attuale"
                      id="Demolito"
                      label="Demolito"
                      checked={
                        macchinario.stato_attuale === "Demolito" ? true : ""
                      }
                      value="Demolito"
                      onChange={(e) => handleChangeRadio("Demolito")}
                      disabled
                    />
                    <CFormCheck
                      type="radio"
                      name="stato_attuale"
                      id="Venduto"
                      label="Venduto"
                      checked={
                        macchinario.stato_attuale === "Venduto" ? true : ""
                      }
                      value="Venduto"
                      onChange={(e) => handleChangeRadio("Venduto")}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    variant="scrollable"
                    onChange={handleChangeValue}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Documenti" value={1} />
                    <Tab label="Amministrazione" value={2} />
                    <Tab label="Dati" value={3} />
                    <Tab label="Manutenzione" value={4} />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Certificato CE
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.documenti_ce)}
                        style={{
                          display: macchinario.documenti_ce ? "block" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        type="button"
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              ce: !visibile.ce,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.ce}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          className="mt-2"
                          id="Certificato"
                          name="documenti_ce"
                          defaultValue={macchinario.documenti_ce}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Carta di Circolazione
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.documenti_cdc)}
                        style={{
                          display: macchinario.documenti_cdc ? "block" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              cdc: !visibile.cdc,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    {/* collapse documento */}
                    <CCollapse visible={visibile.cdc}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="CartaDiCircolazione"
                          className="mt-2"
                          name="documenti_cdp"
                          defaultValue={macchinario.documenti_cdc}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormFloating className="col-6 d-flex justify-content-sm-start  align-items-center">
                        <CFormSwitch
                          label="Scadenza"
                          checked={circolazione}
                          id="formSwitch"
                          style={{
                            backgroundColor:
                              moment(new Date()).local().format("YYYY-MM-DD") >=
                              moment(macchinario.scadenza_circolazione).format(
                                "DD/MM/YYYY"
                              )
                                ? "red"
                                : "",
                            borderColor: "grey",
                          }}
                          onChange={(e) => {
                            e.target.checked
                              ? setCircolazione(true)
                              : setCircolazione(false);
                          }}
                        />
                      </CFormFloating>
                      {/* collapse Scadenza */}
                      <CCollapse className="col-6" visible={circolazione}>
                        <CCol sm={12}>
                          <CFormFloating className="mt-2">
                            <div className="">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enLocale}
                              >
                                <DatePicker
                                  label="Scadenza"
                                  value={macchinario.scadenza_circolazione}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  onChange={(newValue) => {
                                    let can = { ...macchinario };
                                    can["scadenza_circolazione"] = moment(
                                      newValue
                                    )
                                      .local()
                                      .format("YYYY-MM-DD");
                                    setMacchinario(can);
                                    setCircolazione(true);
                                  }}
                                  disabled
                                />
                              </LocalizationProvider>
                            </div>
                          </CFormFloating>
                        </CCol>
                      </CCollapse>
                    </div>
                  </CRow>
                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Certificato di Proprietà
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.documenti_cdp)}
                        style={{
                          display: macchinario.documenti_cdp ? "block" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              cdp: !visibile.cdp,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.cdp}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="CertificatoDiProprieta"
                          className="mt-2"
                          name="documenti_cdp"
                          defaultValue={macchinario.documenti_cdp}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Assicurazione
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.documenti_ass)}
                        style={{
                          display: macchinario.documenti_ass ? "block" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              ass: !visibile.ass,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.ass}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="CertificatoDiProprieta"
                          className="mt-2"
                          name="documenti_ass"
                          defaultValue={macchinario.documenti_ass}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormFloating className="col-6">
                        <CFormSwitch
                          label="Scadenza"
                          checked={assicurazione}
                          id="formSwitchCheckDefault"
                          style={{
                            backgroundColor:
                              moment(new Date()).local().format("YYYY-MM-DD") >=
                              moment(macchinario.scadenza_assicurazione).format(
                                "DD/MM/YYYY"
                              )
                                ? "red"
                                : "",
                            borderColor: "grey",
                          }}
                          onChange={(e) => {
                            e.target.checked
                              ? setAssicurazione(true)
                              : setAssicurazione(false);
                          }}
                        />
                      </CFormFloating>
                      {/* collapse Scadenza */}
                      <CCollapse className="col-6" visible={assicurazione}>
                        <CCol sm={12}>
                          <CFormFloating className="mt-2">
                            <div className="">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enLocale}
                              >
                                <DatePicker
                                  label="Scadenza"
                                  value={macchinario.scadenza_assicurazione}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  onChange={(newValue) => {
                                    let can = { ...macchinario };
                                    can["scadenza_assicurazione"] = moment(
                                      newValue
                                    )
                                      .local()
                                      .format("YYYY-MM-DD");
                                    setMacchinario(can);
                                    setAssicurazione(true);
                                  }}
                                  disabled
                                />
                              </LocalizationProvider>
                            </div>
                          </CFormFloating>
                        </CCol>
                      </CCollapse>
                    </div>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Manuale Uso e Manutenzione
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() =>
                          manageFiles(macchinario.documenti_usoman)
                        }
                        style={{
                          display: macchinario.documenti_usoman
                            ? "block"
                            : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              usoman: !visibile.usoman,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.usoman}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="ManualeUsoeManutenzione"
                          className="mt-2"
                          name="documenti_usoman"
                          defaultValue={macchinario.documenti_usoman}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Catalogo Ricambi
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() =>
                          manageFiles(macchinario.documenti_catric)
                        }
                        style={{
                          display: macchinario.documenti_catric
                            ? "block"
                            : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              catric: !visibile.catric,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.catric}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="ManualeUsoeManutenzione"
                          className="mt-2"
                          name="documenti_catric"
                          defaultValue={macchinario.documenti_catric}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Schema Impianto Elettrico
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.documenti_schel)}
                        style={{
                          display: macchinario.documenti_schel
                            ? "block"
                            : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              schel: !visibile.schel,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.schel}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="ManualeUsoeManutenzione"
                          className="mt-2"
                          name="documenti_schel"
                          defaultValue={macchinario.documenti_schel}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Schema Impianto Idraulico
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() =>
                          manageFiles(macchinario.documenti_schidr)
                        }
                        style={{
                          display: macchinario.documenti_schidr
                            ? "block"
                            : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              schidr: !visibile.schidr,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.schidr}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="ManualeUsoeManutenzione"
                          name="documenti_schidr"
                          className="mt-2"
                          defaultValue={macchinario.documenti_schidr}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Schema Impianto Pneumatico
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() =>
                          manageFiles(macchinario.documenti_schpneu)
                        }
                        style={{
                          display: macchinario.documenti_schpneu
                            ? "block"
                            : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              schpneu: !visibile.schpneu,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.schpneu}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="ManualeUsoeManutenzione"
                          className="mt-2"
                          name="documenti_schpneu"
                          value={macchinario.documenti_schpneu}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Scheda Tecnica
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.varie1)}
                        style={{
                          display: macchinario.varie1 ? "block" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              tecnica: !visibile.tecnica,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.tecnica}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="ManualeUsoeManutenzione"
                          className="mt-2"
                          name="varie1"
                          defaultValue={macchinario.varie1}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Verifica funi e catene
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.varie2)}
                        style={{
                          display: macchinario.varie2 ? "block" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              varie2: !visibile.varie2,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.varie2}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          id="ManualeUsoeManutenzione"
                          className="mt-2"
                          name="varie2"
                          defaultValue={macchinario.varie2}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormFloating className="col-6">
                        <CFormSwitch
                          label="Scadenza"
                          checked={funiCatene}
                          id="formSwitchCheckDefault"
                          style={{
                            backgroundColor:
                              moment(new Date()).local().format("YYYY-MM-DD") >=
                              moment(macchinario.scadenza_varie2).format(
                                "DD/MM/YYYY"
                              )
                                ? "red"
                                : "",
                            borderColor: "grey",
                          }}
                          onChange={(e) => {
                            e.target.checked
                              ? setFuniCatene(true)
                              : setFuniCatene(false);
                          }}
                        />
                      </CFormFloating>
                      {/* collapse Scadenza */}
                      <CCollapse className="col-6" visible={funiCatene}>
                        <CCol sm={12}>
                          <CFormFloating className="mt-2">
                            <div className="">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enLocale}
                              >
                                <DatePicker
                                  label="Scadenza"
                                  value={macchinario.scadenza_varie2}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  onChange={(newValue) => {
                                    let can = macchinario;
                                    can["scadenza_varie2"] = moment(newValue)
                                      .local()
                                      .format("YYYY-MM-DD");
                                    setMacchinario(can);
                                    setFuniCatene(true);
                                  }}
                                  disabled
                                />
                              </LocalizationProvider>
                            </div>
                          </CFormFloating>
                        </CCol>
                      </CCollapse>
                    </div>
                  </CRow>

                  <CRow className="mb-3 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-6 col-form-label">
                        Verifica periodica
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        onClick={() => manageFiles(macchinario.varie3)}
                        style={{
                          display: macchinario.varie3 ? "block" : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        size="sm"
                        className="col-3 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              varie3: !visibile.varie3,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.varie3}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          className="mt-2"
                          name="varie3"
                          defaultValue={macchinario.varie3}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                    <div className="d-flex col-md-6 col-12 justify-content-sm-start align-items-center">
                      <CFormFloating className="col-6">
                        <CFormSwitch
                          label="Scadenza"
                          checked={verificaPeriodica}
                          id="formSwitchCheckDefault"
                          style={{
                            backgroundColor:
                              moment(new Date()).local().format("YYYY-MM-DD") >=
                              moment(macchinario.scadenza_varie3).format(
                                "DD/MM/YYYY"
                              )
                                ? "red"
                                : "",
                            borderColor: "grey",
                          }}
                          onChange={(e) => {
                            e.target.checked
                              ? setVerificaPeriodica(true)
                              : setVerificaPeriodica(false);
                          }}
                        />
                      </CFormFloating>
                      {/* collapse Scadenza */}
                      <CCollapse className="col-6" visible={verificaPeriodica}>
                        <CCol sm={12}>
                          <CFormFloating className="mt-2">
                            <div className="">
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                locale={enLocale}
                              >
                                <DatePicker
                                  label="Scadenza"
                                  value={macchinario.scadenza_varie3}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                  onChange={(newValue) => {
                                    let can = macchinario;
                                    can["scadenza_varie3"] = moment(newValue)
                                      .local()
                                      .format("YYYY-MM-DD");
                                    setMacchinario(can);
                                    setVerificaPeriodica(true);
                                  }}
                                  disabled
                                />
                              </LocalizationProvider>
                            </div>
                          </CFormFloating>
                        </CCol>
                      </CCollapse>
                    </div>
                  </CRow>
                </TabPanel>
                <TabPanel value="2">
                  <CFormFloating className="row mb-3">
                    <CInputGroup className="mb-3 col-12">
                      <CFormLabel className="mx-1 col-4">Cespite</CFormLabel>
                      <CFormInput
                        className="rounded col-8"
                        name="cespite"
                        defaultValue={macchinario.cespite}
                        onChange={handleChange}
                        disabled
                      />
                    </CInputGroup>
                  </CFormFloating>
                  <CFormFloating className="row mb-3">
                    <CInputGroup className="mb-3 col-12">
                      <CFormLabel className="mx-1 col-4">
                        Costo Storico
                      </CFormLabel>
                      <CFormInput
                        className="rounded col-8"
                        name="costo_storico"
                        defaultValue={macchinario.costo_storico}
                        onChange={handleChange}
                        disabled
                      />
                      <CInputGroupText>€</CInputGroupText>
                    </CInputGroup>
                  </CFormFloating>
                  <CFormFloating className="row mb-3">
                    <CInputGroup className="mb-3 col-12">
                      <CFormLabel className="mx-1 col-4">
                        Costo acquisto
                      </CFormLabel>
                      <CFormInput
                        className="rounded col-8"
                        name="costo"
                        defaultValue={macchinario.costo}
                        onChange={handleChange}
                        disabled
                      />
                    </CInputGroup>
                  </CFormFloating>
                  <CFormFloating className="row mb-3">
                    <CInputGroup className="mb-3 col-12">
                      <CFormLabel className="mx-1 col-4">
                        Valore Ammortamento
                      </CFormLabel>
                      <CFormInput
                        className="rounded col-8"
                        name="valore_ammortamento"
                        defaultValue={macchinario.valore_ammortamento}
                        onChange={handleChange}
                        disabled
                      />
                      <CInputGroupText>€</CInputGroupText>
                    </CInputGroup>
                  </CFormFloating>

                  <CRow className="mb-3 gx-0 d-flex justify-content-sm-start justify-content-around align-items-center">
                    <div className="d-flex col-12 gx-0 justify-content-sm-start align-items-center">
                      <CFormLabel className="col-4 col-form-label">
                        Fattura di Acquisto
                      </CFormLabel>
                      <CButton
                        size="sm"
                        className="col-4 btn btn-secondary rounded"
                        onClick={() =>
                          manageFiles(macchinario.documenti_fattura)
                        }
                        style={{
                          display: macchinario.documenti_fattura
                            ? "block"
                            : "none",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </CButton>
                      <CButton
                        type="button"
                        size="sm"
                        className="col-4 btn btn-secondary rounded"
                        style={{ display: displayNone() }}
                        onClick={() =>
                          setVisibile({
                            visibile: {
                              ...visibile,
                              fattura: !visibile.fattura,
                            },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pen"
                          viewBox="0 0 16 16"
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </CButton>
                    </div>
                    <CCollapse visible={visibile.fattura}>
                      <CCol sm={12}>
                        <CFormInput
                          type="text"
                          className="mt-2"
                          id="Certificato"
                          name="documenti_fattura"
                          defaultValue={macchinario.documenti_fattura}
                          onChange={handleChange}
                          disabled
                        />
                      </CCol>
                    </CCollapse>
                  </CRow>
                </TabPanel>
                <TabPanel value="3">
                  <h3>Dati motore</h3>

                  <div className="row d-flex ">
                    <div className="col-lg-4">
                      <CFormFloating
                        className="col-4"
                        style={{ width: "100%" }}
                      >
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="motore_marca"
                          value={datiMacchina.motore_marca}
                          disabled
                        />
                        <CFormLabel>Marca</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-4">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="motore_modello"
                          value={datiMacchina.motore_modello}
                          disabled
                        />
                        <CFormLabel>Modello</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-4">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="motore_SN"
                          value={datiMacchina.motore_SN}
                          disabled
                        />
                        <CFormLabel>S/N</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-4">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="motore_potenza"
                          value={datiMacchina.motore_potenza}
                          disabled
                        />
                        <CFormLabel>Ptenza</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-4">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="motore_note"
                          value={datiMacchina.motore_note}
                          disabled
                        />
                        <CFormLabel>Note</CFormLabel>
                      </CFormFloating>
                    </div>
                  </div>
                  <h3>Dati trasmissione</h3>

                  <div className="row d-flex ">
                    <div className="col-lg-6">
                      <CFormFloating
                        className="col-4"
                        style={{ width: "100%" }}
                      >
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="trasmissione_marca"
                          value={datiMacchina.trasmissione_marca}
                          disabled
                        />
                        <CFormLabel>Marca</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-6">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="trasmissione_modello"
                          value={datiMacchina.trasmissione_modello}
                          disabled
                        />
                        <CFormLabel>Modello</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-6">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="trasmissione_SN"
                          value={datiMacchina.trasmissione_SN}
                          disabled
                        />
                        <CFormLabel>S/N</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-6">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="trasmissione_note"
                          value={datiMacchina.trasmissione_note}
                          disabled
                        />
                        <CFormLabel>Note</CFormLabel>
                      </CFormFloating>
                    </div>
                  </div>
                  <h3>Dimensione macchina base</h3>

                  <div className="row d-flex ">
                    <div className="col-lg-6">
                      <CFormFloating
                        className="col-4"
                        style={{ width: "100%" }}
                      >
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="macchina_base_lunghezza"
                          value={datiMacchina.macchina_base_lunghezza}
                          disabled
                        />
                        <CFormLabel>Lunghezza</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-6">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="macchina_base_larghezza"
                          value={datiMacchina.macchina_base_larghezza}
                          disabled
                        />
                        <CFormLabel>Larghezza</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-6">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="macchina_base_altezza"
                          value={datiMacchina.macchina_base_altezza}
                          disabled
                        />
                        <CFormLabel>Altezza</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-6">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="macchina_base_peso"
                          value={datiMacchina.macchina_base_peso}
                          disabled
                        />
                        <CFormLabel>Peso</CFormLabel>
                      </CFormFloating>
                    </div>
                  </div>
                  <div className="row d-flex mt-3">
                    <div className="col-lg-4">
                      <CFormFloating
                        className="col-4"
                        style={{ width: "100%" }}
                      >
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="equipaggiamento"
                          value={datiMacchina.equipaggiamento}
                          disabled
                        />
                        <CFormLabel>Equipaggiamenti</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-4">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="accessori"
                          value={datiMacchina.accessori}
                          disabled
                        />
                        <CFormLabel>Accessori</CFormLabel>
                      </CFormFloating>
                    </div>
                    <div className="col-lg-4">
                      <CFormFloating className="mb-3">
                        <CFormInput
                          type="text"
                          id="DescrizioneInput"
                          onChange={handleChangeDatiMacchinario}
                          name="note"
                          value={datiMacchina.note}
                          disabled
                        />
                        <CFormLabel>Note</CFormLabel>
                      </CFormFloating>
                    </div>
                  </div>
                  <div className="row d-flex">
                    <div className="col-lg-12"></div>
                  </div>
                </TabPanel>
                <TabPanel value="4">
                  <CTable>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">
                          Componente
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col">
                          Marca e modello
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col">
                          Quantità
                        </CTableHeaderCell>
                        <CTableHeaderCell scope="col">
                          Frequenza manut./sostituz.
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {manutenzioneMacchina.map((manut) => {
                        return <>{rendOutputManutenzioni(manut)}</>;
                      })}
                    </CTableBody>
                  </CTable>
                  <CButton
                    className="btn btn-green mb-4"
                    style={{
                      width: "inherit",
                      display: macchinario.matricola !== "" ? "" : "None",
                    }}
                  >
                    {" "}
                    Salva Manutenzioni
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-lg"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                  </CButton>
                </TabPanel>
              </TabContext>
            </div>
            <div className="col-lg-2 col-12">
              {/* <CContainer fluid>
                                            
                                            <CButton className="btn btn-green mb-4" onClick={()=> this.modifyMacchinario()} style={{width: 'inherit', display: displayNone()}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                                                </svg>
                                            </CButton>
                                            <br />
                                            <CButtonGroup vertical role="group" aria-label="Vertical button group" style={{width: 'inherit'}}>
                                            <CButton type="button" className="btn btn-secondary" onClick={()=> {navigate(-1)}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                                    <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                                                </svg>
                                            </CButton>
                                            <CButton className="btn btn-secondary rounded-bottom" style={{width: 'inherit'}} onClick={()=> {
                                                navigate(`/home/macchinari/stampa/${macchinario.matricola}`, {state: {macchinario: macchinario, stampa: 'MacchinarioDettaglio'}})}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                                    <path  d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                                                </svg>
                                            </CButton>
                                            <CButton type="button" className="btn btn-danger" style={{display: displayNone()}} onClick={()=> this.handlleDelete()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                </svg>
                                            </CButton>
                                            </CButtonGroup>
                                            <br />
                                            <CButton  className="btn btn-secondary mt-4" style={{width: 'inherit', display: displayNone()}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                            </CButton>
                                            <br />
                                            <h5>
                                                Opt
                                            </h5>
                                            <CButtonGroup vertical role="group" aria-label="Vertical button group" style={{width: 'inherit'}}>
                                            <CButton type="button" className="btn btn-secondary">
                                                QR
                                            </CButton>
                                            <CButton type="button" className="btn btn-secondary">
                                                FTP
                                            </CButton>
                                            </CButtonGroup>
                                        </CContainer> */}
            </div>
          </div>
        </CCard>
        <div className="container p-4">
          <div className="row d-flex justify-content-start"></div>
          <TabContext value={valueBottom}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeValueBottom}
                aria-label="lab API tabs example"
              >
                <Tab label="Accise" value={1} />
                <Tab
                  label="Manutenzioni"
                  value="2"
                  style={{ display: displayNone() }}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              {/* <CButton size="sm"  className="btn btn-green col-3 mx-3 mb-2" onClick={()=> navigate('/home/accise/:id', {state: {manutenzioni: [], operazione: 'Nuovo'}})}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>
                                            <span className="mx-2">
                                            Aggiungi
                                            </span>
                                        </CButton>
                                        <CButton size="sm" className="btn btn-green col-1 mx-3 mb-2" onClick={() => {window.location.reload();}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                            </svg>
                                        </CButton> */}
              <div style={{ height: 250, width: "100%" }} id="accisaCorrelata">
                <DataGridPro
                  rows={accise}
                  columns={columnsAccise}
                  pageSize={2}
                  checkboxSelection
                  disableSelectionOnClick
                  onRowClick={(paramsAccise) => {
                    const dataAccise = accise.filter(
                      (accisa) =>
                        accisa.matricola === paramsAccise.row.matricola
                    );
                    navigate(`/home/accise/${dataAccise[0].matricola}`, {
                      state: { accise: dataAccise[0] },
                    });
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel value="2">
              {/* <CButton size="sm"  className="btn btn-green col-3 mx-3 mb-2" onClick={()=> navigate('/home/manutenzioni/:id', {state: {manutenzioni: [], operazione: 'Nuovo'}})}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                            </svg>
                                            <span className="mx-2">
                                            Aggiungi
                                            </span>
                                        </CButton>
                                        <CButton size="sm" className="btn btn-green col-1 mx-3 mb-2" onClick={() => {window.location.reload();}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                            </svg>
                                        </CButton> */}
              <div style={{ height: 250, width: "100%" }}>
                <DataGridPro
                  rows={manutenzione}
                  columns={columnsManutenzioni}
                  pageSize={5}
                  checkboxSelection
                  disableSelectionOnClick
                  onRowClick={(paramsManutenzioni) => {
                    const dataManutenzione = manutenzione.filter(
                      (manutenzione) =>
                        manutenzione.matricola ===
                        paramsManutenzioni.row.matricola
                    );
                    navigate(
                      `/home/manutenzioni/${dataManutenzione[0].matricola}`,
                      { state: { manutenzione: dataManutenzione[0] } }
                    );
                  }}
                />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </div>
  );
};

/*         function NavigationPages(props){
            const location = useLocation();
            const navigate = useNavigate()
            return <AtterraggioQrcode {...props} location={location} navigate={navigate}></AtterraggioQrcode>
        } */

export default AtterraggioQrcode;
