import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CButton, CSpinner } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import api from "./api";
import ReusableButton from "./Reusable/Buttons";
const columns = [
  {
    field: "id",
    headerName: "Progressivo",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "utente",
    headerName: "Utente",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "desWbs",
    headerName: "Wbs",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "desTipo",
    headerName: "Tipo",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "quantita",
    headerName: "Quantità",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "importo",
    headerName: "Importo",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "totale",
    headerName: "Totale",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
];

const Costi = (props) => {
  const [costi, setCosti] = useState([]);
  const [costiVisibili, setCostiVisibili] = useState([]);
  const [costiCaricate, setCostiCaricate] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    var costiX = [];
    var i;
    for (i = 0; i < costi.length; i++) {
      if (
        costi[i].utente?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        costi[i].commessa?.toString().includes(e.target.value.toLowerCase()) ||
        costi[i].costo?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        costiX.push(costi[i]);
      }
    }
    setCostiVisibili(costiX);
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/costi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let costi = res.data.data;
          setCosti(costi);
          setCostiVisibili(costi);
          setCostiCaricate(true);
        });
    };
    fetchData();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">COSTI</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center  col">
            <div className="d-flex col-6 ">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className="mx-3"
              onClick={() =>
                navigate(`/home/costi/dettaglio-costi`, {
                  state: { costi: [], operazione: "Nuovo" },
                })
              }
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={costiVisibili}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(params) => {
              const dataCosti = costi.filter(
                (costi) => costi.id === params.row.id
              );
              navigate(`/home/costi/dettaglio-costi`, {
                state: { costi: dataCosti[0], operazione: "Modifica" },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Costi;
