import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CFormTextarea,
  CInputGroupText,
  CInputGroup,
  CFormSelect,
} from "@coreui/react";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";
import enLocale from "date-fns/locale/en-IE";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import GlobalSnackbar from "./Snackbar";
import moment from "moment";
import ReusableButton from "./Reusable/Buttons";

const columns = [
  {
    hide: true,
    field: "id",
    headerName: "Id",
    width: 50,
  },
  {
    field: "wbs_descrizione",
    headerName: "Wbs",
    flex: 1,
  },
  {
    field: "lavorazione_descrizione",
    headerName: "Lavorazione",
    flex: 1,
  },
  {
    field: "operatore_descrizione",
    headerName: "Operatore",
    flex: 1,
  },
  {
    field: "attrezzatura",
    headerName: "Attrezzatura",
    flex: 1,
  },
  {
    field: "quantita",
    headerName: "Quantita",
    flex: 1,
  },
  {
    field: "prezzo",
    headerName: "Prezzo",
    flex: 1,
  },
  {
    field: "produzione",
    headerName: "Produzione",
    flex: 1,
  },

  {
    field: "note",
    headerName: "Note",
    flex: 1,
  },
];

const DettaglioProduzione = () => {
  const [produzionePadre, setProduzionePadre] = useState({
    utente: "",
    data: "",
    cantiere: "",
  });
  const [produzione, setProduzione] = useState({});
  const [produzioneSel, setProduzioneSel] = useState([]);
  const [operatore, setOperatore] = useState([]);
  const [eliminaProd, setEliminaProd] = useState(0);
  const [lavorazioni, setLavorazioni] = useState({});
  const [attrezzature, setAttrezzature] = useState([]);
  const [noleggi, setNoleggi] = useState([]);
  const [cantieri, setCantieri] = useState([]);
  const [macchinari, setMacchinari] = useState([]);
  const [searchReady, setSearchReady] = useState(false);
  const [options, setOptions] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const permission = JSON.parse(localStorage.getItem("permission"));
  const [operazione, setOperazione] = useState(location.state.operazione);
  const nomeUtente = localStorage.getItem("nomeUtente");
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleDeleteButton = async () => {
    //prendo produzioni figlie associate
    const resProd = await api.get("api/produzione", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const prodSel = resProd.data.data.filter((el) => {
      if (el.id_intestazione === produzionePadre.id) {
        //estraggo gli id di tutte le produzioni associate alla produzione_padre
        return el.id;
      }
    });
    const idProduzioni = prodSel.map((el) => el.id);

    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la Produzione?"
    );
    if (isExecuted) {
      await api.delete("/api/produzione_padre", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: produzionePadre.id,
        },
      });
      if (idProduzioni.length > 0) {
        await api.delete("/api/produzione", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
          data: {
            id: idProduzioni,
          },
        });
      }
      setSnack({
        severity: "success",
        messaggio: "Produzione eliminata con successo",
      });
      setOpenSnack(true);
      navigate(-1);
    }
  };
  const handleConfirmButton = async () => {
    let prod = { ...produzionePadre };
    if (operazione === "Nuovo") {
      const response = await api.post(
        "api/produzione_padre",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          produzione_padre: produzionePadre,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );

      setSnack({ severity: "success", messaggio: "Inserimento effettuato" });
      setOpenSnack(true);

      let newProd = { ...produzionePadre };
      newProd["id"] = response.data.message.insertId; //mi salvo l'id per poter filtrare le produzioni associate

      setProduzionePadre(newProd);

      const resLav = await api.get("api/cantieri_lavorazioni", {
        //prendo i dati da inserire nella select
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setLavorazioni(resLav.data.data);

      const resOpe = await api.get("api/operatore", {
        //prendo i dati da inserire nella select
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const operatori = resOpe.data.data;
      const ope = operatori.map((op) => {
        return { id: op.id, descrizione: op.descrizione };
      });

      setOperatore(ope);

      const resMacch = await api.get("api/macchinari", {
        //prendo i dati da inserire nella select
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const macc = resMacch.data.data;
      setMacchinari(macc);
      const attrezzature = resMacch.data.data.map((attr) => {
        let att = { matricola: "", descrizione: "" };
        return (att = {
          matricola: attr.matricola,
          descrizione: attr.descrizione,
        });
      });
      setAttrezzature(attrezzature);
      setSearchReady(true);
      setOperazione("Modifica");
    } else {
      prod["data"] = moment(produzionePadre.data).format("YYYY-MM-DD");
      try {
        const response = await api.put(
          "api/produzione_padre",
          {
            auth: "a5d42c3ed7977afa2d8e922569e7940b",
            produzione_padre: prod,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        setSnack({
          severity: "success",
          messaggio: "Aggiornamento effettuato",
        });
        setOpenSnack(true);
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore durante l'aggionamento",
        });
        setOpenSnack(true);
      }
    }
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  const fetchProduzioni = async (operazione, id) => {
    //aggiorno i dati all'interno della tabella dettaglio-produzioni
    if (operazione === "Nuovo") {
      const resProd = await api.get("api/produzione", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const prodSel = resProd.data.data.filter(
        (el) => el.id_intestazione === id
      );
      setProduzione(prodSel);
    } else {
      const resProd = await api.get("api/produzione", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      if (id !== undefined) {
        const prodSel = resProd.data.data.filter(
          (el) => el.id_intestazione === id
        );
        return setProduzione(prodSel);
      } else {
        const prodSel = resProd.data.data.filter(
          (el) => el.id_intestazione === location.state.produzione.id
        );
        setProduzione(prodSel);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setOperazione(location.state.operazione);
      if (location.state.operazione === "Nuovo") {
        const prod = { ...produzionePadre };
        prod["utente"] = localStorage.getItem("nomeUtente");
        prod["data"] = moment(new Date());
        setProduzionePadre(prod);
        const resCant = await api.get("api/cantieri", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const cantieri = resCant.data.data.map((cantiere) => cantiere.commessa);
        setCantieri(cantieri);
        setSearchReady(true);
        const resNoleggi = await api.get("api/noleggi", {
          //prendo i dati da inserire nella select
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const nol = resNoleggi.data.data.map((noleggio) => {
          return {
            id: noleggio.id,
            matricola: noleggio.matricola,
            descrizione: noleggio.descrizione,
          };
        });
        setNoleggi(nol);
      } else {
        setProduzionePadre(location.state.produzione);

        const resProd = await api.get("api/produzione", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const prodSel = resProd.data.data.filter(
          (el) => el.id_intestazione === location.state.produzione.id
        );
        setProduzione(prodSel);

        const resLav = await api.get("api/cantieri_lavorazioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        setLavorazioni(resLav.data.data);

        const resOpe = await api.get("api/operatore", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const operatori = resOpe.data.data;
        const ope = resOpe.data.data.map((op) => {
          return { id: op.id, descrizione: op.descrizione };
        });
        setOperatore(ope);

        const resNoleggi = await api.get("api/noleggi", {
          //prendo i dati da inserire nella select
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const nol = resNoleggi.data.data.map((noleggio) => {
          return {
            id: noleggio.id,
            matricola: noleggio.matricola,
            descrizione: noleggio.descrizione,
          };
        });
        setNoleggi(nol);

        const resMacch = await api.get("api/macchinari", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        const macc = resMacch.data.data;
        setMacchinari(macc);
        const attrezzature = resMacch.data.data.map((attr) => {
          let att = { matricola: "", descrizione: "" };
          return (att = {
            matricola: attr.matricola,
            descrizione: attr.descrizione,
          });
        });
        setAttrezzature(attrezzature);
        setSearchReady(true);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />

      <div className="mt-5 pt-5 p-md-5 p-0 ">
        <div className="card text-center border-classic border-0">
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento Produzione</span>
            <span className="position-absolute top-50 end-0 translate-middle-y mr-3"></span>
            <a
              style={{ cursor: "pointer", color: "black" }}
              className="position-absolute top-50 end-0 translate-middle-y indigo-500"
              onClick={() => setOptions(!options)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="30"
                fill="currentColor"
                className="bi bi-three-dots-vertical pr-3"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </a>
            <ul
              className={`dropdown-menu position-absolute top-200 end-0 ${
                options ? "show" : ""
              }`}
            >
              <li>
                <a
                  className="dropdown-item"
                  style={{ width: "inherit" }}
                  onClick={() => {
                    navigate(`/home/macchinari/stampa/${produzione.id}`, {
                      state: {
                        produzione: produzione,
                        stampa: "ProduzioneDettaglio",
                      },
                    });
                  }}
                >
                  <i className="bi bi-printer"></i> STAMPA
                </a>
              </li>
              <li>
                <a
                  className={`dropdown-item ${
                    JSON.parse(localStorage.getItem("permission")).produzione
                      .allowDelete === 1 &&
                    nomeUtente === produzionePadre.utente
                      ? "d-flex"
                      : JSON.parse(localStorage.getItem("permission"))
                          .produzione.allowDelete === 3
                      ? "d-flex"
                      : "d-none"
                  }`}
                  onClick={() => {
                    handleDeleteButton();
                  }}
                >
                  <i className="bi bi-trash"></i> ELIMINA
                </a>
              </li>
              <li>
                <a className="dropdown-item">
                  <i className="bi bi-plus-circle"></i> AGGIUNGI
                </a>
              </li>
            </ul>
          </div>
          <div className="card-body">
            <div className="d-flex col-md-12 flex-md-nowrap flex-wrap">
              <CFormFloating className="mb-3 col-md-6 col-12 px-1">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enLocale}
                >
                  <DatePicker
                    label="Data"
                    value={produzionePadre.data}
                    renderInput={(params) => (
                      <TextField {...params} className="col-12" />
                    )}
                    onChange={(newValue) => {
                      let prod = produzionePadre;
                      prod["data"] = moment(newValue)
                        .local()
                        .format("YYYY-MM-DD");
                      setProduzione(prod);
                    }}
                    disabled={operazione === "Modifica"}
                  />
                </LocalizationProvider>
              </CFormFloating>
              <div className="d-flex flex-nowrap col-md-6 col-12 px-1">
                <CFormFloating
                  className="mb-3 col-12"
                  style={{
                    height: "100%",
                    paddingRight: "0px",
                    width:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "100%"
                        : "",
                  }}
                >
                  {searchReady ? (
                    <Autocomplete
                      value={produzionePadre.cantiere}
                      onChange={(event, newVal) => {
                        let prod = { ...produzionePadre };
                        prod["cantiere"] = newVal;
                        setProduzionePadre(prod);
                      }}
                      getRowId={(row) => row.id}
                      options={cantieri}
                      getOptionLabel={(option) => `${option}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Cantiere" />
                      )}
                      disabled={operazione === "Modifica"}
                    />
                  ) : (
                    ""
                  )}
                </CFormFloating>
              </div>
            </div>

            {operazione === "Modifica" ? (
              <>
                <div className="d-flex justify-content-end px-1">
                  <PopUp
                    operazione="Nuovo"
                    operazionePadre={location.state.operazionePadre}
                    title="Nuova Produzione"
                    eliminaProd={eliminaProd}
                    prod={produzione}
                    prodNew={produzionePadre}
                    cantiere={produzionePadre.cantiere}
                    attrezzature={attrezzature}
                    noleggi={noleggi}
                    operatore={operatore}
                    reloadData={fetchProduzioni}
                    utente={location.state.utente}
                  />
                  <PopUp
                    operazione="Modifica"
                    operazionePadre={location.state.operazionePadre}
                    title="Modifica Produzione"
                    eliminaProd={eliminaProd}
                    prod={produzioneSel}
                    cantiere={location.state.produzione.cantiere}
                    attrezzature={attrezzature}
                    noleggi={noleggi}
                    operatore={operatore}
                    reloadData={fetchProduzioni}
                    utente={location.state.utente}
                  />
                </div>
                <div className="row d-flex flex-nowrap gx-0 px-1">
                  <div className="py-2" style={{ height: 350, width: "100%" }}>
                    <DataGridPro
                      components={{ Toolbar: CustomToolbar }}
                      localeText={
                        itIT.components.MuiDataGrid.defaultProps.localeText
                      }
                      rows={produzione}
                      columns={columns}
                      getRowId={(row) => row.id}
                      columnVisibilityModel={columnVisibilityModel}
                      pageSize={5}
                      onRowDoubleClick={(params) => {
                        setProduzioneSel(params.row);
                        setEliminaProd(params.row.id);
                        const popUp = document.getElementById("btnMod");
                        popUp.click();
                      }}
                      onRowClick={(params) => {
                        setProduzioneSel(params.row);
                        setEliminaProd(params.row.id);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between">
            <div className="w-25 d-flex justify-content-start">
              <ReusableButton
                text="CHIUDI"
                icon="x"
                size="xs"
                onClick={() => {
                  if (location.state.search !== undefined) {
                    navigate("/home/produzione", {
                      state: {
                        search: location.state.search,
                      },
                    });
                  } else {
                    navigate(-1);
                  }
                }}
              />
            </div>
            {operazione === "Nuovo" ? (
              <div className="w-25 d-flex justify-content-end">
                <ReusableButton
                  text="AVANTI"
                  icon="arrow-right"
                  size="xs"
                  style={{
                    display:
                      permission.produzione.allowEdit === 0 ? "none" : "block",
                  }}
                  onClick={() => handleConfirmButton()}
                />
              </div>
            ) : (
              <div className={`w-25  justify-content-end`}>
                {/* <ReusableButton
                  text="SALVA"
                  icon="check"
                  size="xs"
                  onClick={() => handleConfirmButton()}
                /> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DettaglioProduzione;

const PopUp = (props) => {
  const [produzione, setProduzione] = useState({
    noleggio: 0,
    attrezzatura: "",
    commessa: 0,
    data: "",
    descrizione: "",
    id_intestazione: 0,
    lavorazione: "",
    note: "",
    operatore: "",
    produzione: 0,
    quantita: "",
    wbs: "",
    fermo: 0,
  });
  const [wbs, setWbs] = useState([]);
  const [valueWbs, setValueWbs] = useState([]);
  const [wbsAll, setWbsAll] = useState([]);
  const [lavorazioni, setLavorazioni] = useState([]);
  const [lavorazioneSel, setLavorazioneSel] = useState([]);
  const [attrezzature, setAttrezzature] = useState([]);
  const [noleggi, setNoleggi] = useState([]);
  const [valueNoleggi, setValueNoleggi] = useState("");
  const [operatore, setOperatore] = useState([]);
  const [valueOperatore, setValueOperatore] = useState("");
  const [descrizioni, setDescrizioni] = useState([]);
  const [descrizioniNol, setDescrizioniNol] = useState([]);
  const [um, setUm] = useState("");
  const [switchNoleggio, setSwitchNoleggio] = useState(false);
  const [ready, setReady] = useState(false);
  const [readyUm, setReadyUm] = useState(false);
  const [open, setOpen] = useState(false);
  const nomeUtente = localStorage.getItem("nomeUtente");
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleClickOpen = async () => {
    if (props.operazione === "Nuovo") {
      //se è nuovo carico i dati dalle props
      let prod = { ...produzione };
      prod["id_intestazione"] = props.prodNew.id;
      prod["data"] = props.prodNew.data;
      prod["commessa"] = props.cantiere;
      prod["quantita"] = "";
      prod["note"] = "";
      setProduzione(prod);
    } else {
      //se no passo il valore della WBS alla funzioe
      updateWbs(props.prod.wbs);
      let findOperatore = props.operatore.filter(
        (el) => props.prod.operatore === el.id
      );
      if (findOperatore.length > 0) {
        //solo se esiste una descrizione carico il valore
        setValueOperatore(findOperatore[0].descrizione);
      }

      const res = await api.get("api/cantieri_wbs", {
        //carico tutte le WBS associate al cantiere
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      let wbsAll = res.data.data;
      let cantWbs = [];
      if (
        props.operazionePadre === "Nuovo" &&
        props.operazione === "Modifica"
      ) {
        cantWbs = wbsAll.filter((el) => el.commessa === props.prod.commessa);
      } else {
        cantWbs = wbsAll.filter((el) => el.commessa === props.cantiere);
      }
      let findWbs = cantWbs.filter((el) => props.prod.wbs === el.id);
      setValueWbs(findWbs[0].descrizione);
    }

    if (
      props.prod.noleggio !== "0" &&
      props.prod.noleggio !== undefined &&
      props.prod.noleggio !== 0
    ) {
      setSwitchNoleggio(true);
    } else {
      setSwitchNoleggio(false);
    }
    let nol = props.noleggi.map((el) => el.matricola + " - " + el.descrizione);
    let desNol = props.noleggi.map((el) => el.descrizione);
    //carico i dati fissi delle select
    let att = props.attrezzature.map(
      (el) => el.matricola + " - " + el.descrizione
    );
    let des = props.attrezzature.map((el) => el.descrizione);
    let ope = props.operatore.map((el) => el.descrizione);
    setOperatore(ope);
    setNoleggi(nol);
    setDescrizioniNol(desNol);
    setAttrezzature(att);
    setDescrizioni(des);

    const res = await api.get("api/cantieri_wbs", {
      //carico tutte le WBS associate al cantiere
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    let wbsAll = res.data.data;
    let cantWbs = [];
    if (props.operazionePadre === "Nuovo" && props.operazione === "Modifica") {
      //se ho appena creato la produzione padre e voglio modificare il dettaglio-produzione
      cantWbs = wbsAll.filter((el) => el.commessa === props.prod.commessa);
      setWbsAll(cantWbs);
    } else {
      cantWbs = wbsAll.filter((el) => el.commessa === props.cantiere);
      setWbsAll(cantWbs);
    }

    let wbs = cantWbs.map((el) => el.descrizione);
    setWbs(wbs);
    if (props.operazione === "Nuovo") {
      setReady(true);
    } else {
      setProduzione(props.prod);
    }
    setOpen(true);
  };

  const updateWbs = async (newVal) => {
    //carico lavorazioni associate al cantiere in base alla WBS
    const res = await api.get("api/cantieri_lavorazioni", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const prodLav = res.data.data.filter(
      (el) => el.id_wbs === parseInt(newVal)
    );

    let lav = [];
    lav.push({ label: "SELEZIONA...", value: "" });
    for (let i = 0; i < prodLav.length; i++) {
      lav.push({
        indexSel: prodLav.indexOf(prodLav[i]),
        label: prodLav[i].descrizione,
        value: prodLav[i].id,
        um: prodLav[i].um,
        prezzo: prodLav[i].prezzo,
      });
    }
    setLavorazioni(lav);

    if (props.operazione === "Modifica") {
      let lavSel = lav.filter((el) => el.value === props.prod.lavorazione);
      setLavorazioneSel(lavSel);
      if (lavSel.length > 0) {
        setUm(lavSel[0].um);
      }
      setReadyUm(true);
      setReady(true);
    }
  };
  const handleChange = (event) => {
    if (event.target.name === "wbs") {
      //quando aggiorno la wbs ricarico tutte le lavorazioni associate
      updateWbs(event.target.value);
    }
    if (event.target.name === "lavorazione") {
      let indexSel = event.nativeEvent.target.selectedIndex;
      setLavorazioneSel(lavorazioni[indexSel]);
      setUm(lavorazioni[indexSel].um);
      setReadyUm(true);
    }
    if (event.target.name === "quantita") {
      //se modifico la quantità si aggiornara anche la produzione = prezzo*quantità
      if (props.operazione === "Modifica") {
        let result = produzione.prezzo * event.target.value;
        let prod = { ...produzione };
        prod["produzione"] = result;
        prod["quantita"] = event.target.value;
        setProduzione(prod);
      } else {
        let result =
          parseInt(event.target.value) * parseInt(lavorazioneSel.prezzo);
        let prod = { ...produzione };
        prod["produzione"] = result;
        prod["quantita"] = event.target.value;
        setProduzione(prod);
      }
    } else {
      if (
        event.target.name === "note" &&
        (event.target.value.includes(`"`) || event.target.value.includes(`'`))
      ) {
        setSnack({
          severity: "error",
          messaggio: "Non è possibile inserire gli apici",
        });
        setOpenSnack(true);
      } else {
        let prod = { ...produzione };
        prod[event.target.name] = event.target.value;
        setProduzione(prod);
      }
    }
  };
  const handleConfirmButton = async () => {
    if (props.operazione === "Nuovo") {
      const resPadre = await api.get("api/produzione_padre", {
        params: { id: true },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const prod = resPadre.data.data.filter(
        (el) => el.id === produzione.id_intestazione
      );
      let produzioneCorretta = { ...produzione };
      produzioneCorretta.data = moment(prod[0].data).format("YYYY-MM-DD");

      const res = await api.post(
        "api/produzione",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          produzione: produzioneCorretta,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({
        severity: "success",
        messaggio: "Inserimento effettuato",
      });
      setOpenSnack(true);
      setOpen(false);
      setProduzione({
        //resetto i campi di input
        noleggio: 0,
        attrezzatura: "",
        commessa: 0,
        data: "",
        descrizione: "",
        id_intestazione: 0,
        lavorazione: "",
        note: "",
        operatore: "",
        produzione: 0,
        quantita: "",
        wbs: "",
        fermo: 0,
      });
      setValueOperatore("");
      setValueNoleggi("");
      setValueWbs("");
      setSwitchNoleggio(false);

      if (props.operazionePadre === "Nuovo") {
        //ricarico i dati della tabella richiamando la funzione nella props
        props.reloadData("Nuovo", produzione.id_intestazione);
      } else {
        props.reloadData("Modifica");
      }
    } else {
      let prodData = { ...produzione };

      const resPadre = await api.get("api/produzione_padre", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const prod = resPadre.data.data.filter(
        (el) => el.id === produzione.id_intestazione
      );
      prodData.data = moment(prod[0].data).format("YYYY-MM-DD");

      const res = await api.put(
        "api/produzione",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", produzione: prodData },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
      setOpen(false);
      setProduzione({
        //resetto i campi di input
        noleggio: 0,
        attrezzatura: "",
        commessa: 0,
        data: "",
        descrizione: "",
        id_intestazione: 0,
        lavorazione: "",
        note: "",
        operatore: "",
        produzione: 0,
        quantita: "",
        wbs: "",
        fermo: 0,
      });
      setValueOperatore("");
      setValueNoleggi("");
      setValueWbs("");
      setSwitchNoleggio(false);

      if (props.operazionePadre === "Nuovo") {
        //ricarico i dati della tabella richiamando la funzione nella props
        props.reloadData("Modifica", produzione.id_intestazione);
      } else {
        props.reloadData("Modifica");
      }
    }
  };

  const handleDeleteButton = async (id) => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la produzione?"
    );
    if (isExecuted) {
      await api.delete("/api/produzione", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Produzione eliminata con successo",
      });
      setOpenSnack(true);
      props.reloadData(true);
    }
  };
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      {props.operazione === "Nuovo" ? (
        <ReusableButton
          onClick={handleClickOpen}
          text="AGGIUNGI"
          icon="plus-circle"
          size="xxs"
          id="btnAgg"
          className=""
        />
      ) : (
        <>
          {props.eliminaProd !== 0 ? (
            <ReusableButton
              text="MODIFICA"
              icon="pencil"
              size="xxs"
              id="btnMod"
              className="mx-2"
              onClick={handleClickOpen}
            />
          ) : (
            ""
          )}
          {props.eliminaProd !== 0 ? (
            <ReusableButton
              text="ELIMINA"
              icon="trash"
              size="xxs"
              className={`bg-red ${
                JSON.parse(localStorage.getItem("permission")).produzione
                  .allowDelete === 1 && nomeUtente === props.utente
                  ? "d-flex"
                  : JSON.parse(localStorage.getItem("permission")).produzione
                      .allowDelete === 3
                  ? "d-flex"
                  : "d-none"
              }`}
              onClick={() => {
                handleDeleteButton(props.eliminaProd);
              }}
            />
          ) : (
            ""
          )}
        </>
      )}
      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="position-absolute top-50 start-50 translate-middle my-auto overflow-y-auto overflow-x-hidden d-fixed right-0 left-0 h-modal md:h-screen flex bg-blue bg-opacity-50 justify-center w-100 h-100"
        style={{ display: open ? "" : "none", zIndex: "100" }}
      >
        <div className="p-0 mx-auto d-flex place-content-center justify-content-center w-100">
          <div className="pt-md-0 p-0 d-flex align-middle w-100">
            <div className="card text-center mx-auto  border-classic w-100 w-md-75">
              <div className="card-header">
                <span className="fs-4 col">{props.title}</span>
              </div>

              <div className="card-body">
                <div className="row d-flex">
                  <div className="d-flex flex-nowrap col-md-6 col-12 mx-auto">
                    <CFormFloating className="py-2" style={{ width: "100%" }}>
                      <CFormSelect
                        id="floatingSelect"
                        name="fermo"
                        options={["Produzione", "Fermo"]}
                        value={produzione.fermo === 1 ? "Fermo" : "Produzione"}
                        onChange={(e) => {
                          if (e.target.value === "Fermo") {
                            let prod = { ...produzione };
                            prod.fermo = 1;
                            setProduzione(prod);
                          } else {
                            let prod = { ...produzione };
                            prod.fermo = 0;
                            setProduzione(prod);
                          }
                        }}
                      />
                      <CFormLabel>Produzione/Fermo</CFormLabel>
                    </CFormFloating>
                  </div>
                  <div className="col-lg-12">
                    <CFormFloating className="">
                      {ready ? (
                        <Autocomplete
                          value={valueWbs}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              //inserisco condizione per non fare andare in errore
                              let findWbs = wbsAll.filter(
                                (el) => newValue === `${el.descrizione}`
                              );
                              setValueWbs(findWbs[0].descrizione);
                              let prod = { ...produzione };
                              prod["wbs"] = findWbs[0].id;
                              updateWbs(findWbs[0].id);
                              setProduzione(prod);
                            }
                          }}
                          name="wbs"
                          options={wbs}
                          getOptionLabel={(option) => `${option}`}
                          renderInput={(params) => (
                            <TextField {...params} label="Wbs" />
                          )}
                          disabled={
                            JSON.parse(localStorage.getItem("permission"))
                              .produzione.allowEdit === 0
                              ? true
                              : false
                          }
                        />
                      ) : (
                        ""
                      )}
                    </CFormFloating>

                    <div className="row d-flex flex-md-nowrap flex-wrap gx-0 mb-3">
                      <div className="col-md-6 col-12">
                        {ready ? (
                          <CFormFloating
                            className="py-2"
                            style={{ width: "100%" }}
                          >
                            <CFormSelect
                              id="floatingSelect"
                              name="lavorazione"
                              options={lavorazioni}
                              value={parseInt(produzione.lavorazione)}
                              onChange={handleChange}
                            />
                            <CFormLabel>Lavorazione</CFormLabel>
                          </CFormFloating>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-6 col-12">
                        <CInputGroup className="py-2 form-floating">
                          <CFormInput
                            value={produzione.quantita}
                            onChange={handleChange}
                            placeholder="Quantità"
                            name="quantita"
                            disabled={produzione.fermo === 1}
                          />

                          <CInputGroupText
                            id="basic-addon2"
                            className="rounded"
                          >
                            {um}
                          </CInputGroupText>

                          <CFormLabel style={{ color: "#6c757d" }}>
                            Quantità
                          </CFormLabel>
                        </CInputGroup>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap flex-md-nowrap gap-1 gx-0 ">
                      <CFormFloating className="mb-3 col-md-6 col-12">
                        {ready ? (
                          <Autocomplete
                            value={valueOperatore}
                            onChange={(event, newValue) => {
                              if (newValue !== null) {
                                //inserisco condizione per non fare andare in errore
                                let findOperatore = props.operatore.filter(
                                  (el) => newValue === `${el.descrizione}`
                                );
                                setValueOperatore(findOperatore[0].descrizione);
                                let prod = { ...produzione };
                                prod["operatore"] = findOperatore[0].id;
                                setProduzione(prod);
                              }
                            }}
                            name="operatore"
                            options={operatore}
                            getOptionLabel={(option) => `${option}`}
                            renderInput={(params) => (
                              <TextField {...params} label="Operatori" />
                            )}
                            disabled={
                              JSON.parse(localStorage.getItem("permission"))
                                .produzione.allowEdit === 0
                                ? true
                                : false
                            }
                          />
                        ) : (
                          ""
                        )}
                      </CFormFloating>

                      <div className="mb-3 col-md-6 col-12">
                        <CInputGroup className="form-floating col-12 ">
                          <CInputGroupText
                            id="basic-addon2"
                            className="rounded col-md-2 col-3 p-0 px-1"
                            onClick={() => {
                              setSwitchNoleggio(!switchNoleggio);
                              let prod = { ...produzione };
                              prod["noleggio"] = !switchNoleggio ? 1 : 0;
                              prod["attrezzatura"] = null; //azzero attrezzatura al cambio dello switch
                              setProduzione(prod);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <input
                              checked={switchNoleggio}
                              className="align-middle"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label className="fs-6" for="flexCheckDefault">
                              Noleggi
                            </label>
                          </CInputGroupText>
                          {!switchNoleggio ? (
                            <Autocomplete
                              value={
                                produzione.attrezzatura === null
                                  ? null
                                  : produzione.attrezzatura +
                                    " - " +
                                    produzione.descrizione
                              }
                              className="col-md-10 col-9"
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  //inserisco condizione per non fare andare in errore
                                  let onlyAttr = newValue.split(" - ");
                                  let prod = { ...produzione };
                                  prod["attrezzatura"] = onlyAttr[0];
                                  let indexSel = attrezzature.indexOf(newValue);
                                  prod["descrizione"] = descrizioni[indexSel];
                                  setProduzione(prod);
                                }
                              }}
                              name="attrezzatura"
                              options={attrezzature}
                              getOptionLabel={(option) => `${option}`}
                              renderInput={(params) => (
                                <TextField {...params} label="Attrezzatura" />
                              )}
                              disabled={
                                JSON.parse(localStorage.getItem("permission"))
                                  .produzione.allowEdit === 0
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <Autocomplete
                              value={
                                produzione.attrezzatura === null
                                  ? null
                                  : produzione.attrezzatura +
                                    " - " +
                                    produzione.descrizione
                              }
                              className="col-md-10 col-9"
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  let onlyAttr = newValue.split(" - ");
                                  /* 
                                  let prod = { ...produzione };
                                  prod["attrezzatura"] = onlyAttr[0];
                                  setProduzione(prod); */
                                  let prod = { ...produzione };
                                  prod["attrezzatura"] = onlyAttr[0];
                                  let indexSel = noleggi.indexOf(newValue);
                                  prod["descrizione"] =
                                    descrizioniNol[indexSel];
                                  setProduzione(prod);
                                }
                              }}
                              name="attrezzatura"
                              options={noleggi}
                              getOptionLabel={(option) => `${option}`}
                              renderInput={(params) => (
                                <TextField {...params} label="Noleggi" />
                              )}
                              disabled={
                                JSON.parse(localStorage.getItem("permission"))
                                  .produzione.allowEdit === 0
                                  ? true
                                  : false
                              }
                            />
                          )}
                        </CInputGroup>
                      </div>
                    </div>
                    <CFormFloating className="mb-3 col-12">
                      {produzione.quantita !== undefined ? (
                        <CFormInput
                          type="text"
                          id="ChassisInput"
                          name="produzione"
                          value={produzione.produzione}
                          disabled
                        />
                      ) : (
                        ""
                      )}

                      <CFormLabel>Produzione</CFormLabel>
                    </CFormFloating>
                    <CFormFloating className="mb-3 col-12">
                      <CFormTextarea
                        style={{ height: 100 }}
                        type="text"
                        id="ChassisInput"
                        name="note"
                        onChange={handleChange}
                        value={produzione.note}
                      />
                      <CFormLabel>Note</CFormLabel>
                    </CFormFloating>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-between ">
                <div className="w-25 d-flex justify-content-start">
                  <ReusableButton
                    text="CHIUDI"
                    icon="x"
                    size="xs"
                    onClick={() => {
                      setProduzione({
                        //resetto i valori di input
                        attrezzatura: "",
                        commessa: 0,
                        data: "",
                        descrizione: "",
                        id_intestazione: 0,
                        lavorazione: "",
                        note: "",
                        operatore: "",
                        produzione: 0,
                        quantita: "",
                        wbs: "",
                      });
                      setOpen(false);
                      setValueOperatore("");
                      setValueNoleggi("");
                      setValueWbs("");
                      setSwitchNoleggio(false);
                    }}
                  />
                </div>

                <div
                  className={`w-25  justify-content-end ${
                    JSON.parse(localStorage.getItem("permission")).produzione
                      .allowDelete === 1 && nomeUtente === props.utente
                      ? "d-flex"
                      : JSON.parse(localStorage.getItem("permission"))
                          .produzione.allowDelete === 3
                      ? "d-flex"
                      : "d-none"
                  }`}
                >
                  <ReusableButton
                    text="SALVA"
                    icon="check"
                    size="xs"
                    onClick={() => handleConfirmButton()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
