import React, { useEffect, useState } from "react";
import api from "./api";
import { useNavigate } from "react-router-dom";
import { CButton } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import ReusableButton from "./Reusable/Buttons";
import moment from "moment";

const manageFiles = async (e, row, name) => {
  let perc = row.matricola + "/" + row.matricola + "_" + name + ".pdf";
  let fileName = row.matricola + "_" + name + ".pdf";
  await api
    .post(
      "api/ftp",
      { file: fileName, percorso: perc, operazione: "prendi" },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    )
    .then((res) => {
      window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
    });
};

const columns = [
  {
    field: "matricola",
    headerName: "Matricola",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  /*   {
    field: "cantiere",
    headerName: "Cantiere",
    width: 150,
    headerAlign: "center",
    align: "center",
  }, */
  {
    field: "marca",
    headerName: "Marca",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "modello",
    headerName: "Modello",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "descrizione",
    headerName: "Descrizione",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "targa",
    headerName: "Targa",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "noleggiatore",
    headerName: "Noleggiatore",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  /*   {
    field: "data_inizio",
    headerName: "Data inizio noleggio",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data_fine",
    headerName: "Data fine noleggio",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    width: 150,
    headerAlign: "center",
    align: "center",
  }, */
  {
    field: "scheda_tecnica",
    headerName: "ST",
    width: 30,
    renderCell: (params) =>
      params.row.scheda_tecnica !== null &&
      params.row.scheda_tecnica !== undefined &&
      params.row.scheda_tecnica !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row, "scheda_tecnica")}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "certificato_ce",
    headerName: "CE",
    width: 30,
    renderCell: (params) =>
      params.row.certificato_ce !== null &&
      params.row.certificato_ce !== undefined &&
      params.row.certificato_ce !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row, "certificato_ce")}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "carta_circolazione",
    headerName: "cdc",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_circolazione).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row, "carta_circolazione")}
          ></div>
        );
      } else if (
        params.row.carta_circolazione !== null &&
        params.row.carta_circolazione !== undefined &&
        params.row.carta_circolazione !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row, "carta_circolazione")}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "assicurazione",
    headerName: "ass",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_assicurazione).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row, "assicurazione")}
          ></div>
        );
      } else if (
        params.row.assicurazione !== null &&
        params.row.assicurazione !== undefined &&
        params.row.assicurazione !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row, "assicurazione")}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "manuale_uso_manutenzione",
    headerName: "mum",
    width: 60,
    align: "center",
    renderCell: (params) =>
      params.row.manuale_uso_manutenzione !== null &&
      params.row.manuale_uso_manutenzione !== undefined &&
      params.row.manuale_uso_manutenzione !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) =>
            manageFiles(e, params.row, "manuale_uso_manutenzione")
          }
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "catalogo_ricambi",
    headerName: "cr",
    width: 20,
    align: "center",
    renderCell: (params) =>
      params.row.catalogo_ricambi !== null &&
      params.row.catalogo_ricambi !== undefined &&
      params.row.catalogo_ricambi !== "" ? (
        <div
          className="bg-green rounded"
          style={{ width: "20px", height: "20px", cursor: "pointer" }}
          onClick={(e) => manageFiles(e, params.row, "catalogo_ricambi")}
        ></div>
      ) : (
        <div
          className="rounded"
          style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
        ></div>
      ),
  },
  {
    field: "verifica_funi_catene",
    headerName: "VFC",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_funicatene).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row, "verifica_funi_catene")}
          ></div>
        );
      } else if (
        params.row.verifica_funi_catene !== null &&
        params.row.verifica_funi_catene !== undefined &&
        params.row.verifica_funi_catene !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row, "verifica_funi_catene")}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "visita_periodica",
    headerName: "VP",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_periodica).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row, "visita_periodica")}
          ></div>
        );
      } else if (
        params.row.visita_periodica !== null &&
        params.row.visita_periodica !== undefined &&
        params.row.visita_periodica !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row, "visita_periodica")}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
];

const Noleggi = () => {
  const [noleggi, setNoleggi] = useState([]);
  const [noleggiVisibili, setNoleggiVisibili] = useState([]);
  const [noleggiSel, setNoleggiSel] = useState([]);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  const zipAllFiles = async (matricola) => {
    api
      .post(
        "api/allegati",
        {
          all: true,
          macchina: matricola,
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", matricola + ".zip");
        document.body.appendChild(link);
        link.click();
      });
  };
  const handleChange = (e) => {
    var noleggiX = [];
    var i;
    for (i = 0; i < noleggi.length; i++) {
      if (
        noleggi[i].matricola?.toString().includes(e.target.value) ||
        noleggi[i].marca?.toString().includes(e.target.value) ||
        noleggi[i].noleggiatore?.toString().includes(e.target.value) ||
        noleggi[i].descrizione?.toString().includes(e.target.value) ||
        noleggi[i].modello.toString().includes(e.target.value) ||
        noleggi[i].targa?.toString().includes(e.target.value)
      ) {
        noleggiX.push(noleggi[i]);
      }
    }
    setNoleggiVisibili(noleggiX);

    if (e.target.value === "") {
      // perchè se è vuoto ricarico tutto
      setNoleggiVisibili(noleggi);
    }
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/noleggi", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let noleggi = res.data.data;
          if (
            JSON.parse(localStorage.getItem("permission")).noleggi_int
              .allowView === 1
          ) {
            noleggi = noleggi.filter(
              (noleggio) =>
                noleggio.utente === localStorage.getItem("nomeUtente")
            );
          }
          let correctData = noleggi.map((el) => {
            if (
              el.data_inizio === "1899-11-29T23:00:00.000Z" ||
              el.data_inizio === "0000-00-00"
            ) {
              el.data_inizio = null;
            }
            if (
              el.data_fine === "1899-11-29T23:00:00.000Z" ||
              el.data_fine === "0000-00-00"
            ) {
              el.data_fine = null;
            }
            return el;
          });
          setNoleggi(correctData);
          setNoleggiVisibili(correctData);
          setReady(true);
        });
    };
    fetchData();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">NOLEGGI</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center row flex-wrap flex-md-nowrap justify-content-center">
            <div className="d-flex col-md-6 col-12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  onChange={handleChange}
                  placeholder="Cerca..."
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              size="xxs"
              className={
                JSON.parse(localStorage.getItem("permission")).noleggi_int
                  .allowEdit === 0
                  ? "d-none"
                  : "d-block"
              }
              onClick={() =>
                navigate(`/home/noleggi/dettaglio-noleggi`, {
                  state: { noleggi: [], operazione: "Nuovo" },
                })
              }
            />
            <ReusableButton
              className={`${noleggiSel.length > 0 ? "d-block" : "d-none"}`}
              text="ESPORTA"
              icon="cloud-download"
              size="xxs"
              onClick={() => {
                let arrMatricole = [];
                for (let i = 0; i < noleggiSel.length; i++) {
                  noleggi.map((nol) => {
                    if (nol.id === noleggiSel[i]) {
                      arrMatricole.push(nol.matricola);
                    }
                  });
                }
                zipAllFiles(arrMatricole);
              }}
            />
          </div>
        </div>
        <div className="p-3" style={{ height: 650, width: "100%" }}>
          <DataGridPro
            components={{ Toolbar: CustomToolbar }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={noleggiVisibili}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={10}
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(params) => {
              const dataNoleggi = noleggi.filter(
                (noleggio) => noleggio.id === params.row.id
              );
              navigate(`/home/noleggi/dettaglio-noleggi`, {
                state: { noleggi: dataNoleggi[0], operazione: "Modifica" },
              });
            }}
            onRowSelectionModelChange={(newSelection) =>
              setNoleggiSel(newSelection)
            }
            selectionModel={noleggiSel}
          />
        </div>
      </div>
    </div>
  );
};

export default Noleggi;
