import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "./api";
import md5 from "md5";
import {
  CCard,
  CCardHeader,
  CCardBody,
  CRow,
  CCol,
  CButton,
  CFormInput,
  CFormLabel,
  CFormFloating,
  CFormSelect,
  CFormTextarea,
} from "@coreui/react";
import "../index.scss";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";

const DettaglioUtente = () => {
  const [user, setUser] = useState({});
  const [groups, setGroups] = useState([]);
  const [password, setPassword] = useState("");
  const [bottoneElimina, setBottoneElimina] = useState("block");
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      await api
        .get("api/groups", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          let gruppi = [];

          res.data.data.map((gruppo) =>
            gruppi.push({ label: gruppo.name, value: gruppo.groupID })
          );
          setGroups(gruppi);
        });
      if (location.state.operazione === "Nuovo") {
        setBottoneElimina("none");
      }
      setUser(location.state.utente);
    };

    fetchData();
  }, []);
  const handleChange = (e) => {
    let copiaUser = { ...user };
    copiaUser[e.target.name] = e.target.value;

    setUser(copiaUser);
  };
  const handleUpdateButton = async () => {
    let tempUser = user;
    if (location.state.operazione === "Modifica") {
      if (password !== "") {
        tempUser.passMD5 = md5(password);
      }
      await api.put(
        "api/users",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", user: tempUser },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      alert("Modifica effettuata");
    } else {
      tempUser.passMD5 = md5(password);
      tempUser.signupDate = new Date();
      await api.post(
        "api/users",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", user: tempUser },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      location.state.operazione = "Modifica";
      alert("Inserimento effettuato");
      setBottoneElimina("block");
    }
  };
  const handleDeleteButton = async () => {
    let isExecuted = window.confirm("Sei sicuro di voler cancellare l'utente?");
    if (isExecuted) {
      await api.delete("/api/users", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          user: user.memberID,
        },
      });

      alert("Utente eliminato con successo");
      navigate(-1);
    }
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">
              {location.state.operazione} {user.memberID}
            </span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
            {/* <span className="position-absolute top-50 end-0 translate-middle-y mr-3">
              {" "}
            </span>{" "}
            <a
              style={{ cursor: "pointer", color: "black" }}
              className="position-absolute top-50 end-0 translate-middle-y indigo-500"
              onClick={() => setOptions(!options)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="30"
                fill="currentColor"
                className="bi bi-three-dots-vertical pr-3"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </a>
            <ul
              className={`dropdown-menu position-absolute top-200 end-0 ${
                options ? "show" : ""
              }`}
            >
              <li>
                <a
                  className="dropdown-item"
                  style={{ display: bottoneElimina }}
                  onClick={() => handleDeleteButton()}
                >
                  <i className="bi bi-trash"></i> ELIMINA
                </a>
              </li>
            </ul> */}
          </div>

          {/* BODY */}
          <div className="card-body">
            <CCardBody>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="memberID"
                  value={user.memberID}
                />
                <CFormLabel>Username</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="password"
                  id="DescrizioneInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <CFormLabel>Password</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3" style={{ width: "100%" }}>
                <CFormSelect
                  id="floatingSelect"
                  options={groups}
                  value={parseInt(user.groupID)}
                  onChange={(e) => {
                    let tempUser = { ...user };
                    tempUser.groupID = e.target.value;
                    setUser(tempUser);
                  }}
                />

                <CFormLabel>Lavorazione</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="email"
                  value={user.email}
                />
                <CFormLabel>Email Address</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="custom1"
                  value={user.custom1}
                />
                <CFormLabel>Full Name</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="custom2"
                  value={user.custom2}
                />
                <CFormLabel>Address</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="custom3"
                  value={user.custom3}
                />
                <CFormLabel>City</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="custom4"
                  value={user.custom4}
                />
                <CFormLabel>State</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-3">
                <CFormTextarea
                  type="text"
                  id="DescrizioneInput"
                  onChange={(e) => handleChange(e)}
                  name="comments"
                  value={user.comments}
                />
                <CFormLabel>Comments</CFormLabel>
              </CFormFloating>
            </CCardBody>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap gap-2">
            <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap"></div>

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap/*  */">
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xs"
                className={`bg-red ${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-flex"
                }`}
                onClick={() => handleDeleteButton()}
              />
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                onClick={() => handleUpdateButton()}
              />
              {/* <button
                className="btn bg-green text-white fs-6"
                onClick={() => handleUpdateButton()}
              >
                <i className="bi bi-check fs-5"></i> SALVA
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DettaglioUtente;
