import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CFormLabel, CFormInput, CFormFloating } from "@coreui/react";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import { DetailControlled } from "./Reusable/DetailControlled";

const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_VALUE":
      return { ...state, [action.payload.filed]: action.payload.value };
    case "LOAD_VALUE":
      return action.payload.data;
  }
  return state;
};

const initialState = {
  id: "",
  descrizione: "",
};

const DettaglioTipologiaCosti = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //const [tipologiaCosti, setTipologiaCosti] = useState();

  const [tipologiaCosti, dispatch] = useReducer(reducer, initialState);

  const { id, descrizione } = tipologiaCosti || {};
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la tipologiaCosti?"
    );
    if (isExecuted) {
      await api.delete("/api/tipologia_costi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: tipologiaCosti.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "TipologiaCosti eliminata con successo",
      });
      setOpenSnack(true);

      //navigate(-1);
    }
  };
  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_VALUE",
      payload: {
        filed: e.target.name,
        value: e.target.value,
      },
    });
    /* let tipo = { ...tipologiaCosti };
    tipo[event.target.name] = event.target.value;
    setTipologiaCosti(tipo); */
  };
  const handleConfirmButton = async () => {
    let tipo = { ...tipologiaCosti };
    if (location.state.operazione === "Modifica") {
      await api.put(
        "api/tipologia_costi",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          tipologia_costi: tipo,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
    } else {
      let tipo = { ...tipologiaCosti };
      const response = await api.post(
        "api/tipologia_costi",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", tipologia_costi: tipo },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      tipo["id"] = response.data.message.insertId;
      location.state.operazione = "Modifica";
      //ottengo l'id dell'elemento appena inserito
      setSnack({ severity: "success", messaggio: "Inserimento effettuato" });
      setOpenSnack(true);
    }
  };
  useEffect(() => {
    (async () => {
      if (location.state.operazione === "Modifica") {
        dispatch({
          type: "LOAD_VALUE",
          payload: { data: location.state.tipologiaCosti },
        });
      }
    })();
  }, []); //funzione che permette di caricare i dati una volta che si è caricato il component

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <DetailControlled
        headerText="Inserimento tipologiaCosti"
        closeModal={() => {
          if (location.state.search !== undefined) {
            navigate("/home/tipologiaCosti", {
              state: {
                search: location.state.search,
              },
            });
          } else {
            navigate(-1);
          }
        }}
        footerRight={
          <>
            <ReusableButton
              text="ELIMINA"
              icon="trash"
              size="xs"
              onClick={() => handleDeleteButton()}
              className={`bg-red ${
                location.state.operazione === "Nuovo" ? "d-none" : "d-block"
              }`}
            />
            <ReusableButton
              text="SALVA"
              icon="check"
              size="xs"
              onClick={() => handleConfirmButton()}
            />
          </>
        }
      >
        <div className="col">
          <CFormFloating className="mb-3">
            <CFormInput
              type="text"
              name="descrizione"
              id="DescrizioneInput"
              value={tipologiaCosti.descrizione}
              onChange={handleChange}
            />
            <CFormLabel>Descrizione</CFormLabel>
          </CFormFloating>
        </div>
      </DetailControlled>
    </>
  );
};

export default DettaglioTipologiaCosti;
