import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CButton } from "@coreui/react";
import Divider from "@mui/material/Divider";
import etichetta from "../img/etichetta.png";
import QRCode from "qrcode.react";
import ReusableButton from "./Reusable/Buttons";

const PrintQrcode = () => {
  const [macchinari, setMacchiari] = useState([]);
  //const [matricole, setMatricole] = useState([]);
  const [url, setUrl] = useState([]);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const macc = location.state.macchinari;
      setMacchiari(macc);
      setReady(true);
    };

    fetchData();
  }, []);

  function printPage() {
    var firstPar = document.getElementById("divPrint");
    var secondPar = document.getElementById("firstDivPrint");
    firstPar.parentNode.removeChild(firstPar);
    secondPar.parentNode.removeChild(secondPar);
    window.print();
    window.location.reload();
  }

  return (
    <>
      <div className="mt-5" id="firstDivPrint">.</div>
      <div className="container mx-auto mt-2 bg-light rounded">
        <br />
        <div className="d-flex justify-content-between mx-4" id="divPrint">
          <CButton
            className="align-self-center mt-4 mb-3 btn btn-green d-flex align-items-center justify-content-center"
            onClick={() => navigate(-1)}
          >
            <i className="bi bi-x-lg fs-6"></i>
            <span className="mx-2 fs-6">CHIUDI</span>
          </CButton>
          <CButton
            id="buttonPrint"
            className="align-self-center mt-4 mb-3 btn btn-green d-flex align-items-center justify-content-center"
            onClick={() => printPage()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-printer"
              viewBox="0 0 16 16"
            >
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
            </svg>
            <span className="mx-2 fs-6">Print</span>
          </CButton>
        </div>
        <div className="print-container mozilla">
          {ready
            ? macchinari.map((macchinario) => {
                let url = `https://portale.fondamentasrl.it/home/macchinari/qrcode/${macchinario.matricola}`;
                return (
                  <div key={macchinario.matricola}>
                    <div>
                      <span className="mx-4 mb-0">
                        {macchinario.marca} - {macchinario.modello}
                      </span>
                    </div>
                    <Divider variant="middle" className="mx-4" />
                    <div
                      style={{
                        width: "600px",
                        margin: "8px auto",
                        marginBottom: "45px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          width: "500px",
                          height: "263px",
                          background: `url(${etichetta}) no-repeat center / cover`,
                        }}
                      >
                        <h4
                          style={{
                            marginLeft: "110px",
                            marginTop: "200px",
                          }}
                        >
                          {macchinario.matricola}
                        </h4>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexWrap: "no-wrap",
                          padding: "30px",
                          marginLeft: "-255px",
                        }}
                      >
                        <br />

                        <QRCode
                          value={url}
                          style={{ width: "165px", height: "165px" }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};
export default PrintQrcode;
