import React, { useEffect, useState } from "react";
import api from "./api";
import { useLocation, useNavigate } from "react-router-dom";
import { CButton, CSpinner } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

import moment from "moment";
import ReusableButton from "./Reusable/Buttons";
import GlobalSnackbar from "./Snackbar";

const columns = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
    hide: true,
  },
  {
    field: "utente",
    headerName: "Utente",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "data",
    headerName: "Data",
    type: "date",
    valueGetter: ({ value }) => value && new Date(value),
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "cantiere",
    headerName: "Cantiere",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "matricola",
    headerName: "Matricola",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "m_giri",
    headerName: "M Giri",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "ore",
    headerName: "Ore",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "minuti",
    headerName: "Minuti",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
];

const Accise = (props) => {
  const [accise, setAccise] = useState([]);
  const [acciseVisibili, setAcciseVisibili] = useState([]);
  const [acciseCaricate, setAcciseCaricate] = useState(false);
  const [acciseSel, setAcciseSel] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    brokerId: false,
    status: false,
  });
  const applyFilter = (e, val, acc) => {
    var accisaX = [];
    var i;
    for (i = 0; i < acc.length; i++) {
      if (
        acc[i].utente?.toLowerCase().includes(val.toLowerCase()) ||
        acc[i].data?.toLowerCase().includes(val.toLowerCase()) ||
        acc[i].matricola?.toLowerCase().includes(val.toLowerCase()) ||
        acc[i].cantiere?.toString().includes(val.toLowerCase()) ||
        acc[i].m_giri?.toLowerCase().includes(val.toLowerCase()) ||
        acc[i].ore?.toLowerCase().includes(val.toLowerCase()) ||
        acc[i].minuti?.toLowerCase().includes(val.toLowerCase())
      ) {
        accisaX.push(acc[i]);
      }
    }
    setAcciseVisibili(accisaX);
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setSearchValue("");
    }
    var accisaX = [];
    var i;
    for (i = 0; i < accise.length; i++) {
      if (
        accise[i].utente
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        accise[i].cantiere?.toString().includes(e.target.value.toLowerCase()) ||
        accise[i].data?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        accise[i].matricola
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        accise[i].m_giri
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        accise[i].ore?.toLowerCase().includes(e.target.value.toLowerCase()) ||
        accise[i].minuti?.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        accisaX.push(accise[i]);
      }
    }
    setAcciseVisibili(accisaX);
  };

  const handleDeleteAccise = async () => {
    if (acciseSel.length === 0) {
      const errorMsg = {
        severity: "error",
        messaggio: "Selezionare un'accisa",
      };
      setSnack(errorMsg);
      setOpenSnack(true);
    } else {
      if (
        window.confirm(
          "ATTENZIONE, sei sicuro di voler eliminare le accise selezionate?"
        )
      ) {
        try {
          await api.delete("/api/accise", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            data: {
              id: acciseSel.join(","),
            },
          });
          setSnack({
            severity: "success",
            messaggio: "Accisa eliminata con successo",
          });
          setOpenSnack(true);
          window.location.reload();
        } catch {
          setSnack({
            severity: "error",
            messaggio: "Errore durante l'eliminazione",
          });
          setOpenSnack(true);
        }
      }
    }
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      try {
        let response = await api.get("api/accise", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        });
        let accise = response.data.data;
        if (
          JSON.parse(localStorage.getItem("permission")).accise.allowView === 1
        ) {
          accise = accise.filter(
            (accisa) => accisa.utente === localStorage.getItem("nomeUtente")
          );
        }
        setAccise(accise);
        if (location.state.search === "") {
          setAcciseVisibili(accise);
        } else {
          setSearchValue(location.state.search);
          applyFilter("search", location.state.search, accise);
        }
        setAcciseCaricate(true);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        <div className="card text-center  border-classic">
          <div className="card-header position-relative">
            <span className="fs-4 col">ACCISE</span>
          </div>
          <div
            className="card-header position-relative py-3"
            style={{
              backgroundColor: "white",
              borderBottom: "0px solid rgba(0,0,0,.125)",
            }}
          >
            <div className="d-flex align-items-center row flex-wrap flex-md-nowrap justify-content-center">
              <div className="d-flex col-md-6 col-12">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-sm"
                    >
                      <i className={`bi bi-search fs-6`}></i>
                    </span>
                  </div>
                  <input
                    value={searchValue}
                    onChange={handleChange}
                    placeholder="Cerca..."
                    type="text"
                    className="form-control"
                    aria-label="Text input with checkbox"
                  />
                </div>
              </div>
              <ReusableButton
                text="AGGIUNGI"
                icon="plus-circle"
                size="xxs"
                className="mt-md-0 mt-2"
                onClick={() =>
                  navigate(`/home/accise/:id`, {
                    state: {
                      accise: [moment(new Date()).local().format("YYYY-MM-DD")],
                      operazione: "Nuovo",
                    },
                  })
                }
              />
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xxs"
                className={`bg-red mt-md-0 mt-2 ${
                  JSON.parse(localStorage.getItem("permission")).accise
                    .allowDelete === 3
                    ? "d-flex"
                    : "d-none"
                }`}
                onClick={handleDeleteAccise}
              />
            </div>
          </div>
          <div className="p-3" style={{ height: 650, width: "100%" }}>
            {acciseVisibili !== 0 ? (
              <DataGridPro
                components={{ Toolbar: CustomToolbar }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={acciseVisibili}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                getRowId={(row) => row.id}
                pageSize={10}
                checkboxSelection
                disableSelectionOnClick
                onRowClick={(params) => {
                  const dataAccise = accise.filter(
                    (accisa) => accisa.id === params.row.id
                  );

                  if (searchValue !== "") {
                    navigate(`/home/accise/${dataAccise[0].matricola}`, {
                      state: {
                        accise: dataAccise[0],
                        operazione: "Modifica",
                        search: searchValue,
                      },
                    });
                  } else {
                    navigate(`/home/accise/${dataAccise[0].matricola}`, {
                      state: {
                        accise: dataAccise[0],
                        operazione: "Modifica",
                        search: searchValue,
                      },
                    });
                  }
                }}
                onRowSelectionModelChange={(newSelection) => {
                  setAcciseSel(newSelection);
                }}
                selectionModel={acciseSel}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Accise;
