import React, { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CContainer,
  CButton,
  CButtonGroup,
  CCard,
  CCardHeader,
  CCollapse,
  CCol,
  CFormSwitch,
  CFormTextarea,
} from "@coreui/react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import enLocale from "date-fns/locale/en-IE";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Backdrop, CircularProgress } from "@mui/material";
import { ConnectingAirportsOutlined } from "@mui/icons-material";

import { saveAs } from "file-saver";
import { DataGridPro, itIT } from "@mui/x-data-grid-pro";

const FotoNoleggi = ({ noleggioSel, fotoNoleggi, reload }) => {
  const [noleggio, setNoleggio] = useState({
    id: "",
    id_noleggi: noleggioSel.id,
    data: moment(new Date()).format("YYYY-MM-DD"),
    foto: "",
  });

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const columns = [
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) =>
        params.row.data ? moment(params.row.data).format("YYYY-MM-DD") : null,
    },
    /* {
      field: "*",
      headerName: "Importa",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <div className="d-none mb-3">
            <input
              accept="image/jpeg"
              className="form-control"
              type="file"
              id="formFile"
              onChange={(e) => {
                loadFile(
                  e.target.files[0],
                  moment(params.row.data).format("YYYY-MM-DD")
                );
              }}
            />
          </div>
          <CButton
            className="btn btn-secondary rounded"
            onClick={() => {
              document.getElementById("formFile").click();
            }}
            style={{
              display:
                location.state.operazione === "Nuovo"
                  ? "none"
                  : JSON.parse(localStorage.getItem("permission")).noleggi_int
                      .allowEdit === 0
                  ? "none"
                  : !params.row.foto
                  ? "block"
                  : "none",
            }}
          >
            <i className="bi bi-upload"></i>
          </CButton>
        </>
      ),
    }, */
    {
      field: "**",
      headerName: "Download",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <CButton
          className="btn btn-secondary rounded"
          onClick={(e) => manageFiles(params.row)}
          style={{
            display: params.row.foto !== "" ? "block" : "none",
          }}
        >
          <i className="bi bi-download"></i>
        </CButton>
      ),
    },
    {
      field: "***",
      headerName: "Visualizzazione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <CButton
            className="btn btn-secondary rounded"
            onClick={(e) => viewFiles(params.row)}
            style={{
              display: params.row.foto ? "block" : "none",
            }}
          >
            <i className="bi bi-eye"></i>
          </CButton>
        </>
      ),
    },
    {
      field: "****",
      headerName: "Eliminazione",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {JSON.parse(localStorage.getItem("permission")).noleggi_int
            .allowDelete !== 0 ? (
            <CButton
              className="btn btn-secondary rounded"
              onClick={(e) => deleteFiles(params.row, "scheda_tecnica")}
              style={{
                display: params.row.foto ? "block" : "none",
              }}
            >
              <i className="bi bi-trash"></i>
            </CButton>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const deleteFiles = async (row, colonnaDb) => {
    let perc = row.foto;
    let fotoName = row.foto.split("/")[row.foto.split("/").length - 1];
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la foto: " + fotoName + "?"
    );
    if (isExecuted) {
      try {
        await api.post(
          "api/ftp",
          { percorso: perc, operazione: "elimina" },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );

        try {
          await api.delete("api/fotoNoleggi", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
            data: { id: row.id },
          });
          setSnack({
            severity: "success",
            messaggio: "File Eliminato con successo",
          });
          setOpenSnack(true);
          reload();
        } catch (error) {
          setSnack({
            severity: "error",
            messaggio: "Eliminazione persorso file fallito",
          });
          setOpenSnack(true);
        }
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio: "Eliminazione fallita, controllare presenza del file",
        });
        setOpenSnack(true);
      }
    }
  };

  const manageFiles = async (row) => {
    let perc = row.foto;
    let fileName =
      moment(row.data).format("YYYY-MM-DD") +
      "_" +
      noleggioSel.matricola +
      ".jpg";
    await api
      .post(
        "api/ftp",
        { file: fileName, percorso: perc, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        //window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
        var oReq = new XMLHttpRequest();
        // The Endpoint of your server
        var URLToPDF = "https://portale.fondamentasrl.it/file?file=" + fileName;
        // Configure XMLHttpRequest
        oReq.open("GET", URLToPDF, true);

        // Important to use the blob response type
        oReq.responseType = "blob";

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
          // Once the file is downloaded, open a new window with the PDF
          // Remember to allow the POP-UPS in your browser
          var filee = new Blob([oReq.response], {
            type: "application/pdf",
          });

          // Generate file download directly in the browser !
          saveAs(filee, fileName);
        };

        oReq.send();
      });
  };

  const viewFiles = async (row) => {
    let perc = row.foto;
    let fileName =
      moment(row.data).format("YYYY-MM-DD") +
      "_" +
      noleggioSel.matricola +
      ".jpg";
    await api
      .post(
        "api/ftp",
        { file: fileName, percorso: perc, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://portale.fondamentasrl.it/file?file=" + fileName);
      });
  };

  const loadFile = async (filex, matricola, nome, nomeColonnaDb) => {
    setOpenBackdrop(true);

    const formData = new FormData();
    //filex.name=matricola+"_"+moment(data).format("YYMMDD")+".pdf";

    formData.append("file", filex);
    formData.append("matricola", noleggioSel.matricola);
    //formData.append("data", data);
    formData.append(
      "nomeManutenzione",
      "foto/" +
        moment(nome).format("YYYY-MM-DD") +
        "_" +
        noleggioSel.matricola +
        ".jpg"
    );
    await api
      .post("api/ftp/caricaDoc", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async () => {
        setOpenBackdrop(false);
        setSnack({
          severity: "success",
          messaggio: "Documento caricato con successo!",
        });
        setOpenSnack(true);
        let nol = { ...noleggio };
        nol.foto =
          "/" +
          noleggioSel.matricola +
          "/foto/" +
          moment(nome).format("YYYY-MM-DD") +
          "_" +
          noleggioSel.matricola +
          ".jpg";
        api
          .post(
            "api/fotoNoleggi",
            { auth: "a5d42c3ed7977afa2d8e922569e7940b", fotoNoleggi: nol },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          )
          .then((res) => {
            reload();
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="d-flex gap-4 justify-content-start align-items-center m-3">
        <CFormFloating className="">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
            <DatePicker
              label="Data"
              value={noleggio.data}
              renderInput={(params) => (
                <TextField {...params} className="col-12" />
              )}
              onChange={(newValue) => {
                let nol = { ...noleggio };
                nol["data"] = moment(newValue).local().format("YYYY-MM-DD");
                setNoleggio(nol);
              }}
              disabled={
                JSON.parse(localStorage.getItem("permission")).accise
                  .allowEdit === 0
                  ? true
                  : false
              }
            />
          </LocalizationProvider>
        </CFormFloating>
        <div className="d-none mb-3">
          <input
            accept="image/jpeg"
            className="form-control"
            type="file"
            id="formFile"
            onChange={(e) => {
              loadFile(
                e.target.files[0],
                moment(noleggio?.data).format("YYYY-MM-DD")
              );
            }}
          />
        </div>
        {JSON.parse(localStorage.getItem("permission")).noleggi_int
          .allowEdit !== 0 ? (
          <>
            <ReusableButton
              text="CARICA FOTO"
              icon="upload"
              size="xxs"
              className="mt-md-0 mt-2"
              onClick={() => document.getElementById("formFile").click()}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="p-3" style={{ height: 400, width: "100%" }}>
        {fotoNoleggi ? (
          <DataGridPro
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={fotoNoleggi}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={10}
            disableSelectionOnClick
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default FotoNoleggi;
