import axios from "axios";
const call = axios.create({
  //baseUrl: "http://localhost:3000/",
  baseUrl: "https://portale.fondamentasrl.it/",
});
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NDE5OTY3ODR9.UdIvvrZxaq7anKpTOLmWEGbm5F8NkhVs9xgib9jAykU";

//call.defaults.baseURL = "http://localhost:3000/";

call.defaults.baseURL = "https://portale.fondamentasrl.it/";
call.defaults.headers.common["Authorization"] = `Bearer ${token}`;
call.defaults.headers.post["Content-Type"] = "application/json";

export default call;

//http://localhost:3000

//https://portale.fondamentasrl.it/
