import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CContainer,
  CButton,
  CButtonGroup,
  CCard,
  CCardHeader,
} from "@coreui/react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";
import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";

const DettaglioLavorazioni = () => {
  const [lavorazione, setLavorazione] = useState({
    descrizione: "",
    um: "",
    colore: "",
  });
  const [um, setUm] = useState([
    "ml",
    "mq",
    "mc",
    "mctt",
    "kg",
    "ton",
    "qli",
    "lt",
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const [options, setOptions] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la lavorazione?"
    );
    if (isExecuted) {
      await api.delete("/api/lavorazioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: lavorazione.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Lavorazione eliminata con successo",
      });
      setOpenSnack(true);

      //navigate(-1);
    }
  };
  const handleChange = (event) => {
    let lav = { ...lavorazione };
    lav[event.target.name] = event.target.value;

    setLavorazione(lav);
  };

  const handleConfirmButton = async () => {
    if (location.state.operazione === "Modifica") {
      await api.put(
        "api/lavorazioni",
        {
          auth: "a5d42c3ed7977afa2d8e922569e7940b",
          lavorazione: lavorazione,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
    } else {
      let lav = { ...lavorazione };
      const response = await api.post(
        "api/lavorazioni",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", lavorazione: lav },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      lav["id"] = response.data.message.insertId;
      location.state.operazione = "Modifica";
      //ottengo l'id dell'elemento appena inserito
      setSnack({ severity: "success", messaggio: "Inserimento effettuato" });
      setOpenSnack(true);
    }
  };
  useEffect(() => {
    const fetchData = () => {
      if (location.state.operazione === "Modifica") {
        setLavorazione(location.state.lavorazione);
      }
    };
    fetchData();
  }, []); //funzione che permette di caricare i dati una volta che si è caricato il component

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento lavorazioni</span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.state.search !== undefined) {
                  navigate("/home/lavorazioni", {
                    state: {
                      search: location.state.search,
                    },
                  });
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
            {/* <span className="position-absolute top-50 end-0 translate-middle-y mr-3"></span>
            <a
              style={{ cursor: "pointer", color: "black" }}
              className="position-absolute top-50 end-0 translate-middle-y indigo-500"
              onClick={() => setOptions(!options)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="30"
                fill="currentColor"
                className="bi bi-three-dots-vertical pr-3"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </a>
            <ul
              className={`dropdown-menu position-absolute top-200 end-0 ${
                options ? "show" : ""
              }`}
            >
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    navigate(
                      `/home/macchinari/stampa/${lavorazione.descrizione}`,
                      {
                        state: {
                          lavorazione: lavorazione,
                          stampa: "LavorazioneDettaglio",
                        },
                      }
                    );
                  }}
                >
                  <i className="bi bi-printer"></i> STAMPA
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => handleDeleteButton()}
                  style={{
                    display:
                      location.state.operazione === "Nuovo"
                        ? "None"
                        : JSON.parse(localStorage.getItem("permission"))
                            .lavorazioni.allowDelete === 0
                        ? "none"
                        : "block",
                  }}
                >
                  <i className="bi bi-trash"></i> ELIMINA
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  //onClick={() => {}}
                >
                  <i className="bi bi-plus-circle"></i> AGGIUNGI
                </a>
              </li>
            </ul> */}
          </div>

          {/* BODY */}
          <div className="card-body d-flex flex-md-nowrap flex-wrap gap-2">
            <CFormFloating className="col-md-4 col-12">
              <CFormInput
                type="text"
                name="descrizione"
                id="DescrizioneInput"
                value={lavorazione.descrizione}
                onChange={handleChange}
                disabled={
                  JSON.parse(localStorage.getItem("permission")).lavorazioni
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
              <CFormLabel>Descrizione</CFormLabel>
            </CFormFloating>

            <Autocomplete
              value={lavorazione.um}
              onChange={(event, newValue) => {
                let lav = { ...lavorazione };
                lav["um"] = newValue;
                setLavorazione(lav);
              }}
              id="controllable-states-demo"
              className="col-md-4 col-12"
              options={um}
              getOptionLabel={(option) => `${option}`}
              renderInput={(params) => (
                <TextField {...params} label="Unità di misura" />
              )}
              disabled={
                JSON.parse(localStorage.getItem("permission")).lavorazioni
                  .allowEdit === 0
                  ? true
                  : false
              }
            />
            <div className="col-md-4 col-12">
              <input
                className="rounded border"
                style={{ height: "40px", width: "40px" }}
                type="color"
                name="colore"
                id="DescrizioneInput"
                value={lavorazione.colore}
                onChange={(event) => {
                  let lav = { ...lavorazione };
                  lav.colore = event.target.value;
                  setLavorazione(lav);
                }}
                disabled={
                  JSON.parse(localStorage.getItem("permission")).lavorazioni
                    .allowEdit === 0
                    ? true
                    : false
                }
              />
            </div>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap gap-2">
            <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap">
              <ReusableButton
                text="STAMPA"
                icon="printer"
                size="xxs"
                onClick={() => {
                  navigate(
                    `/home/macchinari/stampa/${lavorazione.descrizione}`,
                    {
                      state: {
                        lavorazione: lavorazione,
                        stampa: "LavorazioneDettaglio",
                      },
                    }
                  );
                }}
              />
            </div>

            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xs"
                className={`bg-red ${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                onClick={() => handleDeleteButton()}
                style={{
                  display:
                    JSON.parse(localStorage.getItem("permission")).cantieri
                      .allowEdit === 0
                      ? "none"
                      : "block",
                }}
              />
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                onClick={() => handleConfirmButton()}
                style={{
                  display:
                    JSON.parse(localStorage.getItem("permission")).cantieri
                      .allowEdit === 0
                      ? "none"
                      : "block",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DettaglioLavorazioni;
