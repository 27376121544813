import React, { useEffect, useState } from "react";
import api from "./api";
import { useLocation, useNavigate } from "react-router-dom";
import { CButton, CSpinner, CFormSwitch, CRow } from "@coreui/react";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  DataGridPro,
  GridToolbar,
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

import GlobalSnackbar from "./Snackbar";
import ReusableButton from "./Reusable/Buttons";
import moment from "moment";
import { map } from "d3";

const manageFiles = async (e, cFile) => {
  let f = cFile.split("/");
  let file = f[2];
  await api
    .post(
      "api/ftp",
      { file: file, percorso: cFile, operazione: "prendi" },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    )
    .then((res) => {
      window.open("https://portale.fondamentasrl.it/file?file=" + file);
    });
};

const columns = [
  {
    field: "matricola",
    headerName: "Matricola",
    width: 90,
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 200,
  },
  {
    field: "cognome",
    headerName: "Cognome",
    width: 200,
  },
  {
    field: "descrizione",
    headerName: "Descrizione",
    width: 150,
  },
  {
    field: "luogo",
    headerName: "luogo",
    width: 150,
  },
  {
    field: "data_inizio",
    headerName: "Data Inizio",
    width: 150,
    renderCell: (params) =>
      params.row.data_inizio
        ? moment(params.row.data_inizio).format("YYYY-MM-DD")
        : null,
  },
  {
    field: "data_fine",
    headerName: "Data Fine",
    width: 80,
    renderCell: (params) =>
      params.row.data_fine
        ? moment(params.row.data_fine).format("YYYY-MM-DD")
        : null,
  },
  {
    field: "data_nascita",
    headerName: "Data Nascita",
    width: 80,
    renderCell: (params) =>
      params.row.data_nascita
        ? moment(params.row.data_nascita).format("YYYY-MM-DD")
        : null,
  },
  {
    field: "ci",
    headerName: "ci",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_ci).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.ci)}
          ></div>
        );
      } else if (
        params.row.ci !== null &&
        params.row.ci !== undefined &&
        params.row.ci !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.ci)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "psog",
    headerName: "psog",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_psog).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.psog)}
          ></div>
        );
      } else if (
        params.row.psog !== null &&
        params.row.psog !== undefined &&
        params.row.psog !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.psog)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "cf",
    headerName: "cf",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_cf).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.cf)}
          ></div>
        );
      } else if (
        params.row.cf !== null &&
        params.row.cf !== undefined &&
        params.row.cf !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.cf)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "ts",
    headerName: "ts",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_ts).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.ts)}
          ></div>
        );
      } else if (
        params.row.ts !== null &&
        params.row.ts !== undefined &&
        params.row.ts !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.ts)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "patente",
    headerName: "patente",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_patente).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.patente)}
          ></div>
        );
      } else if (
        params.row.patente !== null &&
        params.row.patente !== undefined &&
        params.row.patente !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.patente)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "unilav",
    headerName: "unilav",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_unilav).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.unilav)}
          ></div>
        );
      } else if (
        params.row.unilav !== null &&
        params.row.unilav !== undefined &&
        params.row.unilav !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.unilav)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "idoneita",
    headerName: "idoneita",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_idoneita).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.idoneita)}
          ></div>
        );
      } else if (
        params.row.idoneita !== null &&
        params.row.idoneita !== undefined &&
        params.row.idoneita !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.idoneita)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "formazione",
    headerName: "formazione",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_formazione).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.formazione)}
          ></div>
        );
      } else if (
        params.row.formazione !== null &&
        params.row.formazione !== undefined &&
        params.row.formazione !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.formazione)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "preposto",
    headerName: "preposto",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_preposto).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.preposto)}
          ></div>
        );
      } else if (
        params.row.preposto !== null &&
        params.row.preposto !== undefined &&
        params.row.preposto !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.preposto)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "dirigente",
    headerName: "dirigente",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_dirigente).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.dirigente)}
          ></div>
        );
      } else if (
        params.row.dirigente !== null &&
        params.row.dirigente !== undefined &&
        params.row.dirigente !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.dirigente)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "rspp",
    headerName: "rspp",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_rspp).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.rspp)}
          ></div>
        );
      } else if (
        params.row.rspp !== null &&
        params.row.rspp !== undefined &&
        params.row.rspp !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.rspp)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "rls",
    headerName: "rls",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_rls).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.rls)}
          ></div>
        );
      } else if (
        params.row.rls !== null &&
        params.row.rls !== undefined &&
        params.row.rls !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.rls)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "diisocianati",
    headerName: "diisocianati",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_diisocianati).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.diisocianati)}
          ></div>
        );
      } else if (
        params.row.diisocianati !== null &&
        params.row.diisocianati !== undefined &&
        params.row.diisocianati !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.diisocianati)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "ps",
    headerName: "ps",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_ps).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.ps)}
          ></div>
        );
      } else if (
        params.row.ps !== null &&
        params.row.ps !== undefined &&
        params.row.ps !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.ps)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "antincendio",
    headerName: "antincendio",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_antincendio).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.antincendio)}
          ></div>
        );
      } else if (
        params.row.antincendio !== null &&
        params.row.antincendio !== undefined &&
        params.row.antincendio !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.antincendio)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "dpi3cat",
    headerName: "dpi3cat",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_dpi3cat).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.dpi3cat)}
          ></div>
        );
      } else if (
        params.row.dpi3cat !== null &&
        params.row.dpi3cat !== undefined &&
        params.row.dpi3cat !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.dpi3cat)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "saldatore",
    headerName: "saldatore",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_saldatore).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.saldatore)}
          ></div>
        );
      } else if (
        params.row.saldatore !== null &&
        params.row.saldatore !== undefined &&
        params.row.saldatore !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.saldatore)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "ponteggi",
    headerName: "ponteggi",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_ponteggi).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.ponteggi)}
          ></div>
        );
      } else if (
        params.row.ponteggi !== null &&
        params.row.ponteggi !== undefined &&
        params.row.ponteggi !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.ponteggi)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "quota",
    headerName: "quota",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_quota).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.quota)}
          ></div>
        );
      } else if (
        params.row.quota !== null &&
        params.row.quota !== undefined &&
        params.row.quota !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.quota)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "ambconf",
    headerName: "ambconf",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_ambconf).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.ambconf)}
          ></div>
        );
      } else if (
        params.row.ambconf !== null &&
        params.row.ambconf !== undefined &&
        params.row.ambconf !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.ambconf)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "dumper",
    headerName: "dumper",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_dumper).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.dumper)}
          ></div>
        );
      } else if (
        params.row.dumper !== null &&
        params.row.dumper !== undefined &&
        params.row.dumper !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.dumper)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "pfr_pd",
    headerName: "pfr_pd",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_pfr_pd).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.pfr_pd)}
          ></div>
        );
      } else if (
        params.row.pfr_pd !== null &&
        params.row.pfr_pd !== undefined &&
        params.row.pfr_pd !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.pfr_pd)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "pfr_gd",
    headerName: "pfr_gd",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_pfr_gd).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.pfr_gd)}
          ></div>
        );
      } else if (
        params.row.pfr_gd !== null &&
        params.row.pfr_gd !== undefined &&
        params.row.pfr_gd !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.pfr_gd)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "ple",
    headerName: "ple",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_ple).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.ple)}
          ></div>
        );
      } else if (
        params.row.ple !== null &&
        params.row.ple !== undefined &&
        params.row.ple !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.ple)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "carroponte",
    headerName: "carroponte",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_carroponte).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.carroponte)}
          ></div>
        );
      } else if (
        params.row.carroponte !== null &&
        params.row.carroponte !== undefined &&
        params.row.carroponte !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.carroponte)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "pompecls",
    headerName: "pompecls",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_pompecls).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.pompecls)}
          ></div>
        );
      } else if (
        params.row.pompecls !== null &&
        params.row.pompecls !== undefined &&
        params.row.pompecls !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.pompecls)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "grutorre",
    headerName: "grutorre",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_grutorre).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.grutorre)}
          ></div>
        );
      } else if (
        params.row.grutorre !== null &&
        params.row.grutorre !== undefined &&
        params.row.grutorre !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.grutorre)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "elev",
    headerName: "elev",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_elev).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.elev)}
          ></div>
        );
      } else if (
        params.row.elev !== null &&
        params.row.elev !== undefined &&
        params.row.elev !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.elev)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "autogru",
    headerName: "autogru",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_autogru).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.autogru)}
          ></div>
        );
      } else if (
        params.row.autogru !== null &&
        params.row.autogru !== undefined &&
        params.row.autogru !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.autogru)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },

  {
    field: "mmt",
    headerName: "mmt",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_mmt).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.mmt)}
          ></div>
        );
      } else if (
        params.row.mmt !== null &&
        params.row.mmt !== undefined &&
        params.row.mmt !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.mmt)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "altro1",
    headerName: "altro1",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_altro1).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.altro1)}
          ></div>
        );
      } else if (
        params.row.altro1 !== null &&
        params.row.altro1 !== undefined &&
        params.row.altro1 !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.altro1)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
  {
    field: "altro2",
    headerName: "altro2",
    width: 50,
    renderCell: (params) => {
      if (
        moment(new Date()).local().format("YYYY-MM-DD") >=
        moment(params.row.scadenza_altro2).local().format("YYYY-MM-DD")
      ) {
        return (
          <div
            className="rounded"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
              backgroundColor: "red",
            }}
            onClick={(e) => manageFiles(e, params.row.altro2)}
          ></div>
        );
      } else if (
        params.row.altro2 !== null &&
        params.row.altro2 !== undefined &&
        params.row.altro2 !== ""
      ) {
        return (
          <div
            className="bg-green rounded"
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={(e) => manageFiles(e, params.row.altro2)}
          ></div>
        );
      } else {
        return (
          <div
            className="rounded"
            style={{ width: "20px", height: "20px", backgroundColor: "grey" }}
          ></div>
        );
      }
    },
    align: "center",
  },
];

const Personale = (props) => {
  const [personale, setPersonale] = useState([]);
  const [personaleVisibili, setPersonaleVisibili] = useState([]);
  const [personaleVisibiliSearch, setPersonaleVisibiliSearch] = useState([]);
  const [personaleSenzaVenduti, setPersonaleSenzaVenduti] = useState([]);
  const [personaleSel, setPersonaleSel] = useState(null);
  const [personaleScaduti, setPersonaleScaduti] = useState([]);
  const [personaleManutScaduta, setPersonaleManutScaduta] = useState([]);
  const [personaleCaricati, setPersonaleCaricati] = useState(false);
  const [qrcode, setQrcode] = useState([]);
  const [scaduti, setScaduti] = useState(false);
  const [venduti, setVenduti] = useState(false);
  const [qrcodePrint, setQrcodePrint] = useState(false);
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const zipAllFiles = async (matricola) => {
    api
      .post(
        "api/allegati",
        {
          all: true,
          personale: matricola,
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", matricola + ".zip");
        document.body.appendChild(link);
        link.click();
      });
  };
  const applyFilter = (e, val, macch) => {
    var personaleX = [];
    var i;
    for (i = 0; i < macch.length; i++) {
      if (
        macch[i].matricola?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].nome?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].cognome?.toLowerCase().includes(val.toLowerCase()) ||
        macch[i].luogo?.toLowerCase().includes(val.toLowerCase())
      ) {
        personaleX.push(macch[i]);
      }
    }
    setPersonaleVisibili(personaleX);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setSearchValue("");
    }
    var personaleX = [];
    var i;
    if (document.getElementById("flexCheckVenduti").checked) {
      for (i = 0; i < personale.length; i++) {
        if (
          personale[i].matricola
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          personale[i].nome
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          personale[i].cognome
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          personale[i].luogo
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          personaleX.push(personale[i]);
        }
      }
    } else {
      for (i = 0; i < personaleSenzaVenduti.length; i++) {
        if (
          personaleSenzaVenduti[i].matricola
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          personaleSenzaVenduti[i].nome
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          personaleSenzaVenduti[i].cognome
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          personaleSenzaVenduti[i].luogo
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          personaleX.push(personaleSenzaVenduti[i]);
        }
      }
      setPersonaleVisibili(personaleX);
    }
  };
  const handleVendutiClick = (e) => {
    if (scaduti) {
      setScaduti(false);
      if (e.target.checked) {
        setPersonaleVisibili(personale);
      } else {
        setPersonaleVisibili(personaleSenzaVenduti);
      }
      setVenduti(!venduti);
    } else {
      if (e.target.checked) {
        setPersonaleVisibili(personale);
      } else {
        setPersonaleVisibili(personaleSenzaVenduti);
      }
      setVenduti(!venduti);
    }
  };
  const handleScadutiClick = (e) => {
    if (venduti) {
      setVenduti(false);
      e.target.checked
        ? setPersonaleVisibili(personaleScaduti)
        : setPersonaleVisibili(personale);
      setScaduti(!scaduti);
    } else {
      e.target.checked
        ? setPersonaleVisibili(personaleScaduti)
        : setPersonaleVisibili(personaleSenzaVenduti);
      setScaduti(!scaduti);
    }
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      const resMacch = await api.get("api/personale", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      const personale = resMacch.data.data;
      let correctData = personale.map((el) => {
        if (
          el.scadenza_ci === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_ci === "0000-00-00"
        ) {
          el.scadenza_ci = null;
        }
        if (
          el.scadenza_psog === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_psog === "0000-00-00"
        ) {
          el.scadenza_psog = null;
        }
        if (
          el.scadenza_cf === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_cf === "0000-00-00"
        ) {
          el.scadenza_cf = null;
        }
        if (
          el.scadenza_ts === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_ts === "0000-00-00"
        ) {
          el.scadenza_ts = null;
        }
        if (
          el.scadenza_patente === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_patente === "0000-00-00"
        ) {
          el.scadenza_patente = null;
        }
        if (
          el.scadenza_unilav === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_unilav === "0000-00-00"
        ) {
          el.scadenza_unilav = null;
        }
        if (
          el.scadenza_idoneita === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_idoneita === "0000-00-00"
        ) {
          el.scadenza_idoneita = null;
        }
        if (
          el.scadenza_formazione === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_formazione === "0000-00-00"
        ) {
          el.scadenza_formazione = null;
        }
        if (
          el.scadenza_preposto === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_preposto === "0000-00-00"
        ) {
          el.scadenza_preposto = null;
        }
        if (
          el.scadenza_dirigente === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_dirigente === "0000-00-00"
        ) {
          el.scadenza_dirigente = null;
        }
        if (
          el.scadenza_rspp === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_rspp === "0000-00-00"
        ) {
          el.scadenza_rspp = null;
        }
        if (
          el.scadenza_rls === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_rls === "0000-00-00"
        ) {
          el.scadenza_rls = null;
        }
        if (
          el.scadenza_diisocianati === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_diisocianati === "0000-00-00"
        ) {
          el.scadenza_diisocianati = null;
        }
        if (
          el.scadenza_ps === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_ps === "0000-00-00"
        ) {
          el.scadenza_ps = null;
        }
        if (
          el.scadenza_antincendio === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_antincendio === "0000-00-00"
        ) {
          el.scadenza_antincendio = null;
        }
        if (
          el.scadenza_dpi3cat === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_dpi3cat === "0000-00-00"
        ) {
          el.scadenza_dpi3cat = null;
        }
        if (
          el.scadenza_saldatore === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_saldatore === "0000-00-00"
        ) {
          el.scadenza_saldatore = null;
        }
        if (
          el.scadenza_ponteggi === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_ponteggi === "0000-00-00"
        ) {
          el.scadenza_ponteggi = null;
        }
        if (
          el.scadenza_quota === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_quota === "0000-00-00"
        ) {
          el.scadenza_quota = null;
        }
        if (
          el.scadenza_ambconf === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_ambconf === "0000-00-00"
        ) {
          el.scadenza_ambconf = null;
        }
        if (
          el.scadenza_dumper === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_dumper === "0000-00-00"
        ) {
          el.scadenza_dumper = null;
        }
        if (
          el.scadenza_pfr_pd === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_pfr_pd === "0000-00-00"
        ) {
          el.scadenza_pfr_pd = null;
        }
        if (
          el.scadenza_pfr_gd === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_pfr_gd === "0000-00-00"
        ) {
          el.scadenza_pfr_gd = null;
        }
        if (
          el.scadenza_ple === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_ple === "0000-00-00"
        ) {
          el.scadenza_ple = null;
        }
        if (
          el.scadenza_carroponte === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_carroponte === "0000-00-00"
        ) {
          el.scadenza_carroponte = null;
        }
        if (
          el.scadenza_pompecls === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_pompecls === "0000-00-00"
        ) {
          el.scadenza_pompecls = null;
        }
        if (
          el.scadenza_grutorre === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_grutorre === "0000-00-00"
        ) {
          el.scadenza_grutorre = null;
        }
        if (
          el.scadenza_elev === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_elev === "0000-00-00"
        ) {
          el.scadenza_elev = null;
        }
        if (
          el.scadenza_autogru === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_autogru === "0000-00-00"
        ) {
          el.scadenza_autogru = null;
        }
        if (
          el.scadenza_mmt === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_mmt === "0000-00-00"
        ) {
          el.scadenza_mmt = null;
        }
        if (
          el.scadenza_altro1 === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_altro1 === "0000-00-00"
        ) {
          el.scadenza_altro1 = null;
        }
        if (
          el.scadenza_altro2 === "1899-11-29T23:00:00.000Z" ||
          el.scadenza_altro2 === "0000-00-00"
        ) {
          el.scadenza_altro2 = null;
        }
        return el;
      });
      setPersonale(personale);
      setPersonaleSenzaVenduti(
        personale.filter((macchina) => macchina.stato_attuale !== "Venduto")
      );
      if (location.state.search === "") {
        setPersonaleVisibili(
          personale.filter((macchina) => macchina.stato_attuale !== "Venduto")
        );
      } else {
        setSearchValue(location.state.search);
        applyFilter(
          "search",
          location.state.search,
          personale.filter((macchina) => macchina.stato_attuale !== "Venduto")
        );
      }

      //CONTROLLO
      const per = personale.filter((macchina) => {
        return (
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_ci).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_psog).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_cf).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_ts).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_patente).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_unilav).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_idoneita).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_formazione).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_preposto).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_dirigente).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_rspp).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_rls).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_diisocianati)
              .local()
              .format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_ps).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_antincendio)
              .local()
              .format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_dpi3cat).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_saldatore).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_ponteggi).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_quota).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_ambconf).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_dumper).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_pfr_pd).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_pfr_gd).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_ple).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_carroponte).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_pompecls).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_grutorre).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_elev).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_autogru).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_mmt).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_altro1).local().format("YYYY-MM-DD") ||
          moment(new Date()).local().format("YYYY-MM-DD") >=
            moment(macchina.scadenza_altro2).local().format("YYYY-MM-DD")
        );
      });
      setPersonaleScaduti(per);

      setPersonaleCaricati(true);
      const resScaden = await api.get("api/scadenzaManutenzioni", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setPersonaleManutScaduta(resScaden.data.data);
      setReady(true);
    };
    fetchData();
  }, []);
  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap">
        <GridToolbarColumnsButton className="green" />
        <GridToolbarFilterButton className="green" />
        <GridToolbarDensitySelector className="green" />
        <GridToolbarExport className="green" />
        <div
          className="form-check form-switch"
          style={{ padding: "4px 5px", marginLeft: "1.5rem" }}
        >
          <input
            className={`form-check-input input-green `}
            style={{ backgroundColor: scaduti ? "#009a3d" : "" }}
            type="checkbox"
            id="flexCheckVenduti"
            onClick={handleScadutiClick}
            checked={scaduti}
          />
          <label
            className="form-check-label green"
            for="flexSwitchCheckDefault"
            style={{ fontSize: "0.8125rem" }}
          >
            SCADUTI
          </label>
        </div>
      </GridToolbarContainer>
    );
  }
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="card text-center  border-classic">
        <div className="card-header position-relative">
          <span className="fs-4 col">PERSONALE</span>
        </div>
        <div
          className="card-header position-relative py-3"
          style={{
            backgroundColor: "white",
            borderBottom: "0px solid rgba(0,0,0,.125)",
          }}
        >
          <div className="d-flex align-items-center row flex-wrap flex-md-nowrap justify-content-center">
            <div className="d-flex col-md-6 col-12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-search fs-6`}></i>
                  </span>
                </div>
                <input
                  value={searchValue}
                  onChange={handleChange}
                  placeholder="Cerca…"
                  type="text"
                  className="form-control"
                  aria-label="Text input with checkbox"
                />
                <div
                  style={{ cursor: "pointer" }}
                  className="input-group-prepend"
                  onClick={() => {
                    setSearchValue("");
                    return applyFilter("search", "", personale);
                  }}
                >
                  <span className="input-group-text" id="inputGroup-sizing-sm">
                    <i className={`bi bi-x-lg fs-6`}></i>
                  </span>
                </div>
              </div>
            </div>
            <ReusableButton
              text="AGGIUNGI"
              icon="plus-circle"
              className="mx-3 mt-md-0 mt-2"
              size="xxs"
              onClick={() =>
                navigate(`/home/personale/:id`, {
                  state: { personale: [], operazione: "Nuovo" },
                })
              }
              style={{
                display:
                  JSON.parse(localStorage.getItem("permission")).report
                    .allowInsert === 0
                    ? "None"
                    : "Block",
              }}
            />
            <ReusableButton
              className={`${personaleSel ? "d-block" : "d-none"}`}
              text="ESPORTA"
              icon="cloud-download"
              size="xxs"
              onClick={() => {
                zipAllFiles(personaleSel);
              }}
            />
            <ReusableButton
              text="QR"
              icon="printer"
              size="xxs"
              onClick={() =>
                qrcode.length === 0
                  ? (setSnack({
                      severity: "error",
                      messaggio: "Selezionare un personale",
                    }),
                    setOpenSnack(true))
                  : navigate(`/home/personale/print-qrcode`, {
                      state: { personale: qrcode },
                    })
              }
              style={{ display: qrcodePrint ? "block" : "none" }}
              className="mt-md-0 mt-2"
            />
          </div>
        </div>
        {personaleVisibili.length === 0 && !personaleCaricati ? (
          <p>
            {" "}
            <center>
              <CSpinner color="primary" />
            </center>
          </p>
        ) : (
          <>
            <Box
              className="p-3"
              sx={{
                height: 650,
                width: 1,
                "& .hot": {
                  backgroundColor: "#d14d5a",
                },
                "& .purple": {
                  backgroundColor: "#BF40BF",
                },
              }}
            >
              <DataGridPro
                sx={{ fontSize: 12 }}
                components={{ Toolbar: CustomToolbar }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                rows={personaleVisibili}
                columns={columns}
                getRowId={(row) => row.matricola}
                pageSize={20}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "matricola", sort: "desc" }],
                  },
                }}
                checkboxSelection
                disableSelectionOnClick
                getCellClassName={(params) => {
                  if (
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_ci).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_psog).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_cf).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_ts).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_patente).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_unilav).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_idoneita).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_formazione).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_preposto).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_dirigente).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_rspp).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_rls).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_diisocianati).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_ps).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_antincendio).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_dpi3cat).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_saldatore).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_ponteggi).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_quota).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_ambconf).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_dumper).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_pfr_pd).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_pfr_gd).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_ple).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_carroponte).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_pompecls).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_grutorre).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_elev).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_autogru).format(
                        "YYYY-MM-DD"
                      ) ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_mmt).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_altro1).format("YYYY-MM-DD") ||
                    moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza_altro2).format("YYYY-MM-DD")
                  ) {
                    return "hot";
                  }
                  if (ready) {
                    let scad = personaleManutScaduta.filter(
                      (macc) => macc.matricola === params.row.matricola
                    );

                    if (scad[0]) {
                      return "purple";
                    }
                  }
                }}
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = personaleVisibili.filter(
                    (row) => selectedIDs.has(row.matricola.toString()));

                  setQrcodePrint(true);
                  setPersonaleSel(
                    selectedRowData.map((el) => {
                      return (
                        el.matricola +
                        "_" +
                        el.cognome
                          .split(" ")
                          .join("")
                          .toUpperCase()
                          .slice(0, 10) +
                        "_" +
                        el.nome.split(" ").join("").toUpperCase().slice(0, 10)
                      );
                    })
                  );
                  setQrcode(selectedRowData);
                }}
                onRowClick={async (params, event) => {
                  if (
                    params.row.foto !== "" &&
                    params.row.foto !== null &&
                    params.row.foto !== undefined
                  ) {
                    let f = params.row.foto.split("/");
                    let file = f[2];
                    await api.post(
                      "api/ftp",
                      {
                        file: file,
                        percorso: params.row.foto,
                        operazione: "prendi",
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "jwt"
                          )}`,
                        },
                      }
                    );
                  }
                  const dataPersonale = personale.filter(
                    (personale) => personale.matricola === params.row.matricola
                  );
                  if (searchValue !== "") {
                    navigate(`/home/personale/${dataPersonale[0].matricola}`, {
                      state: {
                        personale: dataPersonale[0],
                        operazione: "Modifica",
                        search: searchValue,
                      },
                    });
                  } else {
                    navigate(`/home/personale/${dataPersonale[0].matricola}`, {
                      state: {
                        personale: dataPersonale[0],
                        operazione: "Modifica",
                        search: searchValue,
                      },
                    });
                  }
                }}
              />
            </Box>
          </>
        )}
      </div>
    </div>
  );
};

export default Personale;
