export const ControlledButton = ({
  size,
  id,
  className,
  icon,
  text,
  onClick,
  style,
}) => {
  return (
    <>
      {size === "xxs" ? (
        <div
          className="d-flex justify-content-start"
          style={{ width: "fit-content" }}
        >
          <button
            id={id}
            className={`btn bg-green text-white fs-6 my-auto d-flex flex-nowrap gap-1 ${className}`}
            onClick={() => onClick()}
            style={style}
          >
            <i className={`bi bi-${icon} fs-6`}></i> {text}
          </button>
        </div>
      ) : (
        ""
      )}
      {size === "xs" ? (
        <div
          className="d-flex justify-content-start"
          style={{ width: "fit-content" }}
        >
          <button
            id={id}
            className={`btn bg-green text-white fs-5 my-auto d-flex flex-nowrap gap-1 ${className}`}
            onClick={() => onClick()}
            style={style}
          >
            <i className={`bi bi-${icon} fs-5`}></i> {text}
          </button>
        </div>
      ) : (
        ""
      )}
      {size === "md" ? (
        <div className="w-25 d-flex justify-content-start">
          <button
            id={id}
            className={`btn bg-green text-white fs-3  ${className}`}
            onClick={() => onClick()}
          >
            <i className={`bi bi-${icon} fs-3`}></i> {text}
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
