import { Component, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CContainer,
  CButton,
  CButtonGroup,
  CCard,
  CCardHeader,
} from "@coreui/react";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";
import enLocale from "date-fns/locale/en-IE";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import GlobalSnackbar from "./Snackbar";
import moment from "moment";
import ReusableButton from "./Reusable/Buttons";

const AccisaDati = (props) => {
  const [accisa, setAccisa] = useState({
    id: "",
    utente: "",
    data: "",
    cantiere: "",
    matricola: "",
    m_giri: "",
    k_giri: "",
    ore: "",
    minuti: "",
  });
  const [matricole, setMatricole] = useState([]);
  const [cantieri, setCantieri] = useState([]);
  const [searchReady, setSearchReady] = useState(false);
  const [funiCatene, setFuniCatene] = useState(false);
  const [options, setOptions] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleChange = (event) => {
    let manut = accisa;
    manut[event.target.name] = event.target.value;
    setAccisa(manut);
  };

  const handleDeleteButton = async () => {
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare la Accisa?"
    );
    if (isExecuted) {
      await api.delete("/api/accise", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: accisa.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Accisa eliminata con successo",
      });
      setOpenSnack(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  };

  const handleConfirmButton = async () => {
    let acc = { ...accisa };

    try {
      acc["utente"] = localStorage.getItem("nomeUtente");
      if (location.state.operazione === "Nuovo") {
        const response = await api.post(
          "api/accise",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", accise: acc },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        location.state.operazione = "Modifica";
        acc["id"] = response.data.message.insertId; //ottengo l'id dell'elemento appena inserito
      } else if (location.state.operazione === "MacchinarioNuovo") {
        /* let accDate = { ...acc };
        accDate["data"] = moment(acc.data).local().format("YYYY-MM-DD"); */
        const response = await api.post(
          "api/accise",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", accise: acc },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        location.state.operazione = "Modifica";
        acc["id"] = response.data.message.insertId; //ottengo l'id dell'elemento appena inserito
      } else {
        acc["data"] = moment(accisa.data).format("YYYY-MM-DD");
        await api.put(
          "api/accise",
          { auth: "a5d42c3ed7977afa2d8e922569e7940b", accise: acc },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
      }
      setSnack({
        severity: "success",
        messaggio: "Accisa salvata con successo",
      });
      setOpenSnack(true);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    } catch {
      setSnack({
        severity: "error",
        messaggio: "Errore nel salvataggio dei dati",
      });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/macchinari", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const matricole = res.data.data.map(
            (matricola) => matricola.matricola
          );
          setMatricole(matricole);
        });
      api
        .get("api/cantieri", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const cantieri = res.data.data.map((cantiere) => cantiere.commessa);
          setCantieri(cantieri);
        });
      setSearchReady(true);

      if (location.state.operazione === "Nuovo") {
        let acc = { ...accisa };
        acc["data"] = moment(new Date());

        setAccisa(acc);
      } else if (location.state.operazione === "MacchinarioNuovo") {
        let acc = { ...accisa };
        acc["matricola"] = location.state.accise;
        acc["data"] = moment(new Date());
        setAccisa(acc);
      } else {
        const accisa = location.state.accise;
        setAccisa(accisa);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic mx-4 mx-md-0">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento Accise</span>
            <span
              className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (location.state.search !== undefined) {
                  navigate("/home/accise", {
                    state: {
                      search: location.state.search,
                    },
                  });
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="bi bi-x fs-1 my-auto"></i>
            </span>
          </div>

          {/* BODY */}
          <div className="card-body">
            <div className="d-flex col-md-12 flex-md-nowrap flex-wrap">
              <CFormFloating className="col-md-6 col-12">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={enLocale}
                >
                  <DatePicker
                    label="Data"
                    value={accisa.data}
                    renderInput={(params) => (
                      <TextField {...params} className="col-12" />
                    )}
                    onChange={(newValue) => {
                      let acc = { ...accisa };
                      acc["data"] = moment(newValue)
                        .local()
                        .format("YYYY-MM-DD");
                      setAccisa(acc);
                      setFuniCatene(true);
                    }}
                    disabled={
                      JSON.parse(localStorage.getItem("permission")).accise
                        .allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </LocalizationProvider>
              </CFormFloating>
              <div className="row d-flex flex-nowrap gx-0 col-md-6 col-12">
                <CFormFloating
                  className="mb-md-3 mb-2 col-10 col-md-11"
                  style={{
                    height: "100%",
                    width:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "100%"
                        : "",
                  }}
                >
                  {searchReady ? (
                    <Autocomplete
                      value={accisa.cantiere}
                      onChange={(event, newValue) => {
                        let acc = { ...accisa };
                        acc["cantiere"] = newValue;
                        setAccisa(acc);
                      }}
                      id="controllable-states-demo"
                      options={cantieri}
                      getOptionLabel={(option) => `${option}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Cantiere" />
                      )}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).accise
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  ) : (
                    ""
                  )}
                </CFormFloating>
                <CButton
                  className="btn btn-green mb-md-3 mb-2 col-2 col-md-1"
                  style={{
                    display:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "none"
                        : "block",
                  }}
                  onClick={() =>
                    navigate(`/home/cantieri/dettaglio-cantiere`, {
                      state: { cantiere: [], operazione: "Nuovo" },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </CButton>
              </div>
            </div>

            <div className="d-flex col-md-12  flex-md-nowrap flex-wrap">
              <div className="d-flex flex-nowrap gx-0 col-12">
                <CFormFloating
                  className="mb-md-3 mb-2 col-10 col-md-11"
                  style={{
                    height: "100%",
                    width:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "100%"
                        : "",
                  }}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).manutenzioni
                      .allowView === 1
                      ? localStorage.getItem("nomeUtente") === accisa.utente
                        ? true
                        : false
                      : ""
                  }
                >
                  {searchReady ? (
                    <Autocomplete
                      value={accisa.matricola}
                      onChange={(event, newValue) => {
                        let acc = { ...accisa };
                        acc["matricola"] = newValue;
                        setAccisa(acc);
                      }}
                      id="controllable-states-demo"
                      options={matricole}
                      getOptionLabel={(option) => `${option}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Matricola" />
                      )}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).accise
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  ) : (
                    ""
                  )}
                </CFormFloating>
                <CButton
                  className="btn btn-green mb-md-3 mb-2 col-2 col-md-1"
                  style={{
                    display:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "none"
                        : "block",
                  }}
                  onClick={() =>
                    navigate(`/home/macchinari/:id`, {
                      state: { macchinario: [], operazione: "Nuovo" },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </CButton>
              </div>
            </div>
            <div className="d-flex col-md-12  flex-md-nowrap flex-wrap">
              <CFormFloating className="mb-md-3 mb-2 col-md-6 col-12">
                <CFormInput
                  type="text"
                  id="DescrizioneInput"
                  name="m_giri"
                  defaultValue={accisa.m_giri}
                  onChange={handleChange}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).accise
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
                <CFormLabel>M giri</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-md-3 mb-2 col-md-6 col-12">
                <CFormInput
                  type="text"
                  id="ChassisInput"
                  name="k_giri"
                  defaultValue={accisa.k_giri}
                  onChange={handleChange}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).accise
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
                <CFormLabel>K giri</CFormLabel>
              </CFormFloating>
            </div>
            <div className="d-flex col-md-12  flex-md-nowrap flex-wrap">
              <CFormFloating className="mb-md-3 mb-2 col-md-6 col-12">
                <CFormInput
                  type="text"
                  id="TargaInput"
                  name="ore"
                  defaultValue={accisa.ore}
                  onChange={handleChange}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).accise
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
                <CFormLabel>Ore</CFormLabel>
              </CFormFloating>
              <CFormFloating className="mb-md-3 col-md-6 col-12">
                <CFormInput
                  type="text"
                  id="AnnoAcquistoInput"
                  name="minuti"
                  defaultValue={accisa.minuti}
                  onChange={handleChange}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).accise
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
                <CFormLabel>Minuti</CFormLabel>
              </CFormFloating>
            </div>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between flex-md-nowrap flex-wrap">
            <div className="d-flex justify-content-start gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="STAMPA"
                icon="printer"
                size="xxs"
                className={`${
                  location.state.operazione === "Nuovo" ? "d-none" : "d-block"
                }`}
                onClick={() => {
                  let accDate = { ...accisa };
                  accDate["data"] = moment(accisa.data)
                    .local()
                    .format("YYYY-MM-DD");
                  navigate(`/home/macchinari/stampa/${accisa.id}`, {
                    state: { accisa: accDate, stampa: "AccisaDettaglio" },
                  });
                }}
              />
            </div>
            <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
              <ReusableButton
                text="ELIMINA"
                icon="trash"
                size="xs"
                className={`bg-red ${
                  location.state.operazione === "Nuovo"
                    ? "d-none"
                    : JSON.parse(localStorage.getItem("permission")).accise
                        .allowDelete === 1 &&
                      localStorage.getItem("nomeUtente") === accisa.utente
                    ? "d-flex"
                    : JSON.parse(localStorage.getItem("permission")).accise
                        .allowDelete === 3
                    ? "d-flex"
                    : "d-none"
                }`}
                onClick={() => handleDeleteButton()}
              />
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                className={`${
                  JSON.parse(localStorage.getItem("permission")).accise
                    .allowEdit === 0
                    ? "d-none"
                    : "d-flex"
                }`}
                onClick={() => handleConfirmButton()}
              />
              {/* <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                className=""
                style={{
                  display:
                    JSON.parse(localStorage.getItem("permission")).accise
                      .allowEdit === 0
                      ? "none"
                      : "block",
                }}
                onClick={() => handleConfirmButton()}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccisaDati;
